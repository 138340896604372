import { useState, useRef, useCallback } from "react";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function (event) {
      setValues({
        ...fields,
        [event.target.id]: event.target.value,
      });
    },
  ];
}

/**
 *
 * @param {*} initialState
 * @param {*} event.target.name input 항목 지정자, input props에 반드시 name이 들어가야 함.
 * @param {*} event.target.value input 항목의 값
 *
 * @returns
 */
export function useInputFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function (event) {
      //
      // 하나의 함수내에서 속성값을 연속으로 변경할 수 있도록 함.
      // useState 함수는 기본적으로 Closure 이므로 콜백으로 처리함.
      //
      const name = event.target.name;
      const value = event.target.value;
      const type = event.target.type;

      // console.log(type, name, value, !fields[name]);

      // 
      // checkbox update 방법 수정함.
      // 
      setValues((flds) => ({
        ...flds,
        // [name]: value
        [name]: type !== "checkbox" ? value: !fields[name]
      }));

      //
      // 연속으로 변경이 안되므로 위의 코드로 대체함
      //
      // setValues({
      //   ...fields,
      //   [event.target.name]: event.target.value,
      // });
    },
  ];
}

/**
 * 
 * @param {*} initialState = false
 * @param {*} ms half of clock cycle
 * @returns 
 */
 export function useOnOff(initialState=false, ms = 500) {
  const [isOn, setIsOn] = useState(initialState)
  const intervalRef = useRef(null);

  const startOnOff = useCallback(() => {
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      setIsOn(pre => !pre);
    }, ms);
  }, [ms]);

  const stopOnOff = useCallback(() => {
    if (!intervalRef.current) return;
    clearInterval(intervalRef.current);
  }, []);

  return { isOn, startOnOff, stopOnOff };
}
