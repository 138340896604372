import { debug } from "../index"

/**
 * @categoryType에_따라_포스팅_편집내용이_달라야_한다
 * 
 * 1. Blog: 
 *    - 항목: mdContent, htmlContent
 * 
 * 2. Notification: 
 *    - 항목: textContent, goToUrl (location), goToPath (push용), expTime
 *    - 사이트에 첫 페이지 팝업으로 공지할 내용
 * 
 */
export const categoryTypes = [
  "Blog",
  "Notification",
  "LandingPage"
]

export const categoryTypes2 = {
  blog: "Blog",                 // md file html
  noti: "Notification",         // pupup 공지
  landingPage: "LandingPage"    // pageContent with jumbotron and sections
}

export const cmsPost = {
  inputFields: [
    {
      name: 'categoryName',
      displayName: "카테고리명",
      inputType: "select",
      selectList: [],
    },
    {
      name: 'title',
      displayName: "포스트 타이틀",
      defaultValue: debug ? "Posting Example" : ""
    },
    {
      name: 'authorEmail',
      displayName: "작성자 이메일",
      defaultValue: debug ? "kebin@example.com" : "",
      required: false
    },
    {
      name: 'authorName',
      displayName: "작성자 이름",
      defaultValue: debug ? "Kebin Lee" : "",
      required: false
    },
    {
      name: 'excerpt',
      displayName: "요약",
      defaultValue: debug ? "테스트 포스트..." : "",
      required: false
    },
    // { name: 'htmlContent', displayName: "HTML content" },    // file 선택시 생성
    // { name: 'mdContent', displayName: "md file content" },   // file 선택시 생성
    {
      name: 'publish',
      inputType: 'checkbox',
      displayName: "퍼블리시 여부",
      required: false
    },
    {
      name: 'image',
      displayName: "헤더 이미지",
      inputType: "file",
      required: false,
      defaultValue: ""
    },
  ]
}

export const cmsNotification = {
  inputFields: [
    {
      name: 'categoryName',
      displayName: "Category Name",
      inputType: "select",
      selectList: [],
    },
    {
      name: 'title',
      displayName: "Title",
      defaultValue: debug ? "팝업 공지 Example" : ""
    },
    // { name: 'expireTs', displayName: "게시 일" },    // submit 시 생성
    {
      name: 'textContent',
      displayName: "Content",
      defaultValue: debug ? "팝업 공지 테스트" : "",
      inputType: "textarea"
    },
    {
      name: 'excerpt',
      displayName: "요약",
      defaultValue: debug ? "팝업 공지 테스트" : "",
      required: false
    },
    {
      name: 'goToPath',
      displayName: "Go To Url",
      defaultValue: debug ? "/cms/post" : "",
    },
    {
      name: 'publish',
      inputType: 'checkbox',
      displayName: "퍼블리시 여부",
      required: false
    },
    {
      name: 'image',
      displayName: "헤더 이미지",
      inputType: "file",
      required: false,
      defaultValue: ""
    },
  ]
}

export const cmsLandingPageBasic = {
  inputFields: [
    {
      name: 'categoryName',
      displayName: "Category Name",
      readOnly: true
    },
    {
      name: 'title',
      displayName: "Title",
      // defaultValue: debug ? "test landing page" : ""
    },
    {
      name: 'publish',
      inputType: 'checkbox',
      displayName: "퍼블리시 여부",
      required: false
    },
  ]
}