import React, { useEffect, useState } from "react";
import { ModalForm, Button, FormInput } from "../../../components2";
import { Recycle, Trash } from "../../../components/Icons";
import { resyncForce, dqrDelete } from "../../../lib/ec2-dqr-api";
import { debug } from "../../../settings";

export function DqrResyncModal(props) {
  const { visible, setVisible, dqr, setVisibleDel } = props;
  const [isLoading, setIsLoding] = useState(false)

  const resyncDqr = async () => {
    try {
      setIsLoding(true)
      console.log(dqr.uid)
      let res = await resyncForce({ body: { uid: dqr.uid } })
      if (res.err) throw Error(res.err.message)
      alert("Resync OK!!")
    } catch (e) {
      alert(e.message)
    } finally {
      setIsLoding(false)
      setVisible(false)
    }
  }

  return (
    <ModalForm
      visible={visible}
      setIsVisible={setVisible}
      title={`DQR Resync | Delete - ${dqr.uid}`}
      backdrop="static"
    >

      <br />
      <Button
        variant="primary"
        size="sm"
        width="100%"
        title='Resync DQR'
        onClick={resyncDqr}
        isLoading={isLoading}
      >
        <Recycle style={{ color: "white" }} />&nbsp;
      </Button>

      <br />
      <Button
        title="DQR 삭제" size="sm"
        variant="outline-secondary"
        isLoading={isLoading}
        onClick={() => {
          setVisible(false)
          setVisibleDel(true)
        }}
      >
        <Trash style={{ color: "white" }} />
      </Button>

    </ModalForm>
  )
}


export function DqrDeleteModal(props) {
  const { visible, setVisible, dqr, checkDqrConnection, updateDqrList } = props;
  const [isLoading, setIsLoding] = useState(false)
  const [delUid, setDelUid] = useState("")

  useEffect(() => {
    setDelUid(debug ? dqr.uid : "")
  }, [dqr])

  const onSubmit = async () => {
    try {
      setIsLoding(true)
      if (dqr.uid === delUid) {
        let res = await dqrDelete({ ver: dqr.ver, uid: dqr.uid })
        if (res.err) throw Error(res.err.message)
        alert("Delete OK!!")
        await checkDqrConnection()
        await updateDqrList()
      } else {
        throw Error("INVALID UID")
      }
    } catch (e) {
      alert(e.message)
    } finally {
      setIsLoding(false)
      setVisible(false)
    }
  }

  return (
    <ModalForm
      visible={visible}
      setIsVisible={setVisible}
      title={`DQR Resync | Delete - ${dqr.uid}`}
      backdrop="static"
    >

      <FormInput
        label="삭제할 UID"
        value={delUid}
        onChange={(e) => setDelUid(e.target.value)}
      />

      <br />
      <Button
        variant="outline-info"
        size="sm"
        width="100%"
        title='Delete DQR'
        onClick={onSubmit}
        isLoading={isLoading}
      >
        <Trash />&nbsp;
      </Button>

    </ModalForm>
  )
}