import React, { useEffect, useState, useRef } from "react";
import { Page, Section, LoaderFullPage } from "../../../components";
import { Button, FormInput, useStyles } from "../../../components2";
import { CustomDropdown, DropdownItem, Divider } from "../../../components2";
import { getPost, createPost, updatePost } from "../../../lib/ec2-api-cms2-lib";
import { categoryTypes2 } from "../../../settings/cms-schema/cms2";
import qs from "qs";
import { EditSection } from "./EditSection";
import { createDefaultGroupCat } from "../createDefaultGroupCategory";
import { json2obj, tsId } from "../../../lib/utils";
import { CustomTable } from "../../../components2/Tables2";
import { debug } from "../../../settings";
import { ChevronD, ChevronU, Trash, ArrowR } from "../../../components2/Icons";


const tableHeaderSections = [
  { name: "selected", displayName: " ", type: "checkbox", keyName: "title" },
  { name: "title", displayName: "Section Title", selectKey: true },
  { name: "name", displayName: "Section Name" },
  { name: "type", displayName: "Section Type" },
]


export default function IndexLanding(props) {

  const { gStyle } = useStyles();

  // const [categoryList, setCategoryList] = useState([])
  const [category, setCategory] = useState({})              // landing Category 만 해당
  const [isLandingCat, setIsLandingCat] = useState(false)   // landing Category

  // category 가 있을 때
  const [isLoading, setIsLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [post, setPost] = useState({});
  const [pageContent, setPageContent] = useState([])
  // const [sectionList, setSectionList] = useState(pageContent)
  const [content, setContent] = useState({})
  const [contentPosition, setContentPosition] = useState(0)

  const [section, setSection] = useState({
    isActive: false, sectionName: "", sectionType: ""
  })

  useEffect(() => {
    onload()
  }, [])

  const onload = async () => {
    try {
      setIsLoading(true);

      let res = await createDefaultGroupCat()
      if (res.err) throw Error(res.err.message)

      const cList = json2obj(res.result.Item.categoryList)
      // setCategoryList(cList)

      const idx = cList.findIndex(c => c.categoryType === categoryTypes2.landingPage)
      if (idx < 0) throw Error("NO 'Landing Page' Category!")

      setCategory(cList[idx])
      setIsLandingCat(true)

      const q = qs.parse(window.location.search.split('?')[1]);
      if (q.categoryId && q.postId) {
        res = await getPost(q);
        if (res.err) throw Error(res.err.message)
        const p = res.result.Item
        setPost(p)
        setIsEdit(true)

        const pContent = json2obj(p.pageContent)
        setPageContent(pContent)
      }

      // if(debug) selectHeader()

    } catch (e) {
      alert(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  const selectHeader = () => {
    setSection({ isActive: true, sectionName: "header", sectionType: "jumbotron" })
  }

  const selectSectionTextImage = () => setSection({ isActive: true, sectionName: "section", sectionType: "text-image" })

  const selectSectionFullImage = () => setSection({ isActive: true, sectionName: "section", sectionType: "full-image" })

  const selectSectionVideo = () => setSection({ isActive: true, sectionName: "section", sectionType: "video" })

  const hideSection = () => setSection({ isActive: false })

  const styles = {
    text: { ...gStyle.text, marginBottom: "3px" },
    formGroup: {
      margin: 0, padding: 15, marginBottom: 0
    }
  };

  const pageProps = {
    isEdit, setIsEdit,
    post, setPost,
    category,
    pageContent, setPageContent,
    category, setCategory,
    isLoading, setIsLoading, styles,
    content, setContent,
    contentPosition, setContentPosition, 
    selectHeader, selectSectionTextImage, selectSectionVideo, selectSectionFullImage,
    hideSection,
    onload,
  }

  return (
    <Page stretch title={"Landing Page " + (isEdit ? "편집" : "추가")}>

      {isLandingCat && <EditBasic {...props} {...pageProps} />}

      {section.isActive &&
        <EditSection
          {...props} {...pageProps}
          sectionName={section.sectionName}
          sectionType={section.sectionType}
        />
      }

      {isLoading && <LoaderFullPage />}
    </Page>
  );
}



function EditBasic(props) {
  const {
    setIsLoading, styles,
    isEdit,
    post,
    pageContent, setPageContent,
    category,     // landing page category
    selectHeader, selectSectionTextImage, selectSectionVideo
  } = props

  const { gStyle } = useStyles();
  const [fields, setFields] = useState({ categoryName: "", title: "", publish: false })

  useEffect(() => {
    setFields({
      categoryName: category.categoryName,
      title: post.title || "Test Landing",
      publish: post.publish || false
    })
  }, [post])

  const onSubmit = async () => {
    try {
      setIsLoading(true)

      const newPageContent = pageContent.map(p => {
        delete p.selected
        return p
      })

      const params = {
        categoryId: category.categoryId,
        postId: isEdit ? post.postId : tsId(),
        body: {
          ...fields,
          pageContent: JSON.stringify(newPageContent)
        }
      }

      let res = {}
      if (isEdit) {
        res = await updatePost(params)
      } else {
        res = await createPost(params)
      }

      // console.log("=".repeat(50), "isEdit =", isEdit)
      // console.log('params', params)
      // console.log('body', params.body)

      if (res.err) throw Error(res.err.message)

      // props.history.push(`/cms/landing-page?categoryId=${category.categoryId}&postId=${res.result.Item.postId}`)
      props.onload()
      setIsLoading(false)
    } catch (e) {
      alert(e.message)
    }
  }

  const onClickItem = (item) => {
    props.setContent(item)
    const idx = props.pageContent.findIndex(c => c.title === item.title)
    props.setContentPosition(idx)

    // 일반 section 도 상관이 없다? 어차피 setContent에 item을 넣었기 때문에?
    props.selectHeader()

    window.scrollBy({ left: 0, top: document.body.scrollHeight / 2, behavior: "smooth" })
  }

  const onMove = (direction) => {
    const newItems = [...pageContent]

    let idx = newItems.findIndex(item => item.selected)
    if (idx < 0) return null
    const obj = newItems[idx]

    newItems.splice(idx, 1)
    if (direction === "up") {
      idx = idx === 0 ? idx : --idx
    } else {
      idx = idx === newItems.length ? idx : ++idx
    }
    newItems.splice(idx, 0, obj)

    setPageContent(newItems)
  }

  const ddList = [
    { name: "jumbotron", displayName: "Jumbotron", onClick: selectHeader },
    { name: "section-text-image", displayName: "Section TextImage", onClick: selectSectionTextImage },
    { name: "section-full-image", displayName: "Section FullImage", onClick: props.selectSectionFullImage },
    { name: "section-video", displayName: "Section Video", onClick: selectSectionVideo },
  ]

  return (
    <Section bgColor="#fff" >
      <div style={{
        display: "flex",
        justifyContent: "space-between", alignItems: "center",
        marginLeft: 15, marginRight: 15
      }}>
        <h3>Basic Info</h3>
        <ArrowR
          onClick={() => {
            props.history.push(`/cms/landing-page/view?categoryId=${props.post.categoryId}&postId=${props.post.postId}`)
          }}
        />
      </div>
      <hr />

      <div style={styles.formGroup}>
        <FormInput
          label="Category Name"
          name="categoryName"
          value={fields.categoryName}
          readOnly={true}
        />

        <FormInput
          label="Title"
          name="title"
          value={fields.title}
          onChange={(e) => {
            setFields((pre) => {
              return {
                ...pre,
                title: e.target.value
              }
            })
          }}
        />

        <FormInput
          label="Publish"
          name="publish"
          checked={fields.publish}
          inputType="checkbox"
          onChange={(e) => {
            setFields((pre) => {
              return {
                ...pre,
                publish: !fields.publish
              }
            })
          }}
        />


      </div>

      <hr />

      {/* section 추가 */}
      {isEdit &&
        <div style={{
          display: "flex",
          justifyContent: "space-between", alignItems: "center",
          marginLeft: 15, marginRight: 15
        }}>
          <CustomDropdown title="Section 추가" id="section-edit" size="lg" position="left" >
            {
              ddList.map((dd, i) => {
                return (
                  <DropdownItem
                    key={i}
                    onClick={() => {
                      dd.onClick()
                      props.setContentPosition(props.pageContent.length) // 마지막에 위치
                      props.setContent({})                               // 빈 콘텐트
                      window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" })
                    }}
                  >{dd.displayName}</DropdownItem>
                )
              })
            }
          </CustomDropdown>

          <div style={{
            border: "1px solid #eee",
            borderRadius: 5,
            padding: "5px 10px",
            display: "flex",
            justifyContent: "space-between", alignItems: "center"
          }}>
            <p style={{ ...gStyle.text, marginRight: 10 }}><Trash onClick={() => alert("삭제는 나중에")} /></p>
            <p style={{ ...gStyle.text, marginRight: 5 }}><ChevronU onClick={() => onMove("up")} /></p>
            <p style={{ ...gStyle.text }}><ChevronD onClick={() => onMove("down")} /></p>
          </div>
        </div>
      }

      {/* 기존 sections */}
      <div style={styles.formGroup}>
        <CustomTable
          tableHeader={tableHeaderSections}
          rowItems={pageContent}
          setRowItems={setPageContent}
          onClickItem={onClickItem}
        />
      </div>

      <div style={styles.formGroup}>
        <Button variant="primary" title="저장" onClick={onSubmit} width="100%" size="sm" />
      </div>

    </Section>
  )
}
