import { configureStore } from "@reduxjs/toolkit";
import loggerMiddleware from "../lib/loggerMiddleware";
import counterReducer from "./counter"; // deprecated
import userReducer from "./user";
import browserReducer from "./browser"; // deprecated
import platformReducer from "./platform";
import cartReducer from './cart';
import dpCartReducer from './dpCart'

export const store = configureStore({
  reducer: {
    counter: counterReducer, // deprecated
    user: userReducer,
    browser: browserReducer, // deprecated
    platform: platformReducer,
    cart: cartReducer,
    dpCart: dpCartReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(loggerMiddleware),
});
