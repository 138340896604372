import React, { useEffect, useState } from "react";
import { Page, Section } from "../../components";
import { ModalForm, FormInput, Button, LoaderFullPage, colors } from "../../components2";
import { CustomTable } from "../../components2/Tables2";
import { Plus } from "../../components2/Icons";
import { getTnUrl, imageCompress, sortObjArray2 } from "../../lib/utils";
import { fileUpload } from "../../lib/apiLib";
import { listObjects } from "../../lib/ec2-api-lib";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { conf } from "../../settings";

const header = [
  { name: "image", displayName: "Image", type: "image" },
  { name: "filename", displayName: "File Name", sorting: true, selectKey: true },
  { name: "size", displayName: "File Size" },
  { name: "created", displayName: "Created", sorting: true },
]

const s3Prefix = "posting-images"

export default function ImageManager(props) {
  const title = "이미지 저장 및 관리";
  const [isLoading, setIsLoading] = useState(true)
  const [tableItems, setTableItems] = useState([])

  const [visible, setVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editItem, setEditItem] = useState({});

  const [visibleImgView, setVisibleImgView] = useState(false)

  useEffect(() => { onload(); }, [])

  const onload = async () => {
    try {
      setIsLoading(true);
      let res = await listObjects({ prefix: `public/${s3Prefix}/` });
      if (res.err) throw Error(res.err.message)

      const items = res.result.Contents.map(item => {
        const arr = item.Key.split('/')
        return {
          ...item,
          image: conf.awsConfig.s3.url + item.Key,
          filename: arr[arr.length - 1],
          size: Math.floor(item.Size / 1000) + "KB",
          created: item.LastModified,
        }
      })

      setTableItems(sortObjArray2({ arr: items, key: "created", type: "down" }))
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false)
    }
  }

  const onSelect = (obj) => {
    setEditItem(obj)
    setVisibleImgView(true)
  }

  const onClickPlus = () => {
    setIsEdit(false)
    setVisible(true)
  }

  return (
    <Page stretch title={title}>

      <Section bgColor="#fff">
        <h3 style={{ margin: 5, padding: 0 }}>
          <Plus onClick={onClickPlus} style={{ color: colors.primary }} />
        </h3>
        <CustomTable
          {...props}
          tableHeader={header}
          rowItems={tableItems}
          setRowItems={setTableItems}
          onClickItem={onSelect}
        />
        {
          (!isLoading && tableItems.length === 0) &&
          <>
            <p style={{ textAlign: "center" }}>등록된 항목이 없습니다.</p>
            <p style={{ textAlign: "center" }}>상단의 + 를 클릭하여 Image를 업로드 하세요!</p>
          </>
        }
      </Section>

      {isLoading && <LoaderFullPage />}

      <ImageUploadModal
        {...props}
        onload={onload}
        isEdit={isEdit}
        editItem={editItem}
        visible={visible}
        setIsVisible={setVisible}
      />

      <ImageViewModal
        {...props}
        imgObj={editItem}
        visible={visibleImgView}
        setIsVisible={setVisibleImgView}
      />

    </Page>

  )
}

function ImageUploadModal(props) {
  const { visible, setIsVisible, onload, isEdit, editItem } = props
  const title = "Posting Image"

  const [isLoading, setIsLoading] = useState(false)
  const [previewImgUrl, setPreviewImgUrl] = useState("")
  const file = {};

  useEffect(() => {
    setPreviewImgUrl(editItem?.image || "")
  }, [editItem])

  const onSubmit = async (e) => {
    try {
      setIsLoading(true);

      let fileRes = {};

      if (!file.current) throw Error("NO File Selected!!")


      // 메뉴 이미지 저장 300KB 초과하면 resize

      let resizedImg = file.current;
      if (file.current.size > 300000) {
        resizedImg = await imageCompress({
          image: file.current,
          maxSizeMB: 0.3,
          maxWidthOrHeight: 640
        })
        if (!resizedImg) throw new Error("이미지 처리에 문제가 발생하였습니다.");
      }

      fileRes = await fileUpload(resizedImg, s3Prefix);
      if (fileRes.err) throw new Error("Error in fileUpload!");

      // 
      // Thumbnail 생성, 저장 - public/shopId/tn
      //
      resizedImg = await imageCompress({
        image: file.current,
        // maxSizeMB: 0.03,    // 30kB 미만
        // maxWidthOrHeight: 120
        maxSizeMB: 0.6,    // 100kB 미만
        maxWidthOrHeight: 150
      })
      if (!resizedImg) throw new Error("이미지 처리에 문제가 발생하였습니다.");

      await fileUpload(resizedImg, s3Prefix + "/tn");
      if (fileRes.err) throw new Error("Error in fileUpload!");

      await onload();
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
      setIsVisible(false);
    }
  }

  const onChangeInputFile = (e) => file.current = e.target.files[0]

  return (
    <ModalForm
      visible={visible}
      setIsVisible={setIsVisible}
      title={title}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <FormInput
        name="image"
        label="포스팅용 이미지 추가"
        inputType="file"
        value={previewImgUrl}
        onChangeInputFile={onChangeInputFile}
      />
    </ModalForm>
  )
}

function ImageViewModal(props) {
  const { visible, setIsVisible, imgObj } = props
  const title = imgObj.filename
  const [copied, setCopied] = useState(false)

  useEffect(() => setCopied(false), [imgObj])

  return (
    <ModalForm
      visible={visible}
      setIsVisible={setIsVisible}
      title={title}
    >
      <div style={{ paddingBottom: 15 }}>
        <img style={{ width: "100%", borderRadius: 5 }} src={imgObj.image} alt={imgObj.filename} />
      </div>
      <Button
        variant={copied ? "primary" : "outline-primary"}
        size="sm"
        style={{ width: "200px" }}
        onClick={() => console.log()}
      >
        <CopyToClipboard text={imgObj.image} onCopy={() => setCopied(true)} >
          <span>{copied ? "Copied" : "Copy to Clipboard"}</span>
        </CopyToClipboard>
      </Button>
    </ModalForm>
  )
}