const pos = {
  "UNIONPOS": { transformOrders: transformOrdersUnionpos, },
  "OKPOS": { transformOrders: transformOrdersOkpos },
  "OASIS_POS": { transformOrders: transformOrdersOasispos },
  "EASY_POS": { transformOrders: transformOrdersEasypos },
}

const posList = Object.keys(pos)

export function transformOrders({ shopInfo, orders, tableList }) {
  if (!posList.includes(shopInfo.posName)) return { err: { message: "INVALID POSNAME" } };
  return pos[shopInfo.posName].transformOrders({ orders, tableList });
}

function transformOrdersUnionpos({ orders, tableList }) {
  let newOrders = orders.map((order) => {
    let orderData = JSON.parse(order.orderData);
    let tables = tableList.filter(table => table.TableCode === parseInt(orderData.TableCode))
    return {
      TableName: tables.length===1 ? tables[0].TableName : orderData.TableCode + " 이동됨",
      time: order.lastModified,
      OrderData: "아이템 수: " + orderData.OrderData.length
    }
  });
  return newOrders
}

export function transformOrdersOkpos({ orders, tableList }) {
  let newOrders = orders.map((order) => {
    let orderData = JSON.parse(order.orderData);

    let tables = tableList.filter(table => table.TABLE_CD === orderData.orderData.TABLE_CD)

    const obj = {
      TableName: tables.length===1 ? tables[0].TABLE_NM : orderData.orderData.TABLE_CD + " 이동됨", 
      time: order.lastModified,
      OrderData: "아이템 수: " + orderData.orderData.ORDER_INFO.length,
    }

    if(orderData._comment) obj.OrderData = obj.OrderData + " - " + orderData._comment

    return obj
  });

  return newOrders
}

export function transformOrdersOasispos({ orders, tableList }) {
  let newOrders = orders.map((order) => {
    let orderData = JSON.parse(order.orderData);
    let tables = tableList.filter(table => table.tableNo === parseInt(orderData.tableNo))
    return {
      TableName: tables.length===1 ? tables[0].tableName : orderData.tableNo + " 이동됨",
      time: order.lastModified,
      OrderData: "아이템 수: " + orderData.orderList.length
    }
  });
  return newOrders
  // return []
}

export function transformOrdersEasypos({ orders, tableList }) {
  let newOrders = orders.map((order) => {
    let orderData = JSON.parse(order.orderData);

    // console.log('orderData', orderData)

    let tables = tableList.filter(table => table.tableCode === orderData.tableCode)
    return {
      TableName: tables.length===1 ? tables[0].tableName : orderData.tableNo + " 이동됨",
      time: order.lastModified,
      OrderData: "아이템 수: " + orderData.itemList.length
    }
  });
  return newOrders
}