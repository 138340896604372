import { Page, SectionAnimate, Section, FlexBox, TitleBox } from "./Layout";
import Table from "./Table";

Section.FlexBox = FlexBox;
Section.TitleBox = TitleBox;

export { Page, SectionAnimate, Section };

export { 
  colors, 
  gStyle, 
  gStyleMobile, 
  useStyles 
} from "./ui";

export {
  FormInput,
  Search, Search2,
  SelectFilters,
  FormInputWithBtn,
} from "./FormInputs";

// export { default as LoaderButton } from "./LoaderButton";

export { LoaderFullPage, LoaderButton } from "./Loaders";

export { Alert, ModalView, PostCodeView } from "./Modals";

export { Table };
