import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { debug } from "../settings";
import { ui } from "../settings";

/**
 *
 * @param {Object} props.style
 * @param {String} props.padding
 * @param {String} props.bgColor
 * @param {Component} props.children
 * @returns
 */
export function Section({ style, padding, bgColor, children }) {
  const styles = style || {
    margin: "10px 0 0",
    padding: padding || "20px",
    width: "100%",
    backgroundColor: bgColor || "transparent",
    borderRadius: 5
  };
  return <div style={styles}>{children}</div>;
}

/**
 *
 * @param {String} props.title
 * @param {Object} props.headerImage
 * @param {Component} props.children
 * @returns
 */
export function Page({ title, children, headerImage, stretch }) {
  // const [loaded, setLoaded] = useState(false);
  const [minH, setMinH] = useState(1000);

  const { isMobileDevice } = useSelector(state => state.platform)

  const page = useRef();

  useEffect(() => {
    function onLoad() {
      // setLoaded(true);
      setMinHeight();
    }
    onLoad();
  }, []);

  function setMinHeight() {
    let pageRect = page.current.getBoundingClientRect();
    let contentMinH =
      window.innerHeight - (document.body.clientHeight - pageRect.height);

    //
    // always same unless header and footer are not changed
    //
    if (contentMinH > 0) setMinH(contentMinH);

    if (debug) {
      // console.log("window.innerHeight", window.innerHeight);
      // console.log("body.clientHeight ", document.body.clientHeight);
      // console.log("page.height       ", pageRect.height);
      // console.log("contentMinH       ", contentMinH);
    }
  }

  const styles = {
    page: {
      // marginTop: "10px",
      minHeight: minH + "px",
    },
    header: headerImage
      ?
      {
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        color: "white",
        padding: "30px 25px",
        textAalign: "center",
        backgroundImage: `url(${headerImage})`,
      }
      :
      { 
        backgroundColor: ui.color.info,
        color: "white",
        padding: "30px 25px",
        textAalign: "center", 
      },
    headerBg: headerImage || {},
    title: { fontWeight: 400 },
    content: { marginTop: "10px" },
  };

  return (
    <div
      ref={page}
      className={stretch ? null : "container"}
      style={styles.page}
    >
      {
        !isMobileDevice &&
        <div style={{ ...styles.header }}>
          <h1 style={styles.title}>{title}</h1>
        </div>
      }
      <div className={stretch ? "container" : null} style={styles.content}>
        {children}
      </div>
    </div>
  );
}

/**
 *
 * @Description use Scroll Event
 */
export function SectionAnimate({ style, padding, bgColor, children }) {
  const [animate, setAnimate] = useState(false);
  const myRef = useRef();

  useEffect(() => {
    onScroll();
    window.addEventListener("scroll", onScroll);

    return function () {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  function onScroll(e) {
    let h = (window.innerHeight * 3) / 4;
    const rect = myRef.current.getBoundingClientRect();
    if (rect.top < h) setAnimate(true);
  }

  const myStyle = {
    wrap: style || {
      margin: "10px 0 0",
      padding: padding || "40px 0",
      width: "100%",
      backgroundColor: bgColor || "skyblue",
    },
  };

  return (
    <div ref={myRef} style={myStyle.wrap}>
      <div className={animate ? "slide" : "slideanim"}>{children}</div>
    </div>
  );
}

/**
 *
 * @param {*} props.variant justifyContent
 * @param {*} props.direction flexDirect, row as default
 * @returns
 */
export function FlexBox(props) {
  const { variant, direction, children, style } = props;

  let myStyle = {
    width: "100%",
    display: "flex",
    flexDirection: direction || "row", // row | column
    justifyContent: variant,
    alignItems: "center",
  };
  if (style) myStyle = { ...style, ...myStyle };
  return <div style={myStyle}>{children}</div>;
}

export function TitleBox(props) {
  const { title, onClick, btnText, variant = "outline-secondary" } = props;

  const myStyle = {
    wrap: { padding: "20px" },
    title: { margin: "0px", padding: "0px" },
  };
  return (
    <FlexBox variant="space-between" style={myStyle.wrap}>
      <h2 style={myStyle.title}>{title}</h2>
      {btnText && (
        <Button
          size="sm"
          variant={variant}
          style={{ height: "30px" }}
          onClick={onClick}
        >
          {btnText}
        </Button>
      )}
    </FlexBox>
  );
}
