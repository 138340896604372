import { getPost, createPost } from "../../lib/ec2-api-cms2-lib"
import { tsId } from "../../lib/utils"
import { categoryTypes } from "../../settings/cms-schema/cms2"

export async function createDefaultGroupCat() {
  try {

    let res = await getPost({ categoryId: "common", postId: "common" })
    if (res.err) {
      // 
      // 기본 항목이 없으면 생성
      // 
      if (res.err.message === "NO ITEM FOUND") {

        const groupId = tsId()
        const groupName = "Default Group"
        const defaultCatName = "Default Category"

        const defaultGroup = { groupId, groupName }
        const defaultCat = {
          categoryId: tsId(),
          categoryName: defaultCatName,
          groupId, groupName,
          categoryType: categoryTypes[0]  // Blog 를 default로 함
        }

        // console.log(defaultGroup)
        // console.log(defaultCat)

        const body = {
          groupList: JSON.stringify([defaultGroup]),
          categoryList: JSON.stringify([defaultCat])
        }
        res = await createPost({ categoryId: "common", postId: "common", body })
        if (res.err) throw Error(res.err.message)

      } else {
        throw Error(res.err.message)
      }
    }

    return res
  } catch (e) {
    return { err: { message: e.message } }
  }
}