import React, { useEffect, useState } from "react";
import { Page, Section } from "../../components";
import { debug } from "../../settings";
import { Pencil, Trash, ArrowL } from "../../components2/Icons";
import qs from "qs";
import PostModal from "./PostModal2";
// import { postGet, categoryList, postDelete } from "../../lib/ec2-api-cms-lib";
import { getPost, listPost, deletePost } from "../../lib/ec2-api-cms2-lib";
import { createDefaultGroupCat } from "./createDefaultGroupCategory";
import { PostDeleteConfirmModal } from "./PostDelConfirmModal";
import { ModalForm, FormInput, LoaderFullPage, colors, Button } from "../../components2";
import { json2obj } from "../../lib/utils";
import { useInputFields } from "../../lib/hooksLib";

import "./PostView.css"

export default function PostView(props) {
  // const [title, setTitle] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [post, setPost] = useState({});
  const [catList, setCatList] = useState([])
  const [category, setCategory] = useState({})

  useEffect(() => {
    onload();
  }, [])

  const onload = async () => {
    try {
      setIsLoading(true);
      const q = qs.parse(window.location.search.split('?')[1]);

      let res = await getPost(q);
      if (res.err) throw Error(res.err.message)
      const p = res.result.Item
      setPost(p)

      if (debug) console.log(p)

      res = await createDefaultGroupCat()
      if (res.err) throw Error(res.err.message)

      const cList = json2obj(res.result.Item.categoryList)
      setCatList(cList)

      const idx = cList.findIndex(c => c.categoryId === p.categoryId)
      setCategory(cList[idx])

    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false)
    }
  }

  const otherProps = { onload, post, isEdit: true, categoryList: catList, category }

  const styles = {
    lines: { marginBottom: 5, color: '#fff' },
    header: {
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      color: post.image ? "white" : colors.dark,
      padding: "30px 25px",
      textAalign: "center",
      // backgroundColor: colors.secondary,
      // backgroundImage: `url(${post.image})`,
    },
  }

  if (post.image) styles.header["backgroundImage"] = `url(${post.image})`
  else styles.header["backgroundColor"] = colors.secondary

  return (
    <Page stretch title={post.title || "..."}>

      {
        !isLoading &&
        <Section bgColor="#fff">
          <h5 style={{ margin: 5, padding: 0 }}>
            <ArrowL onClick={() => props.history.goBack()} style={{ color: colors.dark, fontSize: "40px" }} />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Pencil onClick={() => setVisibleModal(true)} style={{ color: colors.primary }} />
            &nbsp;&nbsp;
            <Trash onClick={() => setVisibleDeleteModal(true)} style={{ color: colors.secondary }} />
          </h5>
          <hr />

          <div style={styles.header}>
            <p style={styles.lines}>카테고리 : {category.categoryName}</p>
            <p style={styles.lines}>작성일자 : {new Date(post.created).toLocaleString()}</p>
            <p style={styles.lines}>수정일자 : {new Date(post.lastModified).toLocaleString()}</p>
            {category.categoryType === "Notification" &&
              <p style={styles.lines}>만료일자 : {new Date(post.expireTs).toLocaleString()}</p>
            }
            <p style={styles.lines}>작성자 : {post.authorName}</p>
            <p style={styles.lines}>게시중 : {post.publish ? "Yes" : "No"}</p>
          </div>

          {category.categoryType === "Blog" &&
            <div dangerouslySetInnerHTML={createMarkup(post.htmlContent)} className="onl-post" />
          }

          {category.categoryType === "Notification" &&
            <div style={{ marginTop: 15 }}>
              <div>
                {
                  post.textContent.split("\n").map((line, i) => {
                    return <p key={i}>{line}</p>
                  })
                }

              </div>
              <p onClick={() => props.history.push(post.goToPath)} >{post.goToPath}</p>
            </div>
          }
        </Section>
      }

      {isLoading && <LoaderFullPage />}

      {visibleModal &&
        <PostModal
          {...props}
          {...otherProps}
          visible={visibleModal}
          setIsVisible={setVisibleModal}
        />
      }
      {visibleDeleteModal &&
        <PostDeleteConfirmModal
          {...props}
          post={post}
          visible={visibleDeleteModal}
          setIsVisible={setVisibleDeleteModal}
        />
      }
    </Page>

  )
}

function createMarkup(html) {
  return { __html: html };
}
