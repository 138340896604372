import React, { useState, useEffect } from "react";
import { ChevronD } from "./Icons";
import { getTimestampFromNow } from "../lib/utils";
import Calendar from "react-calendar";
import './Calendar.css';
// import { ModalForm } from "./Modals";
// import { Search2 } from "./FormInputs";
import { CustomTable } from "../components2/Tables";
import { ModalForm, Search2, useStyles, colors } from "./";
import { debug } from "../settings";

export function SearchWithCalendar(props) {
  const {
    searchComment, modalTitle,
    placeholder,
    onClickSearch,
    days = 7,         // default 기간: 오늘 포함해서 7일 
    tableHeader, rowItems, onClickItem,
  } = props;

  // const { gStyle } = useStyles()

  // const testPno = "01083448080" // 포베이 PSDL점
  const testPno = "01055545275" // 포베이 PSDL점
  const [searchText, setSearchText] = useState(debug ? testPno : "");
  const [visible, setVisible] = useState(false)

  const today = new Date()
  const _startDate = new Date(getTimestampFromNow({ now: new Date(), days: -(days - 1) }))
  const [startDate, setStartDate] = useState(_startDate)
  const [endDate, setEndDate] = useState(today)

  const [rowItemList, setRowItemList] = useState([])

  useEffect(() => {
    setRowItemList(rowItems)
  }, [rowItems])

  const styles = {
    wrap: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center"
      // justifyContent: "space-around"
    },    
  }

  return (
    <>
      <p style={{ margin: 0 }}>{searchComment}</p>
      <Search2
        onChange={e => setSearchText(e.target.value)}
        placeholder={placeholder}
        btnDisabled="false"
        onClick={() => {
          onClickSearch(searchText);
          setVisible(true)
        }}
        onKeyUp={() => {
          onClickSearch(searchText)
          setVisible(true)
        }}
        value={searchText}
      />

      <div style={styles.wrap} >
        <CalendarDropdown
          label="Start Date"
          date={startDate}
          setDate={setStartDate}
        />

        <CalendarDropdown
          label="End Date"
          date={endDate}
          setDate={setEndDate}
        />
      </div>

      {
        rowItemList.length > 0 &&
        <ModalForm 
          visible={visible} 
          setIsVisible={setVisible} 
          title={modalTitle} 
          size="lg"
        >
          <div style={{ minHeight: 300, overflow: "auto", maxHeight: "85vh" }}>
            <CustomTable
              {...props}
              tableHeader={tableHeader}
              rowItems={rowItemList}
              // rowItemKey="shopName"
              onClickItem={(obj) => {
                onClickItem({ item: obj, startDate, endDate })
                setVisible(false)
              }}
            />
          </div>
        </ModalForm>
      }
    </>
  )
}

function CalendarButton({ onClick, value }) {
  // const bgColor = colors.light;
  // const txtColor = colors.dark;
  const bgColor = colors.dark;
  const txtColor = colors.light;
  const styles = {
    wrapper: {
      width: "200px", // Calendar width 300px와 동일하게 맞춤
      display: 'flex',
      alignItems: 'center',
    },
    btn: {
      width: "100%",
      height: 44,
      paddingLeft: "15px", paddingRight: "15px",
      border: "1px solid " + bgColor,
      outline: 0,
      borderRadius: 5,
      backgroundColor: bgColor,
      color: txtColor,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    icon: { marginLeft: -25, color: txtColor }
  }
  return (
    <div style={styles.wrapper}>
      <button onClick={onClick} style={styles.btn}>{value}</button>
      <ChevronD onClick={onClick} style={styles.icon} />
    </div>
  );
}

export function CalendarDropdown({
  date, setDate, label
}) {
  const [showCalendar, setShowCalendar] = useState(false)

  const styles = {
    wrap: {
      marginBottom: 15,
      marginRight: 15,
      position: "relative"
    },
    calendarWrap: {
      top: 70, left: 0,
      position: "absolute",
      zIndex: 999
    }
  }

  return (
    <div style={styles.wrap}>
      <p style={{ margin: "0px 0px 5px" }}>{label}</p>
      <CalendarButton
        onClick={() => setShowCalendar(!showCalendar)}
        value={date.toLocaleDateString()}
      />
      {showCalendar &&
        <div style={styles.calendarWrap}>
          <Calendar
            value={date}
            onChange={setDate}
            onClickDay={(d) => {
              setShowCalendar(!showCalendar)
              setDate(d)
            }}
          />
        </div>
      }
    </div>
  )
}

export function PeriodWithCalendar(props) {
  const {
    startDate, setStartDate,
    endDate, setEndDate
  } = props
  // const today = new Date()
  // const _startDate = new Date(getTimestampFromNow({ now: new Date(), days: -(days - 1) }))
  // const [startDate, setStartDate] = useState(_startDate)
  // const [endDate, setEndDate] = useState(today)

  const styles = {
    wrap: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      // justifyContent: "space-between"
    }
  }

  return (
    <div style={styles.wrap} >
      <CalendarDropdown
        label="Start Date"
        date={startDate}
        setDate={setStartDate}
      />

      <CalendarDropdown
        label="End Date"
        date={endDate}
        setDate={setEndDate}
      />
    </div>
  )
}