const ui = {
  fontSize: {
    h1: 30,
    h2: 24,
    h3: 18,
    h4: 16,
    normal: 14,
    small: 12
  },
  color: {
    primary: "#445",
    secondary: "#778",
    info: "steelblue",
  },
  bgColor: {
  }
}
export default ui;
