import React, { useState } from "react";
import { Page, Section, LoaderFullPage } from "../../components";
import SearchStore from "./components/SearchStore";
import { useSelector } from "react-redux";
import { Button, UnderlineButton } from "../../components2";
import { debug } from "../../settings";

const items = [
  { name: "shopName", displayName: "가맹점명" },
  { name: "posName", displayName: "사용포스" },
  { name: "addr", displayName: "주소" },
  { name: "ownername", displayName: "가맹점주 이름" },
  { name: "shopType", displayName: "업종" },
  { name: "storeCode", displayName: "가맹점 코드" },
  { name: "tableCount", displayName: "테이블 수" },
  { name: "tel", displayName: "전화번호" },
  { name: "orderCount", displayName: "누적 주문" },
  { name: "shopId", displayName: "가맹점 아이디" },
  { name: "created", displayName: "등록일시" },
]

export default function StoreInfo(props) {
  const title = "가맹점 정보"
  const { isMobileDevice } = useSelector(state => state.platform)

  const [isLoading, setIsLoading] = useState(false);
  const [shopInfo, setShopInfo] = useState({})

  const onSelectShop = async (shop) => {
    try {
      setIsLoading(true)
      const newShop = {
        ...shop,
        addr: `${shop.addr1}, ${shop.addr2}, 우편번호 ${shop.zipcode}`
      }
      setShopInfo(newShop)
    } catch (e) {
      alert(e.message)
      if (debug) console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const styles = {
    info: {
      width: isMobileDevice ? "100%" : "50%",
    },
  }

  return (
    <Page stretch title={title}>

      <Section bgColor="#fff">
        <SearchStore
          onSelectStore={onSelectShop}
          defaultValue={debug ? "EASYPOS" : ""}
        />
      </Section>

      {Object.keys(shopInfo).length > 0 &&
        <Section bgColor="#fff">
          <div style={styles.info}>

            {items.map((attr, i) => {
              if (attr.name === "shopName")
                return <h3 key={i} style={{ marginBottom: 25 }}>{shopInfo[attr.name]}</h3>
              // if (attr.name === "created")
              //   return <p key={i}>{attr.displayName + " : " + new Date(shopInfo[attr.name]).toLocaleDateString()}</p>
              // return <p key={i}>{attr.displayName + " : " + shopInfo[attr.name]}</p>

              return (
                <LineItem
                  key={i}
                  name={attr.displayName}
                  value={attr.name === "created" ? new Date(shopInfo[attr.name]).toLocaleDateString() : shopInfo[attr.name]}
                />
              )
            })}
          </div>

          <UnderlineButton
            size="sm"
            variant="info"
            title="DQR 세팅하러 가기"
            onClick={() => props.history.push(`/store/dqr-setting?shopId=${shopInfo.shopId}`)}
          />
        </Section>
      }

      {isLoading && <LoaderFullPage />}
    </Page>
  );
}

function LineItem({ name, value }) {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <p style={{ fontWeight: "bold" }}>{name}</p>
      <div style={{ display: "flex", justifyContent: "flex-start", paddingLeft: 25 }}>
        <p>{value}</p>
      </div>
    </div>
  )
}