import React, { useEffect, useState } from "react";
import { Section } from "../../../components";
import { FormInput, Button, useStyles } from "../../../components2";
import { buttonVariants } from "../../../components2/Buttons";
// import { useInputFields } from "../../../lib/hooksLib";
import { Plus } from "../../../components2/Icons";
import { debug } from "../../../settings";
import { imageCompress } from "../../../lib/utils";
import { fileUpload } from "../../../lib/apiLib";
import { updatePost } from "../../../lib/ec2-api-cms2-lib";

export function EditSection(props) {
  const {
    isEdit, 
    // editItem,
    // post, setPost,
    // pageContent,  // post.pageContent 를 JSON.parse 한 content Array
    content,      // pageContent Array 내의 section object - header | section
    // isLoading, 
    styles,
    sectionName, sectionType,
  } = props

  const [basicInfo, setBasicInfo] = useState({
    name: "", type: "", title: "", layout: "",
  })

  // texts
  const [texts, setTexts] = useState([
    { value: "", variant: "", display: "block" }
  ])

  // links
  const [links, setLinks] = useState([
    { type: "button", variant: "primary", text: "자세히 보기", linkUrl: "" }
  ])

  // images
  const [images, setImages] = useState([
    { url: "", w: "400px", mw: "" }
  ])
  // textImage.text
  // const [text, setText] = useState("")

  useEffect(() => {
    if (isEdit) {
      setBasicInfo({
        name: content.name || sectionName,
        type: content.type || sectionType,
        title: content.title || "Example Video",
        layout: content.layout || "column",
      })

      setTexts(content.texts || [{ value: "", variant: "", display: "block" }])

      setLinks(content.links || [{ type: "button", variant: "primary", text: "자세히 보기", linkUrl: "" }])

      setImages(content.images || [{ url: "", w: "", mw: "" }])
    }

  }, [props])

  const addText = () => {
    if (texts[texts.length - 1].value) {
      const newList = [...texts]
      newList.push({ value: "", variant: "", display: "block" })
      setTexts(newList)
    }
  }

  const addLink = () => {
    if (links[links.length - 1].linkUrl) {
      const newList = [...links]
      newList.push({ type: "", variant: "primary", displayName: "", linkUrl: "" })
      setLinks(newList)
    }
  }

  const addImage = () => {
    if (images[images.length - 1].url) {
      const newList = [...images]
      newList.push({ url: "", w: "", mw: "" })
      setImages(newList)
    }
  }

  const onSubmitSectionBasic = async () => {
    try {
      props.setIsLoading(true)

      const newPageContent = [...props.pageContent]

      const preContent = props.pageContent[props.contentPosition]
      let newContent = {
        ...preContent, ...basicInfo, lt: Date.now()
      }

      newPageContent[props.contentPosition] = newContent

      const params = {
        categoryId: props.post.categoryId,
        postId: props.post.postId,
        body: {
          pageContent: JSON.stringify(newPageContent)
        }
      }

      // console.log(params)

      let res = await updatePost(params)
      if (res.err) throw Error(res.err.message)

      await props.onload()

    } catch (e) {
      alert(e.message)
    } finally {
      props.setIsLoading(false)
      props.hideSection()
    }
  }

  const myStyle = {
    ...styles,
    addGroupWrap: {
      marginBottom: 5,
      padding: 15,
      border: "1px solid gray",
      borderRadius: 5
    },
    hGroup: {
      display: 'flex', flexWrap: 'wrap'
      // justifyContent: "space-between", alignItems: "center" 
    },
    hInput: { marginRight: 5 }
  }

  return (
    <Section bgColor="#fff">
      <h3>{`${basicInfo.name} - ${basicInfo.type}`}</h3><hr />

      {/* 기본 정보 */}
      <div style={styles.formGroup}>
        <FormInput
          label="Name"
          name={"name"}
          value={basicInfo.name}
          onChange={(e) => {
            setBasicInfo((pre) => {
              return { ...pre, name: e.target.value }
            })
          }}
          readOnly={true}
        />
        <FormInput
          label="Type"
          name={"type"}
          value={basicInfo.type}
          onChange={(e) => {
            setBasicInfo((pre) => {
              return { ...pre, type: e.target.value }
            })
          }}
          readOnly={true}
        />
        <FormInput
          label="Title"
          name={"title"}
          value={basicInfo.title}
          onChange={(e) => {
            setBasicInfo((pre) => {
              return { ...pre, title: e.target.value }
            })
          }}
        />

        <FormInput
          label="Layout"
          name={"layout"}
          value={basicInfo.layout}
          onChange={(e) => {
            setBasicInfo((pre) => {
              return { ...pre, layout: e.target.value }
            })
          }}
          inputType="select"
          selectList={["row-text-left", "row-text-right", "row-text-center", "column" ]}
        />

        <Button variant="outline-info" title="저장" onClick={onSubmitSectionBasic} width="200px" size="sm" />
      </div>

      {props.content.lt &&
        <>
          {props.content?.type !== "video" &&
            <>
              {/* texts */}
              {/* {props.content.lt && */}
              <div style={styles.formGroup}>
                <p style={{ marginBottom: 0, fontWeight: 600 }}>
                  Texts &nbsp;&nbsp;
                  <Plus onClick={addText} />
                </p>
                {
                  texts.map((obj, i) => {
                    return (
                      <TextGroup {...props} key={i} idx={i} obj={obj} styles={myStyle} />
                    )
                  })
                }
              </div>
              {/* } */}

              {/* images */}
              {/* {props.content.lt && */}
              <div style={styles.formGroup}>
                <p style={{ marginBottom: 0, fontWeight: 600 }}>
                  {content.name === "jumbotron" ? "Image" : "Image"}
                  &nbsp;&nbsp;
                  {/* {content.name === "jumbotron" && <Plus onClick={addImage} />} image 는 일단 단일 이미지만 */}
                </p>
                {
                  images.map((obj, i) => {
                    return (
                      <ImageGroup {...props} key={i} idx={i} obj={obj} styles={myStyle} />
                    )
                  })
                }
              </div>
              {/* } */}

              {/* links */}
              {/* {props.content.lt && */}
              <div style={styles.formGroup}>
                <p style={{ marginBottom: 0, fontWeight: 600 }}>
                  Link URLs &nbsp;&nbsp;
                  <Plus onClick={addLink} />
                </p>
                {
                  links.map((obj, i) => {
                    return (
                      <LinkGroup {...props} key={i} idx={i} obj={obj} styles={myStyle} />
                    )
                  })
                }
              </div>
              {/* } */}
            </>
          }

          {(props.content?.type === "video") &&
            <div style={styles.formGroup}>
              <VideoForm {...props} obj={content} styles={myStyle} />
            </div>
          }

        </>
      }

    </Section>
  )
}

function TextGroup(props) {
  const { obj, idx, styles } = props

  const { colors } = useStyles()
  const [colorVariant, setColorVariant] = useState([])

  const [textObj, setTextObj] = useState({
    value: "",
    variant: "",
    display: ""
  })

  useEffect(() => {
    const cVariant = ["none"]
    Object.keys(colors).map(cname => {
      if (cname !== "readOnly") cVariant.push(cname)
      return null
    })
    setColorVariant(cVariant)
    setTextObj(obj)
  }, [obj])

  const onSubmit = async (e) => {
    try {
      props.setIsLoading(true);

      // console.log(props.content.name, props.content.type)
      // console.log(idx, textObj)

      const newPageContent = [...props.pageContent]
      const preContent = props.pageContent[props.contentPosition]
      const newTexts = preContent.texts || []
      const newTextObj = { ...textObj }
      newTexts[idx] = newTextObj

      let newContent = {
        ...preContent,
        texts: newTexts,
        lt: Date.now()
      }

      newPageContent[props.contentPosition] = newContent

      // console.log('newContent', newContent)
      // console.log('newTexts', newTexts)

      const params = {
        categoryId: props.post.categoryId,
        postId: props.post.postId,
        body: {
          pageContent: JSON.stringify(newPageContent)
        }
      }

      let res = await updatePost(params)
      if (res.err) throw Error(res.err.message)

      // props.setIsLoading(false);
      // props.onload()
      // window.scrollTo({ top: 0, behavior: "smooth" })
      goTop(props)
    } catch (e) {
      alert(e.message);
    }
  }

  return (
    <div style={styles.addGroupWrap}>
      <FormInput
        size="sm"
        label="Value"
        name={"value"}
        value={textObj.value}
        placeholder="텍스트 내용"
        onChange={(e) => {
          setTextObj(pre => {
            return { ...pre, value: e.target.value }
          })
        }}
      />

      <div style={styles.hGroup}>

        <FormInput style={styles.hInput}
          size="sm"
          label="Color Variant"
          name={"variant"}
          inputType="select"
          selectList={colorVariant}
          value={textObj.variant || colorVariant[0]}
          onChange={(e) => {
            setTextObj(pre => {
              return { ...pre, variant: e.target.value }
            })
          }}
        />

        <FormInput style={styles.hInput}
          size="sm"
          label="Display"
          name={"display"}
          inputType="select"
          selectList={["block", "inline"]}
          value={textObj.display}
          onChange={(e) => {
            setTextObj(pre => {
              return { ...pre, display: e.target.value }
            })
          }}
        />

      </div>

      <Button variant="outline-info" title="저장" onClick={onSubmit} width="200px" size="sm" />
    </div>
  )
}

function ImageGroup(props) {
  const {
    post, pageContent, content,
    obj, idx, styles,
    sectionName, sectionType, isEdit
  } = props

  const s3Prefix = "posting-images"

  const [previewImgUrl, setPreviewImgUrl] = useState("")
  const [imageObj, setImageObj] = useState({ 
    url: "", size: "md",
    // w: "", mw: "", 
  })

  const sizeVariant = [
    "md",     // image : text = 50% : 50%
    "lg",     // image : text = 75% : 25%
    "sm",     // image : text = 25% : 75%
  ]

  const file = {};

  useEffect(() => {
    setPreviewImgUrl(obj?.url || "")
    setImageObj(obj)
  }, [obj])

  const onSubmit = async (e) => {
    try {
      props.setIsLoading(true);

      // console.log(props.content.name, props.content.type)

      let fileRes = {};

      if (!file.current && previewImgUrl === "") {
        throw Error("NO File Selected!!")
      }

      // console.log('obj', obj)


      // 메뉴 이미지 저장 300KB 초과하면 resize
      if (file.current) {
        let resizedImg = file.current;
        const maxSize = (props.content.type === "jumbotron") ? 1000000 : 300000
        if (file.current.size > maxSize) {
          resizedImg = await imageCompress({
            image: file.current,
            maxSizeMB: maxSize / 1000000,
            maxWidthOrHeight: (props.content.type === "jumbotron") ? 1280 : 480
          })
          if (!resizedImg) throw new Error("이미지 처리에 문제가 발생하였습니다.");
        }

        fileRes = await fileUpload(resizedImg, s3Prefix);
        if (fileRes.err) throw new Error("Error in fileUpload!");
      }

      if (debug) {
        console.log("=".repeat(50), props.sectionName, props.sectionType, props.isEdit)
        console.log("post", props.post)
        console.log("pageContent", props.pageContent)
        console.log("content", props.contentPosition, props.content)

        console.log('obj', obj)
        console.log('idx', idx)
        console.log('fileRes', fileRes)
      }

      const newPageContent = [...props.pageContent]
      const preContent = props.pageContent[props.contentPosition]
      const newImages = preContent.images || []
      const newImageObj = { ...imageObj, url: fileRes.key || imageObj.url }
      newImages[idx] = newImageObj

      let newContent = {
        ...preContent,
        images: newImages,
        lt: Date.now()
      }

      // console.log(newContent)

      newPageContent[props.contentPosition] = newContent

      const params = {
        categoryId: props.post.categoryId,
        postId: props.post.postId,
        body: {
          pageContent: JSON.stringify(newPageContent)
        }
      }

      let res = await updatePost(params)
      if (res.err) throw Error(res.err.message)

      props.setIsLoading(false);
      // window.location.reload()
      props.onload()
      window.scrollTo({ top: 0, behavior: "smooth" })
    } catch (e) {
      alert(e.message);
    } finally {
    }
  }

  const onChangeInputFile = (e) => file.current = e.target.files[0]

  return (
    <div style={styles.addGroupWrap}>
      <FormInput
        label="Image URL"
        name={"url"}
        value={previewImgUrl}
        // onChange={(e) => onChange(e.target.value, idx)}
        inputType="file"
        onChangeInputFile={onChangeInputFile}
      />

      <div style={styles.hGroup}>
        {/* <FormInput
          style={styles.hInput}
          label="Width"
          name={"w"}
          value={imageObj.w}
          onChange={(e) => {
            setImageObj(pre => {
              return { ...pre, w: e.target.value }
            })
          }}
        /> */}

        <FormInput
          style={styles.hInput}
          label="Size"
          name={"size"}
          inputType="select"
          selectList={sizeVariant}
          value={imageObj.size || sizeVariant[0]}
          onChange={(e) => {
            setImageObj(pre => {
              return { ...pre, size: e.target.value }
            })
          }}
        />

        {/* <FormInput
          style={styles.hInput}
          label="Mobile Width"
          name={"mw"}
          value={imageObj.mw}
          onChange={(e) => {
            setImageObj(pre => {
              return { ...pre, mw: e.target.value }
            })
          }}
        /> */}
      </div>

      <Button variant="outline-info" title="저장" onClick={onSubmit} width="200px" size="sm" />
    </div>
  )
}

function LinkGroup(props) {
  const { obj, idx, styles } = props

  const [linkObj, setLinkObj] = useState({
    type: "button", variant: "primary", text: "자세히 보기", linkUrl: ""
  })

  useEffect(() => {
    // console.log('linkObj', obj)
    setLinkObj(obj)
  }, [obj])

  const onSubmit = async () => {
    try {
      props.setIsLoading(true);

      // console.log(props.content.name, props.content.type)
      // console.log(idx, linkObj)

      const newPageContent = [...props.pageContent]
      const preContent = props.pageContent[props.contentPosition]
      const newLinks = preContent.links || []
      const newLinkObj = { ...linkObj }
      newLinks[idx] = newLinkObj

      let newContent = {
        ...preContent,
        links: newLinks,
        lt: Date.now()
      }

      newPageContent[props.contentPosition] = newContent

      // console.log('newContent', newContent)
      // console.log('newLinks', newLinks)

      const params = {
        categoryId: props.post.categoryId,
        postId: props.post.postId,
        body: {
          pageContent: JSON.stringify(newPageContent)
        }
      }

      let res = await updatePost(params)
      if (res.err) throw Error(res.err.message)

      props.setIsLoading(false);
      // window.location.reload()
      props.onload()
      window.scrollTo({ top: 0, behavior: "smooth" })

    } catch (e) {
      alert(e.message);
    }
  }

  return (
    <div style={styles.addGroupWrap}>
      <div style={styles.hGroup}>

        <FormInput
          style={styles.hInput}
          label="Text"
          name={"text"}
          value={linkObj.text}
          onChange={(e) => {
            setLinkObj(pre => {
              return { ...pre, text: e.target.value }
            })
          }}
        />

        <FormInput
          style={styles.hInput}
          label="Link URL (or Path)"
          name={"linkUrl"}
          value={linkObj.linkUrl}
          onChange={(e) => {
            setLinkObj(pre => {
              return { ...pre, linkUrl: e.target.value }
            })
          }}
        />

        <FormInput
          style={styles.hInput}
          label="Button Variant"
          name={"variant"}
          inputType="select"
          selectList={buttonVariants}
          value={linkObj.variant}
          onChange={(e) => {
            setLinkObj(pre => {
              return { ...pre, variant: e.target.value }
            })
          }}
        />
      </div>

      <Button variant="outline-info" title="저장" onClick={onSubmit} width="200px" size="sm" />

    </div>
  )
}

function VideoForm(props) {
  const { obj, styles } = props

  const [videoUrl, setVideoUrl] = useState("")
  const [youtubeId, setYoutubeId] = useState("")

  useEffect(() => {
    setVideoUrl(obj.videoUrl || "https://www.youtube.com/watch?v=v1VRF-NDQ5c")
    setYoutubeId(obj.youtubeId || "W03y2dpxJjw")
  }, [obj])

  const onSubmit = async () => {
    try {
      props.setIsLoading(true);

      const newPageContent = [...props.pageContent]
      const preContent = props.pageContent[props.contentPosition]

      let newContent = {
        ...preContent,
        videoUrl, youtubeId,
        lt: Date.now()
      }

      newPageContent[props.contentPosition] = newContent

      // console.log(newPageContent)

      const params = {
        categoryId: props.post.categoryId,
        postId: props.post.postId,
        body: {
          pageContent: JSON.stringify(newPageContent)
        }
      }

      let res = await updatePost(params)
      if (res.err) throw Error(res.err.message)

      props.setIsLoading(false);
      // window.location.reload()
      props.onload()
      window.scrollTo({ top: 0, behavior: "smooth" })
    } catch (e) {
      alert(e.message);
    }
  }

  return (
    <div style={styles.addGroupWrap}>

      <FormInput
        // style={styles.hInput}
        label="Video URL"
        name={"videoUrl"}
        value={videoUrl}
        onChange={(e) => setVideoUrl(e.target.value)}
      />

      <FormInput
        // style={styles.hInput}
        label="Youtube ID"
        name={"youtubeId"}
        value={youtubeId}
        onChange={(e) => setYoutubeId(e.target.value)}
      />

      <Button variant="outline-info" title="저장" onClick={onSubmit} width="200px" size="sm" />

    </div>
  )

}

function goTop(props) {
  props.setIsLoading(false);
  // window.location.reload()
  props.onload()
  window.scrollTo({ top: 0, behavior: "smooth" })
}