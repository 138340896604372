import React from "react";
import { Page } from "../components";
import NewShops from "./store/NewShops";

export default function Home(props) {
  return (
    <Page stretch title="신규 가맹점 통계">
      <NewShops {...props} />
    </Page>
  );
}
