import React from "react";
import "./LogMessage.css";

function createMarkup(html) {
  return { __html: html };
}

/**
 * 
 * @param {String} param.title 
 * @param {String} param.msg
 * @returns 
 */
export function Log({ title, msg }) {
  return (
    <div className="onl-log">
      {/* <p className="onl-log-title">{title || "Log Message"}</p> */}
      <div dangerouslySetInnerHTML={createMarkup(msg)} />
    </div>
  );
}

/**
 * @info_object2yaml_과_비슷함
 * 
 * @param {*} obj Object | Array
 * @param {*} depth
 * @param {*} parentIsArray 
 * @returns 
 */
export function obj2Html(obj, depth = 0, parentIsArray = false) {
  const indent = 4;
  let keys = Object.keys(obj);
  let logMsg = "";

  const isArray = Array.isArray(obj);

  for (let i = 0; i < keys.length; i++) {
    // console.log(depth, keys[i] , Array.isArray(obj[keys[i]]), parentIsArray);

    // 
    // If value is object | array, then write key and og looping
    // 
    if (obj[keys[i]] !== null && typeof obj[keys[i]] === "object") {

      // 
      // If Array, write "-", not index
      // 
      if (isArray) {
        logMsg += `<p><b>${"&nbsp;".repeat((depth) * indent)}-</b></p>`;
      } else {
        logMsg += `<p><b>${"&nbsp;".repeat((depth) * indent)}${keys[i]}</b></p>`;
      }

      //
      // Looping one more depth
      //
      if (Array.isArray(obj[keys[i]])) {
        logMsg += obj2Html(obj[keys[i]], depth + 1, true);
      } else {
        logMsg += obj2Html(obj[keys[i]], depth + 1);
      }
    }

    // 
    // if value not object | array, then write value
    // 
    else {
      if (isArray)
        logMsg += `<p>${"&nbsp;".repeat((depth) * indent)}<b>-</b> ${obj[keys[i]]}</p>`;
      else
        logMsg += `<p>${"&nbsp;".repeat((depth) * indent)}<b>${keys[i]}</b> ${obj[keys[i]]}</p>`;
    }
  }

  return logMsg;
}
