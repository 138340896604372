import React, { useState, useEffect } from "react";
import { useStyles, ModalForm, FileInputMd } from "../../components2";
import PostFormSection from "../../components2/PostFormSection";
import { debug } from "../../settings";
// import { postCreate, postUpdate, postDelete } from "../../lib/ec2-api-cms-lib";
import { createPost, updatePost, deletePost } from "../../lib/ec2-api-cms2-lib";
import { cmsPost, cmsNotification } from "../../settings/cms2-db";
import { fileUpload } from "../../lib/apiLib";
import { imageCompress, tsId, getTimestampFromNow } from "../../lib/utils";
import { useSelector } from "react-redux";
import { md2html } from "../../lib/md-utils";

export default function PostModal(props) {
  const { setIsVisible, visible, onload, post, isEdit, categoryList, category } = props;

  const categoryType = category.categoryType

  const title = isEdit
    ? post.title + " 수정"
    : categoryType + " 포스팅";

  const { user } = useSelector((state) => state.user);

  const { gStyle } = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  // 
  // category.selectList 에 catList를 추가, category.categoryName 을 defaultValue 추가.
  //
  let inputFields = []
  if (categoryType === "Blog") {
    inputFields = updateInputFields(cmsPost.inputFields, categoryList, category)
  }
  if (categoryType === "Notification") {
    inputFields = updateInputFields(cmsNotification.inputFields, categoryList, category)
  }

  // 
  // categoryType Blog
  // 
  const [mdText, setMdText] = useState("");
  const [htmlText, setHtmlText] = useState("")

  // 
  // categoryType Notification
  // 
  const [notiText, setNotiText] = useState("")
  const [expDate, setExpDate] = useState("2024-10-01")
  const [today, setToday] = useState("2024-10-01")

  const file = {};

  useEffect(() => {
    if (categoryType === "Blog") {
      // if(post.mdContent) setMdText(post.mdContent)
      // if(post.htmlContent) setHtmlText(post.htmlContent)
      setMdText(post.mdContent || "")
      setHtmlText(post.htmlContent || "")

    }
    if (categoryType === "Notification") {
      const mDate = getDateFromTs(Date.now())
      setToday(mDate)

      let eDate = mDate
      if(post.expireTs) {
        eDate = getDateFromTs(post.expireTs)
      }
      setExpDate(eDate)
    }
  }, [post, category])


  const onChangeInputFileMdFile = (e) => {
    file.currentMd = e.target.files[0];
    if (debug) console.log("file.currentMd", file.currentMd);
    let reader = new FileReader()
    reader.onload = () => {
      setMdText(reader.result)

      // 
      // 결과 값이 VFile object이다.
      // 
      const htmlObj = md2html(reader.result)
      setHtmlText(htmlObj.value)
    }
    reader.readAsText(file.currentMd)
  }

  const onChangeInputFileImage = (e) => {
    file.current = e.target.files[0];
    // if (debug) console.log("file.current", file.current);
  }

  const onSubmit = async (fields) => {
    try {
      setIsLoading(true);

      if(categoryType==="Blog") {
        if (!isEdit && mdText === "") throw Error("MD File을 선택하세요!");
      }

      if (debug) {
        console.log('id', user.id)
        console.log('\nmdText\n', mdText)
        console.log('\nhtmlText\n', htmlText)
      }

      let fileRes = {};
      if (file.current) {

        // 
        // 메뉴 이미지 저장 300KB 초과하면 resize
        // 
        let resizedImg = file.current;
        if (file.current.size > 300000) {
          resizedImg = await imageCompress({
            image: file.current,
            maxSizeMB: 0.3,
            maxWidthOrHeight: 640
          })
          if (!resizedImg) throw new Error("이미지 처리에 문제가 발생하였습니다.");
        }

        fileRes = await fileUpload(resizedImg, user.id);
        if (fileRes.err) throw new Error("Error in fileUpload!");

        // 
        // Thumbnail 생성, 저장 - public/user.id/tn
        //
        resizedImg = await imageCompress({
          image: file.current,
          maxSizeMB: 0.03,    // 30kB 미만
          maxWidthOrHeight: 120
        })
        if (!resizedImg) throw new Error("이미지 처리에 문제가 발생하였습니다.");

        // 
        // 저장만 하므로 fileRes를 덮어쓰지 말아야 한다.
        // 
        const tnRes = await fileUpload(resizedImg, user.id + "/tn");
        if (tnRes.err) throw new Error("Error in fileUpload!");
      }

      if (debug) console.log('fileRes', fileRes);

      let body = {}

      if (categoryType === "Blog") {
        body = {
          ...fields,
          image: fileRes.key || fields.image || "",
          mdContent: mdText,
          htmlContent: htmlText
        };
      }
      if (categoryType === "Notification") {
        body = {
          ...fields,
          image: fileRes.key || fields.image || "",
          expireTs: getTimestampFromNow({now: new Date(expDate)})
        };
      }

      const params = {
        categoryId: category.categoryId,
        postId: isEdit ? post.postId : tsId(),
        body
      }

      // console.log('isEdit', isEdit)
      // console.log('params', params)

      // throw Error('Test....')

      let res;
      if (isEdit) {
        res = await updatePost(params)
      } else {
        res = await createPost(params)
      }
      if (res.err) throw Error(res.err.message);
      await onload()
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false)
      setIsVisible(false)
    }
  }

  const styles = {
    text: { ...gStyle.text, marginBottom: "3px" },
    formGroup: { margin: 0, padding: 5, marginBottom: 15 }
  };

  return (
    <ModalForm visible={visible} setIsVisible={setIsVisible} title={title}>

      {categoryType === "Blog" &&
        <div style={styles.formGroup}>
          <FileInputMd
            label="MD File Upload"
            onChangeInputFile={onChangeInputFileMdFile}
            inputType="file"
          />
        </div>
      }

      {/* 게시만료일 선택, timestamp로 변경하여 저장  */}
      {categoryType === "Notification" &&
        <div style={styles.formGroup}>
          <DateInput
            value={expDate}
            minDate={today}
            onChange={e => setExpDate(e.target.value)}
          />
        </div>
      }

      <div style={styles.formGroup}>
        <PostFormSection
          {...props}
          isEdit={isEdit}
          editItems={post}
          inputFields={inputFields}
          onSubmit={onSubmit}
          onChangeInputFile={onChangeInputFileImage}
          onSubmitText={isEdit ? "수 정" : "등 록"}
          isLoading={isLoading}
        />
      </div>

    </ModalForm>
  )
}

function updateInputFields(inputFields, cList, cat) {
  let newInputFields = [...inputFields]
  const catNames = cList.map(c => c.categoryName)
  const i = newInputFields.findIndex(f => f.name === "categoryName")
  newInputFields[i].selectList = catNames
  newInputFields[i].defaultValue = cat.categoryName
  newInputFields[i].readOnly = true
  return newInputFields
}

function getDateFromTs(ts) {
  const now = new Date(ts)
  const yr = now.getFullYear()
  const mon = now.getMonth()
  const da = now.getDate()

  const dateStr = yr.toString()
    + "-" + (mon + 1).toString().padStart(2, "0")
    + "-" + da.toString().padStart(2, "0")

  return dateStr
}

/**
 * @TODO FormInputs 으로 이동할 것 
 */

function DateInput(props) {
  const { value, onChange, minDate } = props

  const { gStyle, colors } = useStyles()

  const styles = {
    input: {
      fontSize: gStyle.text.fontSize,
      // display: display,
      // width: width || "100%",
      width: "100%",
      height: 44,
      border: "1px solid",
      // borderColor: validated ? colors.secondary : colors.warning,
      borderColor: colors.secondary,
      paddingLeft: 10, paddingRight: 10,
      color: colors.dark,
      borderRadius: 5,
      // backgroundColor: readOnly ? colors.readOnly : "white"
    },
    wrap: { marginBottom: "15px" },
    // label: { margin: "5px 20px 5px 5px", fontWeight: "500" }
    label: { margin: "0px 20px 0px 0px", fontWeight: "500" },
    // borderRadius: 5
  }

  return (
    <div>
      <p style={styles.label}>Expire Date</p>
      <input
        style={styles.input}
        type="date"
        // id="start"
        name="expireDate"
        value={value}
        onChange={onChange}
        min={minDate}
      // max="2018-12-31"
      />
    </div>
  )
}