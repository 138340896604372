import React, { useEffect, useState } from "react";
import { Page, Section, LoaderFullPage } from "../../../components";
import { debug } from "../../../settings";
import { getPosMenu, getOnlMenu, validateMenus } from "../../../lib-pos";
import { CustomTable } from "../../../components2";
import { useStyles } from "../../../components2";
import SearchStore from "../components/SearchStore";

const headerCheckList = [
  { name: "checkItem", displayName: "체크항목" },
  { name: "value", displayName: "결과값" }
]

// 
// Unionpos format
// 
const headerUnregisteredPosItems_unionpos = [
  { name: "ItemCode", displayName: "ItemCode" },
  { name: "ItemName", displayName: "ItemName" }
]

const headerUnregisteredPosItems_okpos = [
  { name: "PROD_CD", displayName: "ItemCode" },
  { name: "PROD_NM", displayName: "ItemName" }
]

// 
// 공통 포맷
// 
const headerUndeletedOnlItemList = [
  { name: "itemCode", displayName: "ItemCode" },
  { name: "itemName", displayName: "ItemName" },
  { name: "itemName2", displayName: "ItemName2" }
]

// 
// okpos tuClassList
// 
const headerFaileTuClassList = [
  { name: "itemCode", displayName: "Item Code" },
  { name: "itemName", displayName: "Item Name" },
  { name: "OnlTuClassList", displayName: "Onl TuClassList" },
  { name: "PosTuClassList", displayName: "Pos TuClassList" }
]

let cnt = 0;
export default function MenuCheckUnion(props) {
  const { shopInfo } = props

  // const title = "가맹점 메뉴 업데이트 검증"
  const { colors } = useStyles()

  // const [isLoading, setIsLoading] = useState(false);
  // const [shopInfo, setShopInfo] = useState({})
  const [cListPos, setCheckListPos] = useState([])
  const [cListOnl, setCheckListOnl] = useState([])
  const [val, setValidated] = useState(false)

  const [failPosList, setFailPosList] = useState([])
  const [undeletedList, setUndeletedList] = useState([])

  useEffect(() => {
    async function start() {
      let resPos = await getPosMenu({ shopInfo })
      if (resPos.err) throw Error(resPos.err.message)

      let resOnl = await getOnlMenu({ shopInfo })
      if (resOnl.err) throw Error(resOnl.err.message)

      const {
        checkListPos, checkListOnl, validated,
        notFoundPosItemList, undeletedOnlItemList,
        invalidOnlTuClassList
      } = validateMenus({
        shopInfo,
        posMenu: resPos.result,
        onlMenu: resOnl.result
      })

      if (debug) {
        console.log('checkListPos', checkListPos)
        console.log('checkListOnl', checkListOnl)
        console.log('validated', validated)
        console.log('undeletedOnlItemList', undeletedOnlItemList)
        console.log('notFoundPosItemList', notFoundPosItemList)
        console.log('invalidOnlTuClassList', invalidOnlTuClassList)
      }

      setCheckListPos(checkListPos)
      setCheckListOnl(checkListOnl)
      setValidated(validated)
      setFailPosList(notFoundPosItemList)
      setUndeletedList(undeletedOnlItemList)

    }
    start()

  }, [])

  return (
    <>
      {
        cListOnl.length > 0 &&
        <Section bgColor="#fff">
          <h2>
            <span style={{ color: colors.primary }}>{shopInfo.shopName}</span>
            &nbsp;&nbsp;메뉴검증 결과 :&nbsp;&nbsp;
            <span style={{ color: colors.danger }}>{val ? "OK" : "FAILED"}</span>
          </h2>

          <h5>{shopInfo.posName} 메뉴</h5>
          <CustomTable
            {...props}
            tableHeader={headerCheckList}
            rowItems={cListPos}
            rowItemKey="checkItem"
          />
          <br />

          <h5>ONL 메뉴</h5>
          <CustomTable
            {...props}
            tableHeader={headerCheckList}
            rowItems={cListOnl}
            rowItemKey="checkItem"
          />
        </Section>
      }

      {
        failPosList.length > 0 &&
        <Section bgColor="#fff">
          <h5>미등록 메뉴 리스트: ONL서버에는 등록이 안된 POS 메뉴 {failPosList.length} items</h5>
          <CustomTable
            {...props}
            tableHeader={
              shopInfo.posName === "UNIONPOS"
                ? headerUnregisteredPosItems_unionpos
                : headerUnregisteredPosItems_okpos
            }
            rowItems={failPosList}
            rowItemKey="ItemCode"
          />
        </Section>
      }

      {
        undeletedList.length > 0 &&
        <Section bgColor="#fff">
          <h5>미삭제 메뉴 리스트: ONL서버에 불필요한 메뉴 {undeletedList.length} items</h5>
          <CustomTable
            {...props}
            tableHeader={headerUndeletedOnlItemList}
            rowItems={undeletedList}
            rowItemKey="ItemCode"
          />
        </Section>
      }

    </>
  );
}
