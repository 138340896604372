import { Storage, Auth, API } from "aws-amplify";
import { conf, debug } from "../settings";

/** ***************************************************************************
 * @StorageApi_AWS_S3
 *  **************************************************************************/

/**
 * @Storage_put 은 /public/filename 형태로 저장 /public 이 퍼블릭이므로 filename 만 있으면 불러올수 있음.
 */
export async function s3UploadPublic(file, foldername) {
  const filename = `${Date.now()}-${file.name}`;
  const filepath = foldername ? foldername + "/" + filename : filename;
  const stored = await Storage.put(filepath, file, {
    contentType: file.type,
  });
  return stored.key; // stored = {key}
}

/**
 * @Storage_vault_put 은 /private/userid/filename 형태로 저장 여기서는 사용하지 않음.
 */
export async function s3UploadPrivate(file) {
  const filename = `${Date.now()}-${file.name}`;
  const stored = await Storage.vault.put(filename, file, {
    contentType: file.type,
  });
  return stored.key; // stored = {key}
}

/**
 * @info_filename_실제_선택파일명과_동일하게_하였음 파일이 너무 많아지므로, 단 여기에서는 문제가 없어보임.
 *
 * @param {Object} file file object
 * @param {*} prefix s3Url/public/prefix/filename 형태로 저장됨
 * @returns
 */
export async function fileUpload(file, prefix) {
  try {
    if (debug) console.log(file, prefix);
    if (file && file.size > conf.MAX_FILE_SIZE) {
      const msg = `파일크기는 ${conf.MAX_FILE_SIZE / 1000000} MB 미만이어야 합니다.`;
      return { err: { message: msg } };
    }
    if (file) {
      const filename = `${file.name}`;
      const filepath = prefix ? prefix + "/" + filename : filename;
      const stored = await Storage.put(filepath, file, {
        contentType: file.type,
      });

      if (debug) console.log("stored", stored);

      //
      // stored.key === filepath 이다.
      //
      return { key: `${conf.s3.publicUrl}/${stored.key}` };
    }
    return { err: { message: "NOFILE" } };
  } catch (e) {
    return { err: e.message };
  }
}

export async function fileUploadEncodingTest(file, prefix) {
  try {
    if (debug) console.log(file, prefix);
    if (file && file.size > conf.MAX_FILE_SIZE) {
      const msg = `파일크기는 ${conf.MAX_FILE_SIZE / 1000000} MB 미만이어야 합니다.`;
      return { err: { message: msg } };
    }
    if (file) {
      const filename = `${file.name}`;
      const filepath = prefix ? prefix + "/" + filename : filename;

      // 
      // 한글 파일 문제 해결을 위해 filename을 utf8로 인코딩하려고 했으나.
      // S3 upload시 %를 다시 인코딩하여 예상된 url이 아님
      // const eFilepath = encodeURI(filepath)

      const stored = await Storage.put(filepath, file, {
        // const stored = await Storage.put(eFilepath, file, {
        contentType: file.type,
      });

      if (debug) console.log("stored", stored);

      //
      // stored.key === filepath 이다.
      //
      return { key: `${conf.s3.publicUrl}/${stored.key}` };
    }
    return { err: { message: "NOFILE" } };
  } catch (e) {
    return { err: e.message };
  }
}

/** ***************************************************************************
 * @Auth_Api_AWS_Cognito
 *  **************************************************************************/
export const logIn = (username, password) => {
  return Auth.signIn(username, password);
};

// attributes {phone_number, email} optional
export const signUp = ({ username, password, attributes }) => {
  return Auth.signUp({ username, password, attributes });
};

/** @deprecated */
export const confirmSignUp = ({ username, confirmationCode }) => {
  return Auth.confirmSignUp(username, confirmationCode);
};

/** @deprecated email only */
export const resendSignUp = ({ username }) => {
  return Auth.resendSignUp(username);
};

export const currentSession = () => {
  return Auth.currentSession();
};
export const currentUser = () => {
  return Auth.currentUserPoolUser();
};
export const currentCredentials = () => {
  return Auth.currentCredentials();
};

/** @deprecated */
export function changePassword(oldPassword, newPassword) {
  Auth.currentAuthenticatedUser().then((user) => {
    return Auth.changePassword(user, oldPassword, newPassword);
  });
}
/** @deprecated */
export function forgotPassword(username) {
  return Auth.forgotPassword(username);
}
/** @deprecated */
export function forgotPasswordSubmit(username, code, password) {
  return Auth.forgotPasswordSubmit(username, code, password);
}

/** ***************************************************************************
 * @smsApi
 *  **************************************************************************/

const sms = conf.apiGateway.SMS.API_NAME;

export const codeGet = ({ phone_number }) => {
  // console.log("username: ", username);
  return API.get(sms, `/code`, {
    queryStringParameters: { phone_number },
  });
};

/** *************************************************************************** 
 * @Users_Api
 *  **************************************************************************/
const user = conf.apiGateway.USER.API_NAME;

export const userCreate = ({ body }) => {
  return API.post(user, `/user`, { body: body });
};
export const userGet = ({ username }) => {
  return API.get(user, `/user?username=${username}`);
};
export const userList = ({ userType }) => {
  return API.get(user, `/user/list?userType=${userType}`);
};
export const userUpdate = ({ username, body }) => {
  return API.put(user, `/user?username=${username}`, { body: body });
};
export const userDelete = ({ username }) => {
  return API.del(user, `/user?username=${username}`);
};
export const userAttachPolicy = ({ body }) => {
  // body: {target: identityId, policyName: 'myPolicy'}
  // return API.put(user, `/user/attach-policy`, { body: body });
  return API.put(user, `/attach-policy`, { body: body });
};
export const isExistUsername = ({ username }) => {
  return API.get(user, `/cognito-user?username=${username}`);
}

/** *************************************************************************** 
 * @Shop_Api
 *  **************************************************************************/
const shop = conf.apiGateway.SHOP.API_NAME;

export const shopCreate = ({ shopId, body }) => {
  return API.post(shop, `/shop/${shopId}`, { body: body });
};
export const shopGet = ({ shopId }) => {
  return API.get(shop, `/shop/${shopId}`);
};
export const shopUpdate = ({ shopId, body }) => {
  return API.put(shop, `/shop/${shopId}`, { body: body });
};
export const shopDelete = ({ shopId }) => {
  return API.del(shop, `/admin/shop/${shopId}`);
};
export const shopListByShopName = ({ shopName }) => {
  return API.get(shop, `/shop/list?shopName=${shopName}`);
};
export const shopListByLicenseNo = ({ licenseNo }) => {
  return API.get(shop, `/shop/list?licenseNo=${licenseNo}`);
};

/** ***************************************************************************
 * @ShopItem_Api
 *  **************************************************************************/
export const shopItemCreate = ({ shopId, body }) => {
  return API.post(shop, `/shopitem/${shopId}`, { body: body });
};
export const shopItemGet = ({ shopId, id }) => {
  return API.get(shop, `/shopitem/${shopId}?id=${id}`);
};
export const shopItemList = ({ shopId }) => {
  return API.get(shop, `/shopitem/${shopId}/list`);
};
export const shopItemUpdate = ({ shopId, id, body }) => {
  return API.put(shop, `/shopitem/${shopId}?id=${id}`, { body: body });
};
export const shopItemDelete = ({ shopId, id }) => {
  return API.del(shop, `/shopitem/${shopId}?id=${id}`);
};

/** ***************************************************************************
 * @ShopItemOption_Api
 *  **************************************************************************/
export const shopItemOptionCreate = ({ shopId, body }) => {
  // body={optionName=required, 저장할 속섲들...}
  return API.post(shop, `/shopitem-option/${shopId}`, { body: body });
};
export const shopItemOptionGet = ({ shopId, optionName }) => {
  return API.get(shop, `/shopitem-option/${shopId}?optionName=${optionName}`);
};
export const shopItemOptionList = ({ shopId }) => {
  return API.get(shop, `/shopitem-option/${shopId}/list`);
};
export const shopItemOptionUpdate = ({ shopId, optionName, body }) => {
  // body={수정할 속성들...}
  return API.put(shop, `/shopitem-option/${shopId}?optionName=${optionName}`, { body: body });
};
export const shopItemOptionDelete = ({ shopId, optionName }) => {
  return API.del(shop, `/shopitem-option/${shopId}?optionName=${optionName}`);
};

// /** ***************************************************************************
//  * @CommonStack_Payments
//  *  **************************************************************************/
// const pay = conf.apiGateway.PAY.API_NAME;

// /** ***************************************************************************
//  * @Toss
//  *  **************************************************************************/
// export const tossPayByCard = ({ body }) => {
//   return API.post(pay, `/toss/pay/key-in`, { body });
// };
// export const tossPayCancel = ({ paymentKey, body }) => {
//   return API.post(pay, `/toss/pay/${paymentKey}/cancel`, { body });
// };
// export const tossPayView = ({ paymentKey, shopId }) => {
//   return API.get(
//     pay,
//     `/toss/pay/${paymentKey}?shopId=${shopId}&pg=토스페이먼츠`
//   );
// };
// export const tossTransactionsView = ({ startDate, endDate, shopId, pg }) => {
//   return API.get(
//     pay,
//     `/toss/transactions?startDate=${startDate}&endDate=${endDate}&shopId=${shopId}&pg=${pg}`
//   );
// };

// /**
//  * 
//  * @param {*} param.body {shopId, orderId, paymentKey, amount, orderName}
//  */
// export const tossPayPopup = ({ body }) => {
//   return API.post(pay, `/toss/pay/popup`, { body: { ...body, pg: "토스페이먼츠" } });
// };

// /** ***************************************************************************
//  * @ShopKey
//  *  **************************************************************************/
// export const shopKeyCreate = ({ shopId, body }) => {
//   return API.post(pay, `/shopkey/${shopId}`, { body: body });
// };
// export const shopKeyGet = ({ shopId, pg, body }) => {
//   return API.get(pay, `/shopkey/${shopId}?pg=${pg}`);
// };
// export const shopKeyList = ({ shopId }) => {
//   return API.get(pay, `/shopkey/${shopId}/list`);
// };
// export const shopKeyUpdate = ({ shopId, pg, body }) => {
//   return API.put(pay, `/shopkey/${shopId}?pg=${pg}`, { body: body });
// };
// export const shopKeyDelete = ({ shopId, pg }) => {
//   return API.del(pay, `/shopkey/${shopId}?pg=${pg}`);
// };

// /** ***************************************************************************
//  * @PayMethod
//  *  **************************************************************************/
// export const payMethodCreate = ({ userId, body }) => {
//   return API.post(pay, `/paymethod/${userId}`, { body: body });
// };
// export const payMethodGet = ({ userId, id }) => {
//   return API.get(pay, `/paymethod/${userId}?id=${id}`);
// };
// export const payMethodList = ({ userId }) => {
//   return API.get(pay, `/paymethod/${userId}/list`);
// };
// export const payMethodUpdate = ({ userId, id, body }) => {
//   return API.put(pay, `/paymethod/${userId}?id=${id}`, { body: body });
// };
// export const payMethodDelete = ({ userId, id }) => {
//   return API.del(pay, `/paymethod/${userId}?id=${id}`);
// };

// /** ***************************************************************************
//  * @PayRecord
//  *  **************************************************************************/
// export const payRecordCreate = ({ userId, body }) => {
//   return API.post(pay, `/payrecord/${userId}`, { body: body });
// };
// export const payRecordGet = ({ userId, id }) => {
//   return API.get(pay, `/payrecord/${userId}?id=${id}`);
// };
// export const payRecordList = ({ userId }) => {
//   return API.get(pay, `/payrecord/${userId}/list`);
// };

/** ***************************************************************************
 * @Tags_Api
 *  **************************************************************************/
const tag = conf.apiGateway.TAG.API_NAME;

/** ***************************************************************************
 * @TagVersion
 * @params_docVersion : 2022-03-01
 * @params_tagVersion : ver3 | ver4
 *  ver3: EM4423HOTP, Type 2
 *  ver4: NXP NTAG5 Switch type 5
 *  **************************************************************************/
export const tagVersionCreate = ({ docVersion, body }) => {
  return API.post(tag, `/admin/version/${docVersion}`, { body: body });
};
export const tagVersionGet = ({ docVersion, tagVersion }) => {
  return API.get(tag, `/admin/version/${docVersion}?tagVersion=${tagVersion}`);
};
export const tagVersionList = ({ docVersion }) => {
  return API.get(tag, `/admin/version/${docVersion}/list`);
};
export const tagVersionUpdate = ({ docVersion, tagVersion, body }) => {
  return API.put(tag, `/admin/version/${docVersion}?tagVersion=${tagVersion}`, {
    body: body,
  });
};

/** ***************************************************************************
 * @NfcTag
 *  **************************************************************************/
export const nfcTagCreate = ({ ver, body }) => {
  return API.post(tag, `/admin/nfctag/${ver}`, { body: body });
};
export const nfcTagGet = ({ ver, uid }) => {
  return API.get(tag, `/admin/nfctag/${ver}?uid=${uid}`);
};
export const nfcTagList = ({ ver, shopId }) => {
  return API.get(tag, `/admin/nfctag/${ver}/list?shopId=${shopId}`);
};
export const nfcTagUpdate = ({ ver, uid, body }) => {
  return API.put(tag, `/admin/nfctag/${ver}?uid=${uid}`, {
    body: body,
  });
};

/** ***************************************************************************
 * @NTAG424DNA_Crypto
 *  **************************************************************************/
export const ntag424Test = ({ body }) => {
  return API.post(tag, `/admin/nfctag/ntag424/test`, { body });
};
export const ntag424Enc = ({ body }) => {
  return API.post(tag, `/admin/nfctag/ntag424/enc`, { body });
};
export const ntag424Dec = ({ body }) => {
  return API.post(tag, `/admin/nfctag/ntag424/dec`, { body });
};
export const ntag424Cmac = ({ body }) => {
  return API.post(tag, `/admin/nfctag/ntag424/cmac`, { body });
};
export const ntag424Sesskeys = ({ body }) => {
  return API.post(tag, `/admin/nfctag/ntag424/sesskeys`, { body });
};

/** ***************************************************************************
 * @Order_Api
 *  **************************************************************************/
const order = conf.apiGateway.ORDER.API_NAME;

export const orderCreate = ({ shopId, body }) => {
  return API.post(order, `/order-records/${shopId}`, { body: body });
};
export const orderGet = ({ shopId, orderId }) => {
  return API.get(order, `/order-records/${shopId}?orderId=${orderId}`);
};
export const orderList = ({ shopId, start_time, end_time }) => {
  return API.get(order, `/order-records/${shopId}/list`, {
    queryStringParameters: { start_time, end_time },
  });
};

/** ***************************************************************************
 * @Pos_Api
 *  **************************************************************************/
const pos = conf.apiGateway.POS.API_NAME;

export const unionposProxy = ({ apiName, body }) => {
  // 
  // apiName: 아래 중 하나임.
  //  shopInfo | groupList | itemList | soldoutList | imageList | tableList | descriptionList | order
  // 
  return API.post(pos, `/unionpos?apiName=${apiName}&mode=debug`, { body });
};

export const unionposCopyImage = ({ body }) => {
  /**
   * @info_unionpos_이미지를_download_하여_aws_s3Strorage로_copy함
   * @body shopId, id, imgUrl
   */
  return API.post(pos, `/unionpos/copy-image`, { body });
};
