import React from "react";
import { Table } from "react-bootstrap";
import { useStyles } from "./ui";

export function CustomTable(props) {
  const { tableHeader, rowItems, onClickItem } = props;
  const { colors } = useStyles()
  const styles = {
    head: { color: '#fff', backgroundColor: colors.dark },
    tr: { cursor: onClickItem ? "pointer" : "auto", },
    text: { padding: "2px 5px" },
    num: { padding: "2px 15px 2px", textAlign: 'right' },
  }

  return (
    <Table striped>
      <thead style={styles.head}>
        <tr>
          {
            tableHeader.map((item) => {
              return (
                <th
                  key={item.name}
                  style={item.type === 'number' ? styles.num : styles.text}
                >
                  {item.displayName}
                </th>
              )
            })
          }
        </tr>
      </thead>
      <tbody>
        {
          rowItems.map((obj, i) => {
            return (
              <tr key={"key" + i}
                onClick={() => {
                  if (onClickItem) onClickItem(obj)
                }}
                style={styles.tr}
              >
                {
                  tableHeader.map((item, j) => {
                    let value = (item.name === "lastModified" || item.name === "created")
                      ? new Date(obj[item.name]).toLocaleDateString()
                      : (item.name === "time")
                        ? new Date(obj[item.name]).toLocaleString()
                        : obj[item.name]

                    if (typeof value === "string" && value.length > 50)
                      value = value.substring(0, 50) + "...";

                    if (typeof value === "boolean") value = value ? "Yes" : "-";

                    return (
                      <td
                        key={item.name + j}
                        style={typeof value === "number" ? styles.num : styles.text}
                      >
                        {value}
                      </td>
                    );
                  })
                }
              </tr>
            )
          })
        }
      </tbody>
    </Table>
  )
}
