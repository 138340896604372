import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isMobile: false,
  type: null, // android, ios
};

export const browser = createSlice({
  name: "browser",
  initialState,
  reducers: {    
    setIsMobile: (state, action) => {      
      state.isMobile = action.payload.isMobile;
    },
    setMobileType: (state, action) => {      
      state.type = action.payload.type;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsMobile, setMobileType } = browser.actions;

export default browser.reducer;
