import React, { useState, useEffect } from "react";
import { Page, Section } from "../../components";
import SignupSection from "./SignupSection";
import SignupConfirmSection from "./SignupConfirmSection";

export default function Signup(props) {
  const [userType, setUserType] = useState("");
  const [adminPhoneConfirmCode, setAdminPhoneConfirmCode] = useState("");
  // const [confirmCode, setConfirmCode] = useState("");
  const [newuser, setNewuser] = useState(""); // {username, password}  
  const [title, setTitle] = useState("");

  const titleByUserTypes = {
    shop: "Shop 회원등록",
    admin: "Admin 등록",
  };

  useEffect(() => {    
    setUserType("admin");
    const title = titleByUserTypes["admin"] + (newuser ? " - 관리자 인증" : "");
    setTitle(title);
  }, []);

  return (
    userType !== "" && (
      <Page stretch title={title}>
        <Section bgColor="#fff" padding="10% 20%">
          {adminPhoneConfirmCode === "" ? (
            <SignupSection
              {...props}
              userType={userType}
              setNewuser={setNewuser}
              setServerConfirmNum={setAdminPhoneConfirmCode}
            />
          ) : (
            <SignupConfirmSection
              {...props}
              userType={userType}
              confirmCode={adminPhoneConfirmCode}
              newuser={newuser}
            />
          )}
        </Section>
      </Page>
    )
  );
}
