import React, { useState } from "react";
import { Page, Section, LoaderFullPage } from "../../../components";
import SearchStore from "../components/SearchStore";
import MenuCheckUnion from "./MenuCheckUnion";
import MenuCheckOkpos from "./MenuCheckOkpos";
import { debug } from "../../../settings";

export default function MenuCheck(props) {
  const title = "가맹점 메뉴 업데이트 검증"

  const [isLoading, setIsLoading] = useState(false);
  const [shopInfo, setShopInfo] = useState({})

  const onSelectShop = async (shop) => {
    try {
      setIsLoading(true)
      setShopInfo(shop)

    } catch (e) {
      alert(e.message)
      if (debug) console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Page stretch title={title}>

      <Section bgColor="#fff">
        <SearchStore
          onSelectStore={onSelectShop}
          defaultValue={debug ? "오아시스" : ""}
        />
      </Section>

      {shopInfo.posName === "UNIONPOS" &&
        <MenuCheckUnion shopInfo={shopInfo} setIsLoading={setIsLoading} />}

      {shopInfo.posName === "OKPOS" &&
        <MenuCheckOkpos shopInfo={shopInfo} setIsLoading={setIsLoading} />}

      {(shopInfo.posName === "OASIS_POS" || shopInfo.posName === "DUMMY_POS") &&
        <h2>POS NOT SUPPORTED</h2>}

      {isLoading && <LoaderFullPage />}
    </Page>
  );
}
