import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FormInput, FormInputWithBtn } from "./FormInputs";
import { PostCodeView } from "./Modals";
import { useInputFields } from "../lib/hooksLib";
// import { createFields, validateInputValue } from "../lib/utils";
// import { debug } from "../settings";

/**
 *
 * @param {*} props.inputFields input 데이터의 schima
 * @param {*} props.isEdit register mode register | edit
 * @param {*} props.editItems 이미 등록된 items
 * @returns
 */
// let cnt = 0;
export default function PostFormSection(props) {
  const { 
    inputFields, 
    isEdit, 
    editItems, 
    onSubmit, 
    onCancel, 
    title,
    // onSubmitText,
    // onCancelText, 
    // onChangeInputFile, // inputFile === "file" 일 때 필요함.
    // isLoading
  } = props;

  const originalFields = createFields({ inputFields, editItems: isEdit ? editItems : null });
  const [fields, setFields] = useInputFields(
    { ...originalFields }
  );
  const [viewPostCode, setViewPostCode] = useState(false);

  const validate = () => {
    let val = true;
    inputFields.map((item) => {
      let _val = validateInputValue(item, fields[item.name]);

      // 
      // 생성 상황에서 licenseNo 체크를 위해 추가함.
      // 
      if( !isEdit &&  props.licenseNoChecked !== undefined) val = val && props.licenseNoChecked;
      // if (item.name==="licenseNo") console.log(item.name, "props.licenseNoChecked", props.licenseNoChecked);
      
      val = _val && val;
      return null;
    });
    return val;
  };

  const onReadAddr = (data) => {
    setFields({ target: { name: "zipcode", value: data.zonecode } });
    setFields({ target: { name: "addr1", value: data.address } });
    setViewPostCode(false);
  };

  const myStyle = {
    flexRow: { display: "flex", justifyContent: "space-between" },
  };

  return (
    <div>
      {title &&<><h3>{title}</h3><hr /></>}
      <div>
        {inputFields.map((item, index) => {
          const myProps = createInputProps({item, index, fields, setFields})
          // const myProps = {
          //   key: index,
          //   name: item.name,
          //   label: item.displayName || item.name,
          //   type: item.type || "text",
          //   value: fields[item.name],
          //   onChange: setFields,
          //   placeholder: item.placeholder || item.name,
          //   validated: validateInputValue(item, fields[item.name]),
          //   readOnly: item.readOnly || false,
          //   comment: item.comment,
          //   inputType: item.inputType || "text",
          //   selectList: item.selectList || [],
          //   // required: item.required,
          //   checked: item.inputType === "checkbox" && fields[item.name],
          // };

          // 
          // isEdit: true 이고 보일 필요가 없을 때
          // isEdit: true 이고 보여야 할 때는 readOnly: true 로 해야한다.
          // 즉, create / update 시 동일한 inputFields를 사용하기 위함이다
          // 
          if (isEdit && (item.editable === false)) myProps.readOnly = true;

          if ((item.name === "created" || item.name === "lastModified") && myProps.value) {
            myProps.value = new Date(fields[item.name]).toLocaleString();
            myProps.readOnly = true;
          }

          //
          // 주소확인 Input: labeled input + button
          //
          if (item.name === "zipcode")
            return (
              <FormInputWithBtn
                {...props}
                {...myProps}
                btnText="주소찾기"
                onClick={() => setViewPostCode(true)}
              />
            );

          //
          // 일반 Input: labeled input
          // inputFile의 경우 parent 에서 넘어온
          // onChangeInputFile 함수가 자동으로 넘겨진다.
          //
          return <FormInput {...props} {...myProps} />;
        })}
        <hr />
        <div style={myStyle.flexRow}>
          <Button
            variant="warning"
            onClick={() => (onCancel ? onCancel() : props.history.goBack())}
          >
            취소
          </Button>
          <Button onClick={() => onSubmit(fields)} disabled={!validate()}>
            확인
          </Button>
        </div>
      </div>

      <PostCodeView
        showModal={viewPostCode}
        unsetShowPostCode={() => setViewPostCode(false)}
        onRead={onReadAddr}
      />
    </div>
  );
}

/**
 * 
 * @param {Array} param.inputFields
 * @param {Object} param.editItems
 * @returns 
 */
export function createFields({ inputFields, editItems }) {
  let fields = {};
  const isEdit =
    editItems !== null &&
    editItems !== undefined &&
    Object.keys(editItems).length > 0;
  for (let i = 0; i < inputFields.length; i++) {
    let item = inputFields[i];

    /**
     * @WARNING_editItems_속성이_undefined이면_default값을_부여함
     * @그렇지_않으면_uncontrolled_warning이_발생함
     */
    if (isEdit) {
      if (editItems[item.name] === undefined) {
        if (item.type === "number")
          fields[item.name] = item.defaultValue || 0;
        else if (item.inputType === "checkbox")
          fields[item.name] = false;
        else if (item.inputType === "select")
          fields[item.name] = item.defaultValue || item.selectList[0];
        else
          fields[item.name] = item.defaultValue || "";
      } else {
        fields[item.name] = editItems[item.name];
      }
    } else {
      if (item.defaultValue === undefined) {
        if (item.type === "number") fields[item.name] = 0;
        else if (item.inputType === "checkbox") fields[item.name] = false;
        else if (item.inputType === "select") fields[item.name] = item.selectList[0];
        else fields[item.name] = "";
      } else {
        fields[item.name] = item.defaultValue;
      }
    }
  }
  return fields;
}

/**
 * 
 * @info_params_for_Input_element
 * @param {*} param0 
 * @returns 
 */
export function createInputProps({
  item, index, fields, setFields
}) {
  // console.log('item in createInputProps', item);
  return {
    key: index,
    size: "sm",
    name: item.name,
    label: item.displayName || item.name,
    type: item.type || "text",

    /**
     * @WARNING_controlled_uncontrolled_경고가_발생하므로_defaultValue는_사용하지_말것
     * @info_즉_state_값으로만_value에_할당해야_한다는_의미임
     * 참조 https://reactjs.org/docs/forms.html#controlled-components
     * defaultValue는 createFields에서 이미 적용되었음
     */
    value: fields[item.name],
    onChange: setFields,
    placeholder: item.placeholder || item.comment,
    validated: validateInputValue(item, fields[item.name]),
    readOnly: item.readOnly || false,
    comment: item.comment,
    inputType: item.inputType || "text",
    selectList: item.selectList || [],
    required: item.required,
    checked: item.inputType === "checkbox" && fields[item.name]
  }
}

/**
 *
 * @param {*} item inputFields item
 *            check item.required & item.validate
 * @param {*} value state item.value
 * @returns
 */
export const validateInputValue = (item, value) => {

  //
  // required=false 항목은 무조건 valdate 필요없음
  //
  if (item.required === false) {
    // if (debug) console.log('item.name', item.name, true)
    return true;
  }

  //
  // validate 지정이 된 것은 type 에 따라 validate
  // validate 지정이 안되이 있으면 required 에 따른 공란만 체크함.
  //
  if (item.validate) {
    const type = item.validate.type;
    if (type === "email") return validateEmail(value);
    if (type === "username") return value.length > 4;
    if (type === "password") return validatePassword(value);
    if (type === "phone_number") return validatePhoneNo(value);
    if (type === "licenseNo") return value.length === 10;
  } else {
    // if (debug) console.log('item.name', item.name, true)
    if (item.inputType==="checkbox") return true;
    if (value) return true;
  }

  // if (debug) console.log('item.name', item.name, true)
  return false;
};

export function validatePhoneNo(phoneNo) {
  const regexp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
  if (regexp.test(phoneNo)) return true;
  return false;
}

export function validatePassword(password) {
  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/
  if (passwordRegex.test(password)) return true;
  return false;
}

export function validateEmail(email) {
  const emailRegex =
    /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
  if (!emailRegex.test(email))
    return { isEmail: false, message: '올바른 이메일 형식이 아닙니다.' }
  else
    return { isEmail: true, message: '올바른 이메일 형식 입니다.' }
}
