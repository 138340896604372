import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "./Buttons";
import Postcode from "@actbase/react-daum-postcode";
import { ReactComponent as Close } from "../assets/icons/close.svg";
import { gStyle, colors, useStyles } from "./ui";

/**
 *
 * @param {B} props.showModal visible flag, show로 변경
 * @param {F} props.unsetShowPostCode
 * @param {S} props.title
 * @param {F} props.onRead
 * @returns
 */
export const PostCodeView = (props) => {
  const { showModal, unsetShowPostCode, title, onRead } = props;
  return (
    <>
      <Modal
        show={showModal}
        onHide={unsetShowPostCode}
        style={{ width: "100%" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title || "주소 찾기"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Postcode
            width="300"
            height="300"
            jsOptions={{ animation: true, hideMapBtn: true }}
            onSelected={onRead}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export function CustomModal({
  visible,
  title,
  onClickOk,
  onClickCancel,
  // marginTop,
  children,
}) {

  const styles = {
    modalBody: { padding: "24px 20px" },
    closeBtnWrap: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    text: { ...gStyle.text, ...gStyle.bold, marginBottom: "15px", textAlign: "center" },
  };

  const udpateChildrenWithProps = React.Children.map(
    children,
    (child, i) => {
      return React.cloneElement(child, {
        style: styles.text,
        index: i,
      })
    }
  )

  return (
    <Modal
      // style={{ marginTop: marginTop || "10%" }}
      show={visible}
      onHide={onClickCancel}
      centered
    >
      <Modal.Body style={styles.modalBody}>

        {
          onClickCancel &&
          <div style={styles.closeBtnWrap}>
            <Close width={15} onClick={onClickCancel} style={{ cursor: "pointer" }} />
          </div>
        }
        <p style={styles.text}>{title}</p>

        {/* {children} */}
        {udpateChildrenWithProps}

        {onClickOk &&
          <Button variant="primary" width="100%" title="확인" onClick={onClickOk} />
        }

      </Modal.Body>
    </Modal>
  );
}

export const Alert = ({ title, visible, onClickCancel, onClickOk, children }) => {
  return (
    <CustomModal
      visible={visible}
      title={title}
      onClickCancel={onClickCancel}
      onClickOk={onClickOk}
      children={children}
    />
  );
};

export function ModalView({
  visible,
  setIsVisible,
  children
}) {
  const styles = {
    modalBody: { padding: "24px 20px" },
  };

  return (
    <Modal
      // style={{ marginTop: '5%' }}
      show={visible}
      onHide={() => setIsVisible(false)}
      centered
    >
      <Modal.Body style={styles.modalBody}>
        {children}
      </Modal.Body>
    </Modal>
  )
}

export function ModalForm({
  visible, setIsVisible,
  title, children, 
  onSubmit, btnText,
  isLoading,
  size, backdrop
}) {

  const { gStyle } = useStyles()

  const styles = {
    modalBody: { padding: "24px 20px" },
    titleWrap: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: "10px",
      marginBottom: "15px"
    },
    title: { ...gStyle.subtitle, ...gStyle.bold },
  };

  const modalProps = {
    show: visible,
    onHide: () => setIsVisible(false),
    centered: true
  }

  if (size) modalProps.size = size
  if (backdrop) modalProps.backdrop = backdrop

  return (
    <Modal {...modalProps} >
      <Modal.Body style={styles.modalBody}>
        <div style={styles.titleWrap}>
          <div style={{ width: "20px" }} />
          <h3 style={styles.title}>{title}</h3>
          <Close style={{ cursor: 'pointer' }} width={20} onClick={() => setIsVisible(false)} />
        </div>
        <hr />

        {/* {children} */}
        <div style={{ maxHeight: "80vh", overflow: "auto" }}>
          {children}
        </div>

        {
          onSubmit &&
          <Button
            variant="primary"
            size="sm"
            width="100%"
            title={btnText || '확인'}
            onClick={onSubmit}
            isLoading={isLoading}
          />
        }
      </Modal.Body>
    </Modal>
  )
}

export function ModalWithButton({
  visible, setIsVisible,
  onSubmit, btnText, isLoading,
  title, children, size, backdrop
}) {

  const { gStyle } = useStyles()

  const styles = {
    modalBody: { padding: "24px 20px" },
    titleWrap: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: "10px",
      marginBottom: "15px"
    },
    title: { ...gStyle.subtitle, ...gStyle.bold },
  };

  const modalProps = {
    show: visible,
    onHide: () => setIsVisible(false),
    centered: true
  }

  if (size) modalProps.size = size
  if (backdrop) modalProps.backdrop = backdrop

  return (
    <Modal {...modalProps} >
      <Modal.Body style={styles.modalBody}>
        <div style={styles.titleWrap}>
          <div style={{ width: "20px" }} />
          <h3 style={styles.title}>{title}</h3>
          <Close style={{ cursor: 'pointer' }} width={20} onClick={() => setIsVisible(false)} />
        </div>
        <hr />

        {children}

        {
          onSubmit &&
          <Button
            variant="primary"
            size="sm"
            width="100%"
            title={btnText || '확인'}
            onClick={onSubmit}
            isLoading={isLoading}
          />
        }
      </Modal.Body>
    </Modal>
  )
}