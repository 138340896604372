import {
  shopItemOptionGet, 
  okposItemOptionGet,
} from "../lib/ec2-api-lib";
import { posList } from "./posList";

export function getItemOption({ shopInfo }) {
  if (!posList.includes(shopInfo.posName)) return { err: { message: "INVALID POSNAME" } };
  const params = {
    shopId: shopInfo.shopId,
    optionName: shopInfo.posName==="UNIONPOS" ? "unionpos" : shopInfo.posName
  }
  if (shopInfo.posName === "OKPOS") return okposItemOptionGet(params)
  else return shopItemOptionGet(params)
}