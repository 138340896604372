import {
  shopItemOptionGet, shopItemList,
  okposItemOptionGet, okposItemList
} from "../lib/ec2-api-lib";
import { parseItemOptions } from "./parseItemOptions";

const pos = {
  "UNIONPOS": {
    getOnlMenu: getOnlMenuUnionpos,
  },
  "OKPOS": {
    getOnlMenu: getOnlMenuOkpos
  },
}

const posList = Object.keys(pos);

export function getOnlMenu({ shopInfo }) {
  if (!posList.includes(shopInfo.posName)) return { err: { message: "INVALID POSNAME" } };
  return pos[shopInfo.posName].getOnlMenu({
    shopInfo,
    optionName: pos[shopInfo.posName].optionName
  });
}

async function getOnlMenuUnionpos({ shopInfo }) {
  try {
    const result = {}
    let res = {}
    res = await shopItemOptionGet({
      shopId: shopInfo.shopId,
      optionName: "unionpos"
    })
    if (res.err) throw Error(res.err.message)
    result.itemOptions = parseItemOptions({ shopInfo, itemOptions: res.result.Item })

    res = await shopItemList({ shopId: shopInfo.shopId })
    if (res.err) throw Error(res.err.message)
    result.itemList = res.result.Items

    return { result }
  } catch (e) {
    return { err: { message: e.message } }
  }
}

async function getOnlMenuOkpos({ shopInfo }) {
  try {
    const result = {}
    let res = await okposItemOptionGet({ shopId: shopInfo.shopId, optionName: shopInfo.posName })
    if (res.err) throw Error(res.err.message)
    result.itemOptions = parseItemOptions({ shopInfo, itemOptions: res.result.Item })

    res = await okposItemList({ shopId: shopInfo.shopId })
    if (res.err) throw Error(res.err.message)
    result.itemList = res.result.Items

    return { result }
  } catch (e) {
    return { err: { message: e.message } }
  }
}