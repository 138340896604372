import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
// import ReactDOM from "react-dom";
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";

import { store } from "./redux/store";
import { Provider } from "react-redux";

import Amplify from 'aws-amplify'
import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";
import { conf } from './settings';

import App from "./App";
import * as serviceWorker from "./serviceWorker";

//
// Global Style: index.html에서는 모두 제외하고 여기에서 할 것.....
//
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
//
// fontAwesome css only
//
import "@fortawesome/fontawesome-free/css/all.min.css";

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: conf.cognito.REGION,
    userPoolId: conf.cognito.USER_POOL_ID,
    identityPoolId: conf.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: conf.cognito.APP_CLIENT_ID
  },
  Storage: {
    region: conf.s3.REGION,
    bucket: conf.s3.BUCKET,
    identityPoolId: conf.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: conf.apiGateway.USER.API_NAME,
        endpoint: conf.apiGateway.USER.URL,
        region: conf.apiGateway.REGION
      },
      {
        name: conf.apiGateway.SHOP.API_NAME,
        endpoint: conf.apiGateway.SHOP.URL,
        region: conf.apiGateway.REGION
      },
      {
        name: conf.apiGateway.ORDER.API_NAME,
        endpoint: conf.apiGateway.ORDER.URL,
        region: conf.apiGateway.REGION
      },
      {
        name: conf.apiGateway.TAG.API_NAME,
        endpoint: conf.apiGateway.TAG.URL,
        region: conf.apiGateway.REGION
      },
      {
        name: conf.apiGateway.SMS.API_NAME,
        endpoint: conf.apiGateway.SMS.URL,
        region: conf.apiGateway.REGION
      },
      {
        name: conf.apiGateway.POS.API_NAME,
        endpoint: conf.apiGateway.POS.URL,
        region: conf.apiGateway.REGION
      },
    ]
  }
})

Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: conf.iot.REGION,
    aws_pubsub_endpoint: conf.iot.ENDPOINT,
  })
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
);

// ReactDOM.render(
//   <Provider store={store}>
//     <Router>
//       <App />
//     </Router>
//   </Provider>,
//   document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
