import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/user";
import { userGet } from "../lib/apiLib";
import { Auth } from "aws-amplify";
import { Page, Section, LoaderButton } from "../components";
import { FormInput, Button, CardContainer } from "../components2"
import { queryString2Obj } from "../lib/utils";
import { useInputFields } from "../lib/hooksLib";
import { debug } from "../settings";

const loginInfo = {
  username: debug ? "admin-4673" : "",
  password: debug ? "Psdl12@$" : "",
  // username: debug ? "01053174673" : "", // zucin@naver.com
  // password: debug ? "psdl12!!" : "", // 123456789aA!
};

export default function Login(props) {
  return (
    <Page title="로그인" stretch>

      <LoginForm {...props} />

    </Page>
  );
}

function LoginForm(props) {
  const search = props.location.search;

  // console.log('search', search);
  // const query
  // if(search)

  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useInputFields({
    username: loginInfo.username,
    password: loginInfo.password,
  });

  const dispatch = useDispatch();

  const validate = () => {
    return fields.username.length > 7;
  };

  const onsubmit = async (e) => {
    try {
      setIsLoading(true);
      let cognitoUser = await Auth.signIn(fields.username, fields.password);
      if (debug) console.log("cognito user: ", cognitoUser);

      const res = await userGet({ username: cognitoUser.username });
      if (debug) console.log("User:", res);

      if (!res.status) throw new Error("유저 정보를 가져오는데 실패했습니다.");

      const user = res.result?.Item;
      if (debug) console.log("UserType:", user.userType);
      if (user.userType !== "admin") throw new Error("접근 권한이 없습니다.");

      dispatch(setUser({ user: user }));
      setIsLoading(false);
      // 
      // AuthenticatedRoute check
      // 
      props.history.push(search ? queryString2Obj(search).redirect : "/");

    } catch (e) {
      await Auth.signOut();
      setIsLoading(false);
      alert('Login Error:\n' + e.message);
    }
  };

  return (
    <CardContainer variant="sm">
      <div style={{ padding: 20 }}>
        <FormInput
          name="username"
          label="사용자명(Username)"
          validated={validate()}
          value={fields.username}
          onChange={handleFieldChange}
          colLeft="4"
          type="text"
        />

        <FormInput
          name="password"
          label="비밀 번호"
          validated={fields.password.length > 7}
          value={fields.password}
          onChange={handleFieldChange}
          colLeft="4"
          type="password"
          inputType="password"
        />
        <hr />
        <Button
          size="sm"
          variant="primary"
          width="100%"
          isLoading={isLoading}
          onClick={onsubmit}
          disabled={!validate()}
        >
          Login
        </Button>

        <Button
          size="sm"
          variant="outline-secondary"
          style={{ marginTop: "10px" }}
          onClick={() => props.history.push("/signup")}
          isLoading={isLoading}
        >
          회원 등록
        </Button>
      </div>
    </CardContainer>
  );
}
