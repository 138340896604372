import axios from "axios";
import { conf } from "../settings";

const axiosCall = async ({ method, path, body }) => {
  try {
    const baseUrl = conf.ec2Api.url

    let res = await axios({
      method,
      url: baseUrl + path,
      data: body,
      headers: { 'x-taptag-key': conf.apiKey }
    });
    // if (debug) console.log('res - api ', res);
    if (res.status !== 200) throw new Error("Response Error");
    return res.data;
  } catch (e) {
    return { err: { message: e.message } };
  }
}

/** @Posts */
export const createPost = ({ categoryId, postId, body }) => {
  const path = `/cms/post2?categoryId=${categoryId}&postId=${postId}`;
  return axiosCall({ method: "POST", path, body });
};
export const updatePost = ({ categoryId, postId, body }) => {
  const path = `/cms/post2?categoryId=${categoryId}&postId=${postId}`;
  return axiosCall({ method: "PUT", path, body });
};
export const getPost = ({ categoryId, postId }) => {
  const path = `/cms/post2?categoryId=${categoryId}&postId=${postId}`;
  return axiosCall({ method: "GET", path });
};
export const deletePost = ({ categoryId, postId }) => {
  const path = `/cms/post2?categoryId=${categoryId}&postId=${postId}`;
  return axiosCall({ method: "DELETE", path });
};
export const listPost = ({ categoryId, start_time }) => {
  let path = `/cms/post2/list?categoryId=${categoryId}`;
  if(start_time) path += `&start_time=${start_time}`
  return axiosCall({ method: "GET", path });
};

/**
 * @CMS_Category
 * 
 * @param {String} group: 카테고리 그룹 예) onl-shop, 주로 application에 해당할 듯
 * @param {String} category: 카테고리  예) docs
 * 
 * @deprecated
 */
export const categoryCreate = async ({ group, category, body }) => {
  let path = `/cms/category?group=${group}&category=${category}`;
  // body: { description, subCategory }
  return await axiosCall({ method: "POST", path, body });
};
export const categoryGet = async ({ group, category }) => {
  let path = `/cms/category?group=${group}&category=${category}`;
  return await axiosCall({ method: "GET", path });
};
export const categoryUpdate = async ({ group, category, body }) => {
  let path = `/cms/category?group=${group}&category=${category}`;
  // body: { description, subCategory }
  return await axiosCall({ method: "PUT", path, body });
};
export const categoryList = async ({ group }) => {
  let path = `/cms/category/list?group=${group}`;
  return await axiosCall({ method: "GET", path });
};
export const categoryDelete = async ({ group, category }) => {
  let path = `/cms/category?group=${group}&category=${category}`;
  return await axiosCall({ method: "DELETE", path });
};

/**
 * @CMS_Post
 * @param {String} category: 카테고리  예) docs
 * 
 * @deprecated
 */
export const postCreate = async ({ category, id, body }) => {
  let path = `/cms/post?category=${category}`;
  if (id) path += `&id=${id}`;
  return await axiosCall({ method: "POST", path, body });
};
export const postGet = async ({ category, id }) => {
  let path = `/cms/post?category=${category}&id=${id}`;
  return await axiosCall({ method: "GET", path });
};
export const postUpdate = async ({ category, id, body }) => {
  let path = `/cms/post?category=${category}&id=${id}`;
  return await axiosCall({ method: "PUT", path, body });
};
export const postList = async ({ category }) => {
  let path = `/cms/post/list?category=${category}`;
  return await axiosCall({ method: "GET", path });
};
export const postDelete = async ({ category, id }) => {
  let path = `/cms/post?category=${category}&id=${id}`;
  return await axiosCall({ method: "DELETE", path });
};
