import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { useStyles } from "../../../components2";
import { Button } from "../../../components2";


export function SectionTextImage(props) {
  const { data } = props
  const { gStyle, colors } = useStyles()
  const { isMobilePhone } = useSelector(state => state.platform)
  // const navigate = useNavigate()

  // console.log(data)

  const [textWrapWidth, setTextWrapWidth] = useState("50%")

  useEffect(() => {
    // console.log('data', data)
    if(data.layout==="column") {
      setTextWrapWidth("100%")
    }
  }, [data])

  const onClickLink = (url) => {
    if (url.includes("http")) window.location.replace(url)
    // else navigate(url)
    else props.history.push(url)
  }

  const _getFlexDir = () => {
    if (data.layout === "column") return "column-reverse"
    if (data.layout === "row-text-left") return "row-reverse"
    if (data.layout === "row-text-right") return "row"
    return "row"
  }

  const styles = {
    wrap: {
      width: "100%",
      padding: "30px 15px",
    },
    title: {
      ...gStyle.title,
      fontSize: isMobilePhone ? "26px" : "40px",
      color: colors.dark,
      margin: "15px 30% 30px 15px",
    },
    contentWrap: {
      padding: "15px 10px",
      display: "flex",
      flexDirection: _getFlexDir(),
      flexWrap: isMobilePhone ? "wrap" : "no-wrap",
      width: "100%"
    },
    textWrap: {
      padding: "0 15px",
      display: "flex", flexDirection: "column",
      justifyContent: "center",
      width: textWrapWidth
    },
    text: { ...gStyle.subtitle, color: colors.dark, lineHeight: "2.0rem" },
    imgWrap: {
      padding: 15,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
      // width: imgObj.w || "50%"
    },
    btnWrap: {
      marginTop: 30,
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap"
    },
  }

  /** 
   * @Cases
   * 1. row single Image
   * 2. column multi images
   * 
   */
  return (
    <Container>
      <SectionAnimate bgColor="#fff">
        <div style={styles.wrap}>
          <h2 style={styles.title}>{data.title}</h2>

          <div style={styles.contentWrap}>

            {data.images?.length > 0 &&
              data.images.map((imgObj, i) => {

                const imgStyle = { width: imgObj.w || "50%" }
                

                if (isMobilePhone) {
                  imgStyle.width = imgObj.mw || "100%"
                  imgStyle.marginBottom = 10
                }

                // console.log( textWrapWidth, imgStyle.width)

                return (
                  <div
                    key={"section-img" + i}
                    style={{
                      ...imgStyle,
                      display: "flex", justifyContent: "center", alignItems: "center",
                      // backgroundColor: "yellow",
                      padding: 15
                    }}
                  >
                    <img
                      // style={imgStyle}
                      style={{ width: "100%" }}
                      src={imgObj.url}
                      alt={"section pic " + i}
                    />
                  </div>
                )
              })
            }

            <div style={styles.textWrap}>
              {data.texts &&
                data.texts.map((t, i) => {
                  return <p key={"text" + i} style={styles.text}>{t.value}</p>
                })
              }

              {data.links?.length > 0 &&
                <div style={styles.btnWrap}>
                  {
                    data.links.map((link, i) => {
                      return (
                        <Button
                          key={"link" + i}
                          size="sm"
                          variant={link.variant}
                          title={link.text}
                          onClick={() => onClickLink(link.linkUrl)}
                        />
                      )
                    })
                  }
                </div>
              }

            </div>
          </div>
        </div>
      </SectionAnimate>
    </Container>
  )
}

// export function SectionShopItems(props) {
//   const { data } = props
//   const { gStyle, colors } = useStyles()
//   const { isMobilePhone } = useSelector(state => state.platform)

//   // const onClickLink = (item) => {
//   //   // console.log(item)
//   //   // navigate(`${routePaths.shop.item}?sid=${item.sid}&itemCode=${item.itemCode}`)
//   // }

//   const styles = {
//     wrap: {
//       width: "100%",
//       padding: "30px 0",
//     },
//     title: {
//       ...gStyle.title,
//       fontSize: isMobilePhone ? "26px" : "40px",
//       color: colors.dark,
//       margin: "15px 30% 30px 15px",
//     },
//     contentWrap: {
//       padding: "15px 10px",
//       display: "flex",
//       justifyContent: "space-around",
//       flexWrap: "wrap"
//     },
//     // textWrap: {
//     //   padding: "0 15px",
//     //   display: "flex", flexDirection: "column",
//     //   justifyContent: "center",
//     // },
//     // text: { ...gStyle.subtitle, color: colors.dark, lineHeight: "2.0rem" },
//     // imgWrap: {
//     //   padding: 15,
//     //   display: "flex",
//     //   justifyContent: "space-between",
//     //   alignItems: "center",
//     //   flexWrap: "wrap"
//     // },
//     // btnWrap: {
//     //   marginTop: 30,
//     //   display: "flex",
//     //   justifyContent: "flex-start",
//     //   flexWrap: "wrap"
//     // },
//   }

//   return (
//     <SectionAnimate bgColor="#efefef">
//       <div style={styles.wrap}>
//         {data.title && <h2 style={styles.title}>{data.title}</h2>}

//         <div style={styles.contentWrap}>
//           {data.items.length > 0 &&
//             data.items.map((item, i) => {
//               // return <ItemCard key={"item" + i} idx={i} item={item} onClick={()=>onClickLink(item)} />
//             })
//           }

//           {/* {data.images?.length > 0 &&
//             <div style={styles.imgWrap}>
//               {
//                 data.images.map((imgObj, i) => {
//                   const imgStyle = { width: imgObj.size?.w }

//                   if (isMobilePhone) {
//                     imgStyle.width = imgObj.size?.mw ||  "100%"
//                     imgStyle.marginBottom = 10
//                   }

//                   return (
//                     <div
//                       key={"section-img" + i}
//                       style={{
//                         width: isMobilePhone ? "100%" : null,
//                         display: "flex", justifyContent: "center"
//                       }}
//                     >
//                       <img
//                         // key={"section-img" + i}
//                         style={imgStyle}
//                         src={imgObj.url}
//                         alt={"section pic " + i}
//                       />
//                     </div>
//                   )
//                 })
//               }
//             </div>
//           } */}

//           {/* {
//             data.texts?.length > 0 &&
//             <div style={styles.textWrap}>
//               {
//                 data.texts.map((t, i) => {
//                   if (Array.isArray(t)) {
//                     return (
//                       <p key={"text" + i} style={styles.text}>
//                         {
//                           t.map((tObj, i) => {
//                             return <span style={{ color: colors[tObj.variant] }} >{tObj.value}&nbsp;</span>
//                           })
//                         }
//                       </p>
//                     )
//                   }
//                   return <p key={"text" + i} style={styles.text}>{t}</p>
//                 })
//               }

//               <div style={styles.btnWrap}>
//                 {
//                   data.links.map((link, i) => {
//                     return (
//                       <Button
//                         key={"link" + i}
//                         variant={link.variant}
//                         title={link.displayName}
//                         onClick={() => onClickLink(link.linkUrl)}
//                       />
//                     )
//                   })
//                 }
//               </div>
//             </div>
//           } */}



//         </div>
//       </div>
//     </SectionAnimate>
//   )
// }

export function SectionVideo(props) {
  const { data } = props
  const { gStyle, colors } = useStyles()
  const { isMobilePhone } = useSelector(state => state.platform)

  const styles = {
    wrap: {
      width: "100%",
      padding: "30px 0",
    },
    title: {
      ...gStyle.title,
      fontSize: isMobilePhone ? "26px" : "40px",
      color: colors.dark,
      textAlign: "center"
    },
  }

  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
    // event.target.playVideo();
  }

  const opts2 = {
    width: isMobilePhone ? 'auto' : '640',
    height: isMobilePhone ? 'auto' : '390',
    // width: "auto", height: "auto",
    // playerVars: { autoplay: 1, },
  }

  return (
    <Container>
      <SectionAnimate bgColor="#fff">
        <div style={styles.wrap}>
          <h2 style={styles.title}>{data.title}</h2>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* <YouTube videoId={data.youtubeId} opts={opts2} onReady={_onReady} /> */}
          </div>
        </div>
      </SectionAnimate>
    </Container>
  )
}

export function SectionAnimate({
  style,
  padding,
  bgColor,
  children,
}) {
  const [animate, setAnimate] = useState(false);
  const { gStyle, colors } = useStyles()
  const myRef = useRef();

  useEffect(() => {
    onScroll();
    window.addEventListener("scroll", onScroll);

    return function () {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  function onScroll(e) {
    let h = (window.innerHeight * 6) / 10;
    const rect = myRef.current.getBoundingClientRect();
    if (rect.top < h) setAnimate(true);
  }

  const myStyle = {
    wrap: style || {
      margin: "25px 0 0",
      // padding: padding || "40px 0",
      // width: "100%",
      backgroundColor: bgColor || "azure",
    },
  };

  return (
    // <Container ref={myRef} style={myStyle.wrap}>
    <div ref={myRef} style={myStyle.wrap}>
      <div className={animate ? "slide" : "slideanim"}>
        {children}
      </div>
    </div>
    // </Container>
  );
}