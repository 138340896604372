import React, { useState } from "react";
import { gStyle, colors } from "./ui";
import {
  Pencil as PencilIcon, PencilSquare,
  ArrowLeftShort as ArrowLeft, ArrowRightShort as ArrowRight, ArrowDownShort as ArrowDown, ArrowUpShort as ArrowUp,
  CaretUp, CaretDown, CaretLeft, CaretRight,
  ChevronLeft, ChevronRight, ChevronUp, ChevronDown,
  ThreeDots, ThreeDotsVertical,
  XLg,
  Save as BsSave, BoxArrowLeft, BoxArrowRight,
  Gear,
  Collection,
  Trash as BsTrash, Recycle as BsRecycle,
  EyeFill as Eye, EyeSlashFill as EyeSlash,
  PersonCircle, PersonFillAdd, PersonFillGear,
  HouseDoor,
  Clock as BsClock,
  PlusSquare, DashSquare,
  CheckCircle, Circle,
  FileEarmarkArrowUp,
  BatteryHalf,
  SortAlphaDown,
  FileEarmarkTextFill,
  CardList,
  CircleFill,
  List as BarIcon,
  CreditCard,
  QrCode as QrCode1
} from "react-bootstrap-icons";

// bootstrap Icon main: https://icons.getbootstrap.com/#usage

function MyIcon({ style, children, onClick }) {
  const [mouseOver, setMouseOver] = useState(false);
  const opacity = "80";
  const myStyle = {
    wrap: {
      display: "inline",
      padding: "5px",
    },
    icon: {
      cursor: "pointer",
      color: mouseOver ? colors.dark + opacity : colors.dark
    }
  }

  if (style) {
    myStyle.icon = { ...myStyle.icon, ...style };
    if (style.color) {
      myStyle.icon.color = mouseOver
        ? style.color + opacity
        : style.color;
    }
  }

  const udpateChildrenWithProps = React.Children.map(
    children,
    (child, i) => {
      return React.cloneElement(child, {
        style: myStyle.icon,
        index: i,
        onMouseEnter: () => setMouseOver(true),
        onMouseLeave: () => setMouseOver(false),
        onClick: onClick
      })
    }
  )
  return <>{udpateChildrenWithProps}</>
}

export function Pencil(props) { return <MyIcon {...props} ><PencilIcon /></MyIcon> }

export function PencilBox(props) { return <MyIcon {...props} ><PencilSquare /></MyIcon> }

export function ArrowL(props) { return <MyIcon style={gStyle.title} {...props} ><ArrowLeft /></MyIcon> }

export function ArrowR(props) { return <MyIcon style={gStyle.title} {...props} ><ArrowRight /></MyIcon> }

export function ArrowU(props) { return <MyIcon style={gStyle.title} {...props} ><ArrowUp /></MyIcon> }

export function ArrowD(props) { return <MyIcon style={gStyle.title} {...props}><ArrowDown /></MyIcon> }

export function TriAngleL(props) { return <MyIcon {...props} ><CaretLeft /></MyIcon> }

export function TriAngleR(props) { return <MyIcon {...props} ><CaretRight /></MyIcon> }

export function TriAngleU(props) { return <MyIcon {...props} ><CaretUp /></MyIcon> }

export function TriAngleD(props) { return <MyIcon {...props}><CaretDown /></MyIcon> }

export function ChevronL(props) { return <MyIcon {...props} ><ChevronLeft /></MyIcon> }

export function ChevronR(props) { return <MyIcon {...props} ><ChevronRight /></MyIcon> }

export function ChevronU(props) { return <MyIcon {...props} ><ChevronUp /></MyIcon> }

export function ChevronD(props) { return <MyIcon {...props} ><ChevronDown /></MyIcon> }

export function ThreeDotsH(props) { return <MyIcon {...props} ><ThreeDots /></MyIcon> }

export function ThreeDotsV(props) { return <MyIcon {...props} ><ThreeDotsVertical /></MyIcon> }

export function ThreeBar(props) { return <MyIcon {...props} ><BarIcon /></MyIcon> }

export function Close(props) { return <MyIcon {...props} ><XLg /></MyIcon> }

export function Save(props) { return <MyIcon {...props} ><BsSave /></MyIcon> }

export function Setting(props) { return <MyIcon {...props} ><Gear /></MyIcon> }

export function Grouping(props) { return <MyIcon {...props} ><Collection /></MyIcon> }

export function Trash(props) { return <MyIcon {...props} ><BsTrash /></MyIcon> }

export function Recycle(props) { return <MyIcon {...props} ><BsRecycle /></MyIcon> }

export function EyeOpen(props) { return <MyIcon {...props} ><Eye /></MyIcon> }

export function EyeClose(props) { return <MyIcon {...props} ><EyeSlash /></MyIcon> }

export function UserCircle(props) { return <MyIcon {...props} ><PersonCircle /></MyIcon> }

export function UserAdd(props) { return <MyIcon {...props} ><PersonFillAdd /></MyIcon> }

export function UserSetting(props) { return <MyIcon {...props} ><PersonFillGear /></MyIcon> }

export function Upload(props) { return <MyIcon {...props} ><FileEarmarkArrowUp /></MyIcon> }

export function House(props) { return <MyIcon {...props} ><HouseDoor /></MyIcon> }

export function Signin(props) { return <MyIcon {...props} ><BoxArrowLeft /></MyIcon> }

export function Signout(props) { return <MyIcon {...props} ><BoxArrowRight /></MyIcon> }

export function Clock(props) { return <MyIcon {...props} ><BsClock /></MyIcon> }

export function Plus(props) { return <MyIcon {...props} ><PlusSquare /></MyIcon> }
export function Minus(props) { return <MyIcon {...props} ><DashSquare /></MyIcon> }

export function CheckedCircle(props) { return <MyIcon {...props} ><CheckCircle /></MyIcon> }
export function UnCheckedCircle(props) { return <MyIcon {...props} ><Circle /></MyIcon> }

export function Battery(props) { return <MyIcon {...props} ><BatteryHalf /></MyIcon> }

export function Sorting(props) { return <MyIcon {...props} ><SortAlphaDown /></MyIcon> }

export function TextFile(props) { return <MyIcon {...props} ><FileEarmarkTextFill /></MyIcon> }

export function ListTable(props) { return <MyIcon {...props} ><CardList /></MyIcon> }

export function CircleFilled(props) { return <MyIcon {...props} ><CircleFill /></MyIcon> }

export function PayCard(props) { return <MyIcon {...props} ><CreditCard /></MyIcon> }
export function QrCode(props) { return <MyIcon {...props} ><QrCode1 /></MyIcon> }