import React, { useState } from "react";
import { Section, Table, LoaderFullPage, Page } from "../../components";
import { nfcTagList } from "../../lib/apiLib";
import { getOrderRecords } from "../../lib/ec2-api-lib";
import { shopGet } from "../../lib/ec2-api-lib";
import { getItemOption, parseItemOptions } from "../../lib-pos";
import { getTimestamp, sortObjArray } from "../../lib/utils";
import { useLocation } from "react-router";
import { debug } from "../../settings";
import { useEffect } from "react";
import SearchStoreWithCalendar from "./components/SearchStoreWithCalendar";

const tableHeader = [
  { name: "ver", displayName: "Version" },
  { name: "uid", displayName: "UID" },
  { name: "active", displayName: "사용 여부" },
  { name: "tableNo", displayName: "테이블 번호" },
  { name: "tableName", displayName: "테이블 명" },
  { name: "readCtr", displayName: "Count" },
];

export default function NfcTagsByShop(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [shopName, setShopName] = useState("");
  const [tags, setTags] = useState([]);  

  const onSelectShop = async ({ item, startDate, endDate }) => {
    try {
      const shopInfo = item
      setIsLoading(true)
      if (debug) console.log('shop', item)
      setShopName(item.shopName + ` (${item.posName})`);

      let res = await nfcTagList({ ver: "v1", shopId: item.shopId });
      if (res.err) throw new Error(res.err.message);
      let _tags = res.result.Items;
      const tags2 = sortObjArray({arr: _tags, key: "tableNo"})
      
      const res2 = await getItemOption({ shopInfo })
      if (res2.err) throw new Error(res2.err.message);
      const itemOptions2 = parseItemOptions({ shopInfo, itemOptions: res2.result.Item });
      const tableList = itemOptions2.TableList

      const newTags = tags2.map(tag=>{
        const tables = tableList.filter(table=>table.TableNo===parseInt(tag.tableNo))
        if(tables.length===1) {
          return {
            ...tag,
            tableName: tables[0].TableName
          }
        } else {
          return tag
        }
      })
      
      setTags(newTags)
    } catch (e) {
      alert(e.message)
      if (debug) console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Page stretch title="가맹점 NFC Tags 정보">

      <SearchStoreWithCalendar
        onSelectShop={onSelectShop}
      />

      {
        tags.length > 0 &&
        <Section bgColor="#fff">
          <h3>{shopName}</h3>
          <Table
            tableHeader={tableHeader}
            rowItems={tags}
          />
        </Section>
      }

      {isLoading && <LoaderFullPage />}
    </Page>
  );
}

function ordersByDate(orderList) {
  const orders = orderList || [
    {
      "TableName": "14",
      "time": 1697612958623,
      "OrderData": "아이템 수: 1"
    },
    {
      "TableName": "14",
      "time": 1697612975477,
      "OrderData": "아이템 수: 2"
    },
    {
      "TableName": "14",
      "time": 1697612989685,
      "OrderData": "아이템 수: 1"
    },
    {
      "TableName": "14",
      "time": 1697613197009,
      "OrderData": "아이템 수: 2"
    },
    {
      "TableName": "14",
      "time": 1697613219365,
      "OrderData": "아이템 수: 3"
    },
    {
      "TableName": "14",
      "time": 1697613275986,
      "OrderData": "아이템 수: 3"
    },
    {
      "TableName": "14",
      "time": 1697613301320,
      "OrderData": "아이템 수: 1"
    },
    {
      "TableName": "4",
      "time": 1697696775950,
      "OrderData": "아이템 수: 1"
    },
    {
      "TableName": "8",
      "time": 1697696796212,
      "OrderData": "아이템 수: 1"
    },
    {
      "TableName": "11",
      "time": 1697696853916,
      "OrderData": "아이템 수: 1"
    },
    {
      "TableName": "11",
      "time": 1697696863567,
      "OrderData": "아이템 수: 1"
    },
    {
      "TableName": "3",
      "time": 1697697420552,
      "OrderData": "아이템 수: 6"
    },
    {
      "TableName": "3",
      "time": 1697697795175,
      "OrderData": "아이템 수: 2"
    },
    {
      "TableName": "1",
      "time": 1697697820459,
      "OrderData": "아이템 수: 1"
    },
    {
      "TableName": "10",
      "time": 1697698018040,
      "OrderData": "아이템 수: 2"
    }
  ]

  const orders2 = orders.map(o => {
    return {
      orderDate: new Date(o.time).toLocaleDateString(),
      orderCnt: 1
    }
  })

  const orderSum = []
  orders2.map(order => {
    let index = orderSum.findIndex(o => o.orderDate === order.orderDate)
    if (index > -1) {
      orderSum[index].orderCnt += 1
    } else {
      orderSum.push(order)
    }
    return null
  })

  return orderSum
}

function getTimeList(start_time, end_time) {
  const dayMsecs = 1000 * 60 * 60 * 24
  const days = Math.round((end_time - start_time) / dayMsecs, 0)

  const timeList = []
  let preObj = {}
  for (let i = 0; i < days; i++) {
    let timeObj = {}
    if (i === 0) {
      timeObj.start_time = start_time
      timeObj.end_time = start_time + dayMsecs - 1
    } else {
      timeObj.start_time = preObj.start_time + dayMsecs
      timeObj.end_time = preObj.end_time + dayMsecs
    }
    timeList.push(timeObj)
    preObj = { ...timeObj }
  }

  const tList = timeList.map(timeObj => {
    return {
      // ...timeObj,
      start_time: timeObj.start_time,
      end_time: timeObj.end_time,
      start_time2: new Date(timeObj.start_time).toLocaleString(),
      end_time2: new Date(timeObj.end_time).toLocaleString(),
    }
  })
  return tList
}
