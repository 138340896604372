import React, { useState, useEffect } from "react";
import { useStyles, FormInput, ModalForm, Button, UnderlineButton } from "../../components2";
import { tsId } from "../../lib/utils";
import { updatePost } from "../../lib/ec2-api-cms2-lib";
import { categoryTypes, categoryTypes2 } from "../../settings/cms-schema/cms2";

export function CategoryModal(props) {
  const {
    setVisible, visible, onload, category, isEdit, groupList, categoryList,
    setAlertMsg
  } = props;
  const title = isEdit ? category.categoryName + " 편집" : "Post 카테고리 추가";

  const { gStyle } = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const groupNameList = groupList.map(grp => grp.groupName)

  const [groupName, setGroupName] = useState("")
  const [categoryType, setCategoryType] = useState("")
  const [categoryName, setCategoryName] = useState("")

  useEffect(() => {
    // console.log(isEdit, category)

    if (isEdit) {
      setGroupName(category.groupName)
      setCategoryType(category.categoryType)
      setCategoryName(category.categoryName)
    } else {
      if (groupList.length > 0) setGroupName(groupList[0].groupName)
      setCategoryType(categoryTypes2.blog)
      setCategoryName("")
    }
  }, [isEdit, groupList, categoryList, category])

  const onSubmit = async () => {
    try {

      setIsLoading(true);

      let res = {}
      let newCatList = []

      if (!isEdit) {
        newCatList = [...categoryList]
        const groups = groupList.filter(grp => grp.groupName === groupName)
        if (groups.length !== 1) throw Error("Unexpected Error - groupName")

        newCatList.push({
          categoryId: tsId(),
          categoryName,
          categoryType,
          groupId: groups[0].groupId,
          groupName
        })

      } else {
        newCatList = categoryList.map(cat => {
          if (cat.categoryId === category.categoryId) return { ...cat, categoryName, categoryType }
          else return cat
        })
      }

      res = await updatePost({
        categoryId: "common",
        postId: "common",
        body: { categoryList: JSON.stringify(newCatList) }
      })
      if (res.err) throw Error(res.err.message);

      await onload();

    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false)
      setVisible(false);
    }
  }

  const onClickDelete = () => {
    setVisible(false)
    setAlertMsg(`Delete ${category.categoryName} ?`)
  }

  const styles = {
    text: { ...gStyle.text, marginBottom: "3px" },
    formGroup: { margin: 0, padding: 5 }
  };

  return (
    <ModalForm visible={visible} setIsVisible={setVisible} title={title} >
      <div style={styles.formGroup}>
        <FormInput
          label="POST Group"
          name="groupName"
          value={groupName}
          onChange={e => {
            if (!isEdit) setGroupName(e.target.value)
          }}
          inputType="select"
          selectList={groupNameList}
          readOnly={isEdit}
        />

        <FormInput
          label="Category Type"
          name="categoryType"
          value={categoryType}
          onChange={e => {
            if (!isEdit) setCategoryType(e.target.value)
          }}
          inputType="select"
          selectList={categoryTypes}
          readOnly={isEdit}
        />

        <FormInput
          label="Category Name"
          name="categoryName"
          value={categoryName}
          onChange={e => setCategoryName(e.target.value)}
        />
      </div>

      <Button
        variant="primary"
        size="sm"
        width="100%"
        title={isEdit ? "Update" : "Add"}
        onClick={onSubmit}
        isLoading={isLoading}
      />

      {isEdit &&
        <UnderlineButton
          variant="primary"
          size="sm"
          width="100%"
          title="Delete"
          onClick={onClickDelete}
        />}

    </ModalForm>
  )
}
