import { useSelector } from "react-redux";

const _colors = {
  primary: "#05b8a0",
  info: "#4ec6e1",
  secondary: "#6c757d",
  warning: '#ff4400',
  danger: "#c62127",
  light: "#efefef",
  dark: "#2b2b2b",
}


export const colors = {
  ..._colors,
  readOnly: _colors.light
}

const fontFamily = {
  normal: "nanumSquare-light",
  bold: "nanumSquare-extra-bold",
}

const textBasic = {
  lineHeight: "1.44",
  fontWeight: "300",
  fontStretch: "normal",
  letterSpacing: "normal",
  margin: "0px",
  fontFamily: fontFamily.normal,
  color: colors.dark,
}

export const gStyle = {
  title: {    
    // fontSize: "28px",
    fontSize: "2.5rem",
    fontFamily: fontFamily.bold,
  },
  subtitle: {
    ...textBasic,
    // fontSize: "20px",
    fontSize: "1.8rem",
  },
  text: {
    ...textBasic,
    // fontSize: "16px",
    fontSize: "1rem",
  },
  bold: {
    fontFamily: fontFamily.bold,
  },
  highLight: {
    fontFamily: fontFamily.bold,
    color: colors.primary
  }
}

export const gStyleMobile = {
  title: { ...gStyle.title, fontSize: "22px" },
  subtitle: { ...gStyle.subtitle, fontSize: "16px" },
  text: { ...gStyle.text, fontSize: "14px" },
  bold: { ...gStyle.bold },
  highLight: { ...gStyle.highLight }
}

export function useStyles() {
  const { isMobileDevice } = useSelector((state) => state.platform);
  // const [isMobile, setIsMobile] = useState(false);  
  if (isMobileDevice)  return { gStyle: gStyleMobile, colors};
  else return { gStyle, colors};
}