import React from "react";
import { deletePost } from "../../lib/ec2-api-cms2-lib";
import { ModalForm, FormInput, Button } from "../../components2";
import { useInputFields } from "../../lib/hooksLib";

export function PostDeleteConfirmModal(props) {
  const { visible, setIsVisible, post } = props;
  const cText = "삭제";
  const [field, setField] = useInputFields({
    confirmText: ""
  })

  const onSubmit = async () => {
    try {
      if (field.confirmText !== cText) throw Error("확인 메시지가 다릅니다.")
      let res = await deletePost({ categoryId: post.categoryId, postId: post.postId });
      if (res.err) throw Error(res.err.message)
      props.history.goBack()
    } catch (e) {
      alert(e.message)
      setIsVisible(false);
    }
  }

  return (
    <ModalForm visible={visible} setIsVisible={setIsVisible} title="포스트 삭제">
      <p>"삭제"를 입력후 확인을 누르면 삭제가 됩니다.</p>
      <FormInput
        name="confirmText"
        value={field.confirmText}
        onChange={setField}
      />
      <Button
        size="sm"
        variant="primary"
        width="100%"
        title="확인"
        onClick={onSubmit}
      />
    </ModalForm>
  )
}