import conf from "../conf";
const debug = conf.debug;
// const debug = false;

/**
 * @admin_password_필수
 * @별도의_보안_대책_마련_필요
 */
export const version = {
  name: "Tag Document Version DB Table",
  inputFields: [
    {
      name: "docVersion",
      comment: "yyyy-mm-dd",
      // defaultValue: "2022-08-15"
    },
    {
      name: "tagVersion",
      inputType: 'select',
      selectList: ["v1", "v2"],
      comment: "v1: NFC Type 4 - 후결제",
      // defaultValue: debug ? "v1" : ""
    },
    {
      name: "nfcType",
      displayName: "NFC Type",
      // defaultValue: debug ? "NFC Forum Type 4" : ""
    },
    {
      name: "ic",
      displayName: "NFC IC",
      // defaultValue: debug ? "NXP NTAG424 DNA" : ""
    },
    // {
    //   name: "pw",
    //   displayName: "NFC Locking 암호",
    //   comment: "프로비저닝할 때 아스키코드로 변환하여 저장할 것",
    //   defaultValue: debug ? "1027" : "",
    // },
    {
      name: "baseUrl",
      displayName: "NDEF Base Url",
      defaultValue: "https://order.taptag.co.kr/v1"
    },
    {
      name: "ndef",
      displayName: "NDEF URL Example",
      defaultValue: "https://order.taptag.co.kr/v1/{shopId}/{tableNo}?e={piccdata}&m={mac}"
    },
    {
      name: "isActive",
      displayName: "사용여부",
      inputType: "checkbox",
      defaultValue: false
    }
  ],
};

// 
// 테스트 용으로 웺에서는 실제 활용하지 않으며 프로비져닝 앱에세 API로 사욯함.
// 여기서는 참조만 함.
// 
export const tag = {
  name: "Tag DB Table",
  inputFields: [
    {
      name: "uid",
    },
    {
      name: "ndefUrl",
    },
  ],
};