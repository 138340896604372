/**
 * App의 모든 Constant 모음...
 */
import config from "./conf";
export {config as conf}
export const debug = config.debug;
export const authDebug = config.authDebug;

export { default as db } from "./db";
export { default as strings } from "./strings";
export { default as ui } from "./uiConstants";

// import * as globalStyle from "./gStyle";
// export { globalStyle as gStyle }
// export const page = globalStyle.page;
// export const text = globalStyle.text;
