import React, { useState, useRef } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { ReactComponent as Off } from "../assets/icons/checker-off.svg";
import { ReactComponent as On } from "../assets/icons/checker-on.svg";
import { Button } from "react-bootstrap";
import storeImage from "../assets/icons/store_image.svg";
import { debug } from "../settings";
import { EyeOpen, EyeClose } from "./Icons";

const commonStyles = {
  wrap: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px"
  },
  label: {
    flex: 1,
    margin: 5,
    fontWeight: "500"
  },

  // 
  // index.css 에 포함할 것
  // 
  input: {
    display: "block",
    width: "100%",
    padding: "0.375rem 0.75rem",
    fontSize: "0.875rem",
    // fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#495057",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    border: "1px solid #ced4da",
    borderRadius: "0.2rem",
  },
  inputValidated: { borderLeft: "5px solid #77d" },
  inputUnValidated: { borderLeft: "5px solid #d77" }
}

/**
 *
 * @param {S} props.name
 * @param {S} props.label
 * @param {S} props.type
 * @param {*} props.value
 * @param {F} props.onChange
 * @param {S} props.placeholder
 * @param {B} props.validated
 * @param {B} props.readOnly
 * @param {S} props.size sm as default
 * @param {S} props.comment
 * @param {O} props.styles
 * @param {S} props.inputType
 * @param {A} props.selectList 
 *
 * @returns
 */
export function FormInput(props) {
  if (props.inputType === "checkbox") return <CheckBox {...props} />;
  else if (props.inputType === "file") return <FileInput {...props} />;
  else return <Input {...props} />;
}

export function CheckBox({ onChange, className, checked, name, label }) {

  const onClick = () => {
    onChange({ target: { type: "checkbox", name } });
  }

  const styles = {
    checkWrap: {
      flex: 3,
      display: "flex", alignItems: "center",
    }
  }

  return (
    <div style={commonStyles.wrap}>
      {label && <p style={{ ...commonStyles.label, marginRight: 0 }}>{label}</p>}
      <div style={styles.checkWrap}>
        {
          checked
            ? <On onClick={onClick} width={22} />
            : <Off onClick={onClick} width={22} />
        }
      </div>
    </div>
  );
}

/**
 * <div style={commonStyles.wrap}>
      {label && <p style={commonStyles.label}>{label}</p>}
      <input className="form-control form-control-sm" {...myProps} />
    </div>
 */

/**
 * @info_inputTypes text | select 
 * 
 * @param {*} param0 
 * @returns 
 */
export function Input({
  value,
  placeholder,
  inputType,
  onChange,
  className,
  type = "text",
  style, display, width, size = "sm",
  label, name, readOnly,
  validated,
  selectList = [],
}) {

  let valStyle = validated ? commonStyles.inputValidated : commonStyles.inputUnValidated;
  const myStyle = {
    ...commonStyles.input,
    flex: 3,
    ...valStyle
  };

  const myProps = {
    style: myStyle,
    label,
    name, type, className, value, placeholder, onChange, readOnly
  }

  if (inputType === "select") {
    return (
      <div style={commonStyles.wrap}>
        {label && <p style={commonStyles.label}>{label}</p>}
        <select  {...myProps} >
          {selectList.map((item, index) => <option key={item + index} style={{ padding: 10 }}>{item}</option>)}
        </select>
      </div>
    )
  }

  if (inputType === "textarea") {
    return (
      <div style={commonStyles.wrap}>
        {label && <p style={commonStyles.label}>{label}</p>}
        <textarea {...myProps} />
      </div>
    )
  }

  if (inputType === "password") {
    return <InputPassword {...myProps} />
  }

  return (
    <div style={commonStyles.wrap}>
      {label && <p style={commonStyles.label}>{label}</p>}
      <input {...myProps} />
    </div>
  );

}

function InputPassword(props) {
  const { label } = props;
  const [seen, setSeen] = useState(false);
  return (
    <div style={commonStyles.wrap}>
      {label && <p style={commonStyles.label}>{label}</p>}
      <input
        {...props}
        type={seen ? "text" : "password"}
      />

      <div style={{ display: "flex", alignItems: "center" }}>
        {
          seen
            ? <EyeOpen style={{ marginLeft: "-25px" }} onClick={() => setSeen(false)} />
            : <EyeClose style={{ marginLeft: "-25px" }} onClick={() => setSeen(true)} />
        }
      </div>

    </div>
  )
}

export function FileInput(props) {
  const {
    label,
    value,
    size = "sm",
    onChangeInputFile
  } = props;

  const imgInput = useRef();
  // const [imageUrl, setImageUrl] = useState({});
  const [imagePreview, setImagePreview] = useState(value);
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  const onSelectImage = (event) => {
    if (debug) console.log('event.target.files', event.target.files);
    if (event.target.files && event.target.files.length) {
      setSelectedImageFile(event.target.files[0]);
      setImagePreview(URL.createObjectURL(event.target.files[0]));  // 이미지 미리보기 
      onChangeInputFile(event);
    }
  }

  const onClickImage = () => {
    // console.log('click');
    imgInput.current.click();
  }

  const styles = {
    txt: { fontSize: "16px", color: "#2b2b2b", },
    imgPreview: {
      margin: 5,
      width: '30%',
      borderRadius: 8
    }
  }

  return (
    <div>
      <div style={{ ...commonStyles.wrap, ...styles.txt }}>
        {label && <p style={commonStyles.label}>{label}</p>}
        <input style={{ display: 'none' }} type='file' accept='image/*' ref={imgInput} onChange={onSelectImage} />
        <Button
          style={{ flex: 3 }}
          onClick={onClickImage}
          variant="outline-secondary"
          width="100%"
          size={size}
        >
          <img src={storeImage} width={20} height={20} alt="store" />&nbsp;&nbsp;사진 선택
        </Button>

      </div>
      {imagePreview &&
        <div style={{ ...commonStyles.wrap, ...styles.txt }}>
          <div style={{ flex: 1 }}></div>
          <div style={{ flex: 3 }}>
            <img src={imagePreview} style={styles.imgPreview} />
            <p style={{ fontSize: 12, textAlign: 'left', margin: 0 }}>
              첨부 파일: {selectedImageFile ? selectedImageFile.name : ""}
            </p>
          </div>
        </div>
      }
    </div>
  )
}

/**
 *
 * @param {S} props.onClick
 * @param {*} props.btnText
 * @param {*} props.size
 * @param {*} props.validated
 * @param {*} props.disabled
 * @returns_bootstrap_FormControl
 */
export function InputWithBtn(props) {
  const { onClick, btnText, size, validated, disabled } = props;
  // if (debug) console.log("disabled?", !validated || disabled === true);
  const styles = {
    wrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end'
    },
    inputWrap: { width: "60%" },
    btnWrap: { width: "35%", ...commonStyles.wrap }
  };
  return (
    <div style={styles.wrap}>
      <div style={styles.inputWrap}>
        <FormInput {...props} size={size} style={{ marginBottom: 0 }} />
      </div>
      <div style={styles.btnWrap}>
        <Button
          width="100%"
          variant="info"
          size={size}
          disabled={!validated || disabled === true}
          onClick={onClick}
        >
          {btnText}
        </Button>
      </div>
    </div>
  );
}

export const Search = (props) => {
  const {
    onChange,
    onClick,
    value,
    placeholder,
    btnDisabled,
    btnText,
    onKeyUp,
  } = props;
  return (
    <Form.Group as={Row} controlId="search-text">
      <Col sm="3">
        <Form.Control
          size="sm"
          type="text"
          onChange={onChange}
          placeholder={placeholder || "search text here..."}
          onKeyPress={onKeyUp}
          value={value || ""}
        />
      </Col>
      <Col sm="9">
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={onClick}
          disabled={btnDisabled === "true"}
        >
          {btnText || "Search"}
        </Button>
      </Col>
    </Form.Group>
  );
};

export const SelectFilters = (props) => {
  const { defaultValue, list, onChange, onClick } = props;
  return (
    <Form.Group as={Row} controlId="categoryInput">
      <Col sm="3">
        <Form.Control
          onChange={onChange}
          size="sm"
          type="text"
          as="select"
          value={defaultValue}
        >
          {list.map((item, index) => {
            return <option key={index}>{item.name}</option>;
          })}
        </Form.Control>
      </Col>
      {/* <Form.Label column sm="1">Filter </Form.Label> */}
      <Button
        size="sm"
        variant="outline-secondary"
        onClick={onClick}
      >
        Filter
      </Button>
    </Form.Group>
  );
};
