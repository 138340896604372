import React, { useEffect, useState } from "react";
import { useStyles, ModalWithButton, FormInput, Button } from "../../../components2";
import {
  dqrHwCheckCon,
  dqrHwProvision,
  dqrHwUpdate,
  dqrHwReset,
  dqrProvision,
  dqrUpdate, dqrDelete
} from "../../../lib/ec2-dqr-api";
import { authEndpoint } from "../../../settings/appSetting";
import { debug } from "../../../settings";

const str = {
  invalidParam: "INVALID PARAM",
  invalidPosname: "INVALID POSNAME",
  invalidShopId: "STORE NOT SAME",
  noItemsSelected: "선택된 항목이 없습니다!",
  noName: "명칭이 비어 있습니다!",
  alertMsg: "DQR Already Provisioned."
}

// 총 8개 항목
const inputFields = [
  // { name: "uid" },       // server에서 가져옴
  // { name: "key" },       // server에서 가져옴
  // { name: "tableName" }, // tableNo 에 따라 자동으로 선택
  {
    name: "ver",
    displayName: "Version",
    defaultValue: "q1",
    readOnly: true
  },
  {
    name: "shopId",
    displayName: "Shop ID",
    readOnly: true
  },
  {
    name: "refreshTime",
    displayName: "Refresh Time",
    defaultValue: "10",
    comment: "default = 20분",
    readOnly: true
  },
  {
    name: "baseUrl",
    displayName: "Base URL",
    defaultValue: authEndpoint,
    readOnly: true
  },
  {
    name: "tableNo",
    displayName: "Table No",
    // defaultValue: "012"
  }
]

export default function DqrProvisionModal(props) {
  const {
    visible,
    setVisible,
    shopInfo,
    dqr, // server Dqr 항목
    checkConnection,
    isEdit,
    itemOptions3,
    updateDqrList
  } = props;

  const { gStyle } = useStyles();
  const [isLoading, setIsLoading] = useState(false)
  const [fields, setFields] = useState({})

  useEffect(() => {
    // console.table(itemOptions3.TableList)

    const _fields = {}
    inputFields.map(f => {
      _fields[f.name] = isEdit
        ? dqr[f.name] || f.defaultValue || ""
        : f.defaultValue || ""
      if (f.name === "shopId") _fields[f.name] = shopInfo.shopId
      if (f.name === "refreshTime") _fields[f.name] = "20"
      return null
    })
    setFields(_fields)

  }, [dqr])

  const onSubmit = async () => {
    try {
      const res = await dqrHwCheckCon()
      if (res.err) throw Error(res.err.message)

      if (fields.tableNo === "") throw Error("INVALID TableNo")
      setIsLoading(true)

      let body = { ...fields }

      const { tableName, err } = _getTableName()
      if (err) throw Error(err.message)

      body.tableName = tableName
      body.tableNo = fields.tableNo.padStart(3, "0")

      if (debug) console.log('dqr body', body)

      if (isEdit) await _updateDqr(body)
      else await _createDqr(body)

      await updateDqrList() // server dqrList
      await checkConnection()
    } catch (e) {
      alert(e.message)
    } finally {
      setIsLoading(false)
      setVisible(false)
    }
  }

  const _createDqr = async (body) => {
    try {
      const provisionBody = {
        ver: body.ver,
        shopId: body.shopId,
        tableNo: body.tableNo,
        tableName: body.tableName,
        refreshTime: body.refreshTime, // 항상 2자리수
        baseUrl: authEndpoint
      }

      let res = await dqrProvision({ body: provisionBody })
      if (res.err) throw Error(res.err.message)

      const newDqr = { ...res.result.Item }

      const hwProvisionBody = {
        ver: newDqr.ver,
        shopId: newDqr.shopId,
        tableNo: newDqr.tableNo,
        tableName: newDqr.tableName,
        refreshTime: newDqr.refreshTime,
        baseUrl: newDqr.baseUrl,
        uid: newDqr.uid,
        key: newDqr.authKey,
        // key: "7d5d5d5dd03e342323a2f7c5ce597eed"
      }

      res = await dqrHwProvision({ body: hwProvisionBody })
      if (res.err) throw Error(res.err.message)
      if (debug) console.log('res hw provision', res)

      if (dqr.uid) {
        if (dqr.uid === "F0F0F0F0") throw Error("UID F0F0F0F0 NOT DELETED")
        res = await dqrDelete({ uid: dqr.uid })
        if (res.err) {
          let errMsg = res.err.message
          if (errMsg === "NO ITEM FOUND") throw Error("NO DELETE ITEM FOUND")
          throw Error(errMsg)
        }
        alert("Old DQR Deleted!!")
      }

    } catch (e) {
      alert(e.message)
    }
  }

  const _updateDqr = async (body) => {
    try {
      if (body.shopId !== dqr.shopId) throw Error(str.invalidShopId)

      const hwUpdateBody = {
        shopId: body.shopId,
        uid: dqr.uid,
        tableNo: body.tableNo,
        tableName: body.tableName
      }

      // throw Error(JSON.stringify(hwUpdateBody, null, 2))

      let res = await dqrHwUpdate({ body: hwUpdateBody })
      // console.log('res dqrHwUpdate', res)
      if (res.err) throw Error(res.err.message)


      // throw Error(JSON.stringify(res, null, 2))

      const updateBody = {
        uid: dqr.uid,
        tableNo: body.tableNo,
        tableName: body.tableName
      }

      // throw Error(JSON.stringify(updateBody, null, 2))

      res = await dqrUpdate({ body: updateBody })
      if (res.err) throw Error(res.err.message)

      if (debug) console.log('res', res.result.Item)

    } catch (e) {
      alert(e.message)
    }
  }

  const onChange = async (e, name) => {
    setFields(pre => {
      return { ...pre, [name]: e.target.value }
    })
  }

  const onDqrReset = async () => {
    try {

      setIsLoading(true)
      let res = await dqrHwReset()
      if (res.err) throw Error(res.err.message)

      if (dqr.uid === "F0F0F0F0") throw Error("UID F0F0F0F0 NOT DELETED")

      if (dqr.uid !== "" && dqr.uid !== "F0F0F0F0") {
        if (debug) console.log('deleteing', dqr.uid)
        res = await dqrDelete({ uid: dqr.uid })
        if (res.err) throw Error(res.err.message)
        alert("Old DQR Deleted!!")
      }
      await updateDqrList() // serfer dqrList
      await checkConnection()
    } catch (e) {
      alert(e.message)
    } finally {
      setIsLoading(false)
      setVisible(false)
    }
  }

  const _hangulIncluded = (str) => {
    const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    return reg.test(str)
  }

  const _getTableName = () => {
    /**
     * 1. tableNo 가 존재하는가
     * 2. tableName이 한글이 있는가?
     */
    try {
      let tableList = []

      if (shopInfo.posName === "UNIONPOS") tableList = itemOptions3.TableList
      if (shopInfo.posName === "OKPOS") tableList = itemOptions3.TableList
      if (shopInfo.posName === "OASIS_POS") tableList = itemOptions3.tableList
      if (shopInfo.posName === "EASY_POS") tableList = itemOptions3.tableList

      let tables = []
      switch (shopInfo.posName) {
        case "UNIONPOS":
          tables = tableList.filter(table => table.TableNo === parseInt(fields.tableNo))
          break;
        case "OKPOS":
          tables = tableList.filter(table => parseInt(table.TableNo) === parseInt(fields.tableNo))
          break;
        case "OASIS_POS":
          tables = tableList.filter(table => parseInt(table.tableNo) === parseInt(fields.tableNo))
          break;
        case "EASY_POS":
          tables = tableList.filter(table => parseInt(table.tableNo) === parseInt(fields.tableNo))
          break;
      }

      if (tables.length !== 1) throw Error(str.invalidParam + " NO TableNo")

      let tableName = ""
      if (shopInfo.posName === "UNIONPOS") tableName = tables[0].TableName
      if (shopInfo.posName === "OKPOS") tableName = tables[0].TableName
      if (shopInfo.posName === "OASIS_POS") tableName = tables[0].tableName
      if (shopInfo.posName === "EASY_POS") {
        if (debug) { // 한글 이름 대신에 TABLE + tableNo
          tableName = tables[0].TABLE_DIS_NO
        } else {
          tableName = tables[0].tableName
        }
      }

      if (tableName === "") throw Error(str.invalidParam + " NO TableName")
      if (_hangulIncluded(tableName)) throw Error(str.invalidParam + " TableName 한글 포함됨")


      if (debug) console.log('tableName', tableName)

      if (tableName.length <= 3 && parseInt(tableName) > 0)
        return { tableName: "TABLE " + tableName }
      // 
      // dqr001 tableName 알파벳소문자 지원 안됨
      // 
      else
        return { tableName: tables[0].TableName.toUpperCase() }

    } catch (e) {
      return { err: { message: e.message } }
    }
  }

  const styles = {
    text: { ...gStyle.text, marginBottom: "3px" },
    formGroup: { margin: 0, padding: 5 }
  };

  return (
    <ModalWithButton
      visible={visible}
      setIsVisible={setVisible}
      title={isEdit ? "DQR Update" : "DQR Provisioning"}
      onSubmit={onSubmit}
      btnText={isEdit ? "Update" : "Provisioning"}
      isLoading={isLoading}
      backdrop="static"
    >
      {(dqr.uid && !isEdit) &&
        <p style={{ ...styles.formGroup, color: "red" }}>Provisioning을 진행하면 DQR UID가 변경됩니다.</p>
      }

      {dqr.uid &&
        <p style={styles.formGroup}>Connected DQR UID: {dqr.uid}</p>
      }
      <div style={styles.formGroup}>
        {Object.keys(fields).length > 0 &&
          inputFields.map((f, i) => {
            let lbl = f.comment ? `${f.displayName} (${f.comment})` : f.displayName
            return (
              <FormInput
                key={i}
                label={lbl}
                name={f.name}
                value={fields[f.name]}
                // placeholder={fields[f.defaultValue]}
                readOnly={f.readOnly}
                onChange={(e) => onChange(e, f.name)}
              />
            )
          })
        }
      </div>

      <Button
        title="Reset DQR" size="sm"
        variant="outline-secondary"
        isLoading={isLoading}
        onClick={onDqrReset}
      />
      <br />
    </ModalWithButton>
  )
}
