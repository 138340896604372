import conf from "../conf";
const debug = conf.debug;

export const signup = {
  inputFields: [
    {
      name: "username",
      displayName: "사용자 아이디",
      comment: "알파벳, 숫자 포함 8자 이상",
      defaultValue: debug ? "admin-4673" : "",
    },
    {
      name: "phone_number",
      validate: { type: "phone_number" },
      displayName: "전화번호",
      comment: "- 없이 숫자만 입력",
      defaultValue: debug ? "01053174673" : "",
    },
    {
      name: "userType", // user | shop | admin  | super
      inputType: "select",
      selectList: ["admin"],
      displayName: "사용자 구분",
      defaultValue: "admin",
      readOnly: true,
    },
    {
      name: "password",
      validate: { type: "password" },
      comment: "알파벳, 특수문자, 숫자 조합의 8자 이상이 필요합니다.",
      displayName: "비밀번호",
      defaultValue: debug ? "Psdl12@$" : "",
    },
    {
      name: "confirmPassword",
      displayName: "비밀번호 확인",
      defaultValue: debug ? "Psdl12@$" : "",
    },
  ],
};

// 
// User 정보 수정용
// 
export const user = {
  inputFields: [
    {
      name: "username",
      displayName: "사용자 아이디",
      readOnly: true,
    },
    {
      name: "nickName",
      displayName: "닉네임",
      readOnly: true,
    },
    {
      name: "userType",
      displayName: "사용자 구분",
      readOnly: true,
    },
    {
      name: "approved",
      displayName: "승인 여부",
      inputType: "checkbox",
    },
    {
      name: "lastModified",
      displayName: "마지막수정일",
      readOnly: true
    }
  ],
};

export const userOption = {
  inputFields: [
    {
      name: "zipcode",
      required: false,
    },
    {
      name: "addr1",
      required: false,
    },
    {
      name: "addr2",
      required: false,
    },
    {
      name: "phoneNo",
      required: false,
    },
    {
      name: "country",
      defaultValue: "대한민국",
      required: false,
    },

    // 
    // userType별 policy => 권한 설정 방법 고민 필요
    // 
    // {
    //   name: "policy",
    //   defaultValue: "",
    //   required: false,
    // }
  ]
}