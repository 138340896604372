import React from "react";
import { Page, Section } from "..";
// import IconExamples from "./IconExamples";
// import ButtonExamples from "./ButtonExamples";
import FormExamples from "./FormExamples";
// import TextExamples from "./TextExamples";
// import S3imageExamples from "./S3imageExamples"
// import UriEncoding from "./UriEncoding";
// import CookieHandle from "./CookieHandle";
// import QRCode from "./QRCode";
import { gStyle as styles, colors } from "..";


export default function UiViewTest(props) {

  const myStyle = {
    padding: "40px",
    title: {
      ...styles.title,
      ...styles.bold,
      color: colors.primary,
      textAlign: "center",
      fontSize: "40px"
    },
  }

  return (
    <Page stretch title="App UI Components View">

      {/* <Section padding={myStyle.padding} bgColor="#fff">
        <QRCode />
      </Section> */}
      
      {/* <Section padding={myStyle.padding} bgColor="#fff">
        <CookieHandle />
      </Section> */}

      {/* <Section padding={myStyle.padding} bgColor="#fff">
        <UriEncoding /> 
      </Section> */}

      {/* <Section padding={myStyle.padding} bgColor="#fff">
        <S3imageExamples />
      </Section> */}

      {/* <Section padding={myStyle.padding}>
        <IconExamples />
      </Section> */}

      <Section padding={myStyle.padding} bgColor="#fff">
        <FormExamples />
      </Section>

      {/* <Section padding={myStyle.padding}>
        <ButtonExamples />
      </Section> */}

      {/* <Section padding={myStyle.padding}>
        <TextExamples />
      </Section> */}

    </Page>
  )
}