import axios from "axios";
import { dqrSettingDebug, authEndpoint, apiKey } from "../settings/appSetting";

const axiosCall = async ({ method, path, body }) => {
  try {
    const baseUrl = dqrSettingDebug ? "http://localhost:5010/" : authEndpoint
    let res = await axios({
      method,
      url: baseUrl + path,
      data: body,
      headers: { 'x-taptag-key': apiKey }
    });

    if (res.status !== 200) throw new Error("Response Error");
    return res.data;
  } catch (e) {
    return { err: { message: e.message } };
  }
}

export const getDqrList = async ({ shopId }) => {
  const path = `api/dqr/list?shopId=${shopId}`;
  return axiosCall({ method: "GET", path });
};

export const resyncForce = async ({ body }) => {
  if (!body?.uid) return { err: { message: "NO UID" } }
  const path = `api/dqr/q1/resync/force`;
  return axiosCall({ method: "POST", path, body });
};

export const dqrProvision = async ({ ver = "q1", body }) => {
  // 
  // 필수 3개, 선택 3개
  // body = { shopId, tableNo, tableName 
  //         [, ver, refreshTime, baseUrl]}
  // 
  const path = `api/dqr/${ver}/provision`;
  return axiosCall({ method: "POST", path, body });
};

export const dqrUpdate = async ({ ver = "q1", body }) => {
  // body = {shopId, tableNo, tableName [, refreshTime, baseUrl]}
  const path = `api/dqr/${ver}/update`;
  return axiosCall({ method: "PUT", path, body });
};

export const dqrDelete = async ({ ver = "q1", uid }) => {
  if(!uid) return {err: {message: "NO UID"}}
  const path = `api/dqr/${ver}/delete?uid=${uid}`;
  return axiosCall({ method: "DELETE", path });
};

/** 
 * @DQR_HW_Serial_Interface 
 */
const axiosCallLocal = async ({ method, path, body }) => {
  try {
    const baseUrl = "http://localhost:62183"
    let res = await axios({
      method, url: baseUrl + path, data: body,
      headers: { 'x-taptag-key': apiKey }
    });

    if (res.status !== 200) throw new Error(res.status + " Response Error");

    // 
    // serial Lib는 err 형태임
    // 
    if (res.data.err) throw new Error(res.data.err);
    return res.data;
  } catch (e) {
    return { err: { message: e.message } };
  }
}
export const dqrHwCheckCon = async () => {
  const path = `/dqr/check-con`;
  return axiosCallLocal({ method: "GET", path });
  // try {
  //   const { data } = await axios.request({ method: 'GET', url: 'http://localhost:62183/dqr/check-con' });
  //   return data;
  // } catch (error) {
  //   console.error(error);
  //   return { err: { message: "Response Error" } }
  // }
};

export const dqrHwProvision = async ({ body }) => {
  // 
  // 8개 항목 필수
  // body = { uid, ver, tableNo, tableName, 
  //          key, refreshTime, shopId, baseUrl}
  // 
  const path = `/dqr/provision`;
  return axiosCallLocal({ method: "POST", path, body });

  // try {
  //   const { data } = await axios.request({
  //     method: 'POST',
  //     url: 'http://localhost:62183/dqr/provision',
  //     data: body
  //   });
  //   return data;
  // } catch (error) {
  //   if (debug) console.error(error);
  //   return { err: { message: "Response Error" } }
  // }
};

export const dqrHwUpdate = async ({ body }) => {
  // body = {tableNo, tableName, refreshTime, baseUrl}
  const path = `/dqr/update`;
  return axiosCallLocal({ method: "PUT", path, body });

  // try {
  //   const { data } = await axios.request({
  //     method: 'PUT',
  //     url: 'http://localhost:62183/dqr/update',
  //     data: body
  //   });
  //   return data;
  // } catch (error) {
  //   if (debug) console.error(error);
  //   return { err: { message: "Response Error" } }
  // }
}

export const dqrHwReset = async () => {
  const path = `/dqr/reset`;
  return axiosCallLocal({ method: "POST", path, body: {} });
}

/**
 * @시뮬레이션_test_only_DQR_HW_Serial_Interface
 */
export const testDqrHwCheckCon = async () => {
  const path = `/api/dqr/test/serial/con`;
  return axiosCall({ method: "GET", path });
};

export const testDqrHwProvision = async ({ ver = "q1", body }) => {
  // body = {shopId, tableNo, tableName [, refreshTime, baseUrl]}
  const path = `/api/dqr/${ver}/provision`;
  return axiosCall({ method: "POST", path, body });
};

export const testDqrHwUpdate = async ({ ver = "q1", body }) => {
  // // body = {shopId, tableNo, tableName [, refreshTime, baseUrl]}
  // const path = `/api/dqr/${ver}/update`;
  // return axiosCall({ method: "PUT", path, body });
  return { err: { message: "NOT SUPPORTED" } }
};
