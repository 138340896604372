import React from "react";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { colors as gColors, useStyles } from "./ui";

const colors = { ...gColors, }

const disabledColors = {
  info: "rgba(78, 198, 225, 0.5)",
  primary: "rgba(5, 184, 160, 0.5)",
  secondary: "rgba(108, 117, 125, 0.5)",
  light: "rgba(254, 254, 254, 0.5)",
  dark: "rgba(91, 91, 91, 0.5)",
}

const heights = {
  min: 25,
  sm: 44,
  lg: 50
}

const variants = ['primary', 'info', 'secondary', 'danger'];
const outlineVariants = ['outline-primary', 'outline-info', 'outline-danger', 'outline-secondary'];

export const buttonVariants = [...variants, ...outlineVariants]

export function Button(props) {
  const {
    variant, size = "lg",
    style, width, fontWeight, fontSize,
    children, title,
    className,
    onClick,
    isLoading = false, disabled = false,
    borderRadius
  } = props;
  // const dimension = useWindowDimensions();
  const { isMobileDevice } = useSelector((state) => state.platform);
  const { gStyle, colors } = useStyles();

  // const variants = ['primary', 'info', 'secondary',];
  // const outlineVariants = ['outline-primary', 'outline-info', 'outline-secondary'];

  let btnHeight = heights.lg
  if (isMobileDevice || size === 'sm') btnHeight = heights.sm;

  // let backgroundColor = (disabled || isLoading)
  //   ? disabledColors[variant] || disabledColors.dark
  //   : variants.includes(variant)
  //     ? colors[variant]
  //     : outlineVariants.includes(variant)
  //       ? colors.light
  //       : colors.default;
  let backgroundColor = colors.default;
  if (variants.includes(variant))
    backgroundColor = colors[variant];
  if (outlineVariants.includes(variant))
    backgroundColor = colors.light;
  if (disabled || isLoading)
    backgroundColor = disabledColors[variant] || disabledColors.dark;

  // let textColor = variants.includes(variant)
  //   ? colors.light
  //   : (outlineVariants.includes(variant))
  //     ? colors[variant.split('-')[1]]
  //     : colors.dark; // 마지막 variant=secondary 

  let textColor = colors.dark;
  if (variants.includes(variant)) textColor = colors.light;
  if (outlineVariants.includes(variant)) textColor = colors[variant.split('-')[1]];

  const defaultStyle = {
    buttonContainer: {
      fontSize: gStyle.text.fontSize,
      fontWeight: fontWeight || "400",
      color: textColor,
      width: width || null,
      height: btnHeight,
      paddingLeft: "15px", paddingRight: "15px",
      border: 0,
      outline: 0,
      borderRadius: borderRadius || 5,
      backgroundColor,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      position: "relative" // spinnerWrap을 위함
    },
    spinnerWrap: {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: btnHeight,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }
  };

  const _styles = {
    buttonContainer: { ...defaultStyle.buttonContainer, ...style }
  }

  const outlinedStyle = {
    buttonContainer: {
      ..._styles.buttonContainer,
      border: "1px solid " + textColor,
      backgroundColor: "#fff"
    },
  };

  const onClickBtn = () => {
    if (navigator.vibrate) navigator.vibrate(30)
    onClick()
  }

  // const styles = outlineVariants.includes(variant) ? outlinedStyle : defaultStyle;
  const styles = outlineVariants.includes(variant) ? outlinedStyle : _styles;

  return (
    <button
      className={className}
      style={styles.buttonContainer}
      disabled={disabled || isLoading}
      onClick={onClickBtn}
    >
      {isLoading &&
        <div style={defaultStyle.spinnerWrap}>
          <Spinner animation="border" size="sm" />
        </div>
      }
      {!isLoading && children}
      {title}
    </button>
  );
}

/**
 * @param {*} children
 * @param {*} title
 * @param {*} className : module.css 파일용.
 * @param {*} onClick
 */
export function UnderlineButton({
  children,
  title,
  onClick,
  className,
}) {

  const color = '#7d7d7d';
  const styles = {
    buttonContainer: {
      width: 'auto',
      height: 16,
      borderWidth: "0px 0px 1px",
      marginTop: "15px",
      backgroundColor: 'white',
      color,
      fontSize: 14,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  return (
    <button
      style={styles.buttonContainer}
      className={className}
      onClick={onClick}
    >
      {children}
      {title}
    </button>
  );
}
