import React, { useState } from "react";
import { LoaderFullPage } from "../../../components";
import { debug } from "../../../settings";
import { userListByPhoneNo, shopGet, shopList } from "../../../lib/ec2-api-lib";
// import { parseItemOptions, getItemOption } from "../../../lib-pos";
import { ModalForm, Search2, CustomTable, useStyles } from "../../../components2";
import { validatePhoneNo } from "../../../components2/PostFormSection";

const headerShopList = [
  { name: "shopName", displayName: "가맹점명" },
  { name: "posName", displayName: "사용 포스" },
  { name: "addr1", displayName: "주소" },
]

export default function SearchStore(props) {
  const { onSelectStore, defaultValue } = props

  const sName = defaultValue || "생마차"
  const [searchText, setSearchText] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false)
  const [storeList, setStoreList] = useState([])

  const onClickSearch = async () => {
    try {
      setIsLoading(true);

      const isPhoneNo = validatePhoneNo(searchText)

      let res = {}
      let _storeList = []
      if (isPhoneNo) {
        res = await userListByPhoneNo({ phone_number: searchText });
        if (res.err) throw Error(res.err.message)

        const userList = res.result.Items;

        _storeList = await Promise.all(userList.map(async user => {
          let res = await shopGet({ shopId: user.id })
          if (res.err) return null;
          return res.result.Item
        }))
      } else {
        res = await shopList({ query: { shopName: searchText } })
        if (res.err) throw Error(res.err.message)
        // if (debug) console.log('res ', res);
        _storeList = res.result.Items
      }

      // 
      // shopList에 null 제거
      // 
      const storeList2 = []
      _storeList.map(shop => {
        if (shop) storeList2.push(shop)
        return null
      })

      setStoreList(storeList2)
      setVisible(true)
    } catch (e) {
      if (debug) console.log(e);
      alert(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  const onClickItem = (store) => {
    setVisible(false)
    onSelectStore(store)
  }

  const onKeyUp = (e) => {
    if(e.code==="Enter") onClickSearch()
  }

  return (
    <>
      {/* <Section bgColor="#fff"> */}
        <p style={{ margin: "0px 0px 5px"}}>가맹점 이름 혹은 전화번호(- 없이)</p>
        <Search2
          onChange={e => setSearchText(e.target.value)}
          btnDisabled="false"
          onClick={onClickSearch}
          onKeyUp={onKeyUp}
          value={searchText}
        />
      {/* </Section> */}

      <ModalForm visible={visible} setIsVisible={setVisible} title="가맹점 정보" size="lg">
        <div>
          <CustomTable
            {...props}
            tableHeader={headerShopList}
            rowItems={storeList}
            rowItemKey="shopName"
            // onClickItem={onSelectStore}
            onClickItem={(shop) => onClickItem(shop)}
          />
        </div>
      </ModalForm>

      {isLoading && <LoaderFullPage />}
    </>
  );
}
