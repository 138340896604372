import React, {useEffect, useState, useRef} from "react";
// import { ReactComponent as More } from "../assets/icons/more.svg";
// import { Dropdown } from "react-bootstrap";
// import { gStyle } from "./ui";
import { TriAngleD, ThreeBar } from "./Icons";
import { useStyles } from "../components/ui";

/**
 * @param {array} list          array
 * @param {function} onSelect   functon
 */
// export function CustomDropDown({ list, onSelect }) {

//   /**
//    * @기본_스타일은_Navigation_CSS_에서_수행함
//    */
//   const styles = {
//     moreContainer: {
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "space-between",
//       color: "#b2b2b2",
//       fontSize: "24px",
//     },
//     dropdownMenu: {
//       minWidth: "100px",
//       padding: 0
//     },
//     dropdownItem: {
//       textAlign: "center",
//     },    
//   };

//   return (
//     <Dropdown>
//       <Dropdown.Toggle variant="default" id="dropdown-basic" style={styles.moreContainer} >
//         <More fill="#2b2b2b" />
//       </Dropdown.Toggle>

//       <Dropdown.Menu style={styles.dropdownMenu} align="end">
//         {list.map((item, index) => {
//           return (
//             <Dropdown.Item key={index} style={styles.dropdownItem} onClick={() => onSelect(item)}>
//               {/* <p style={styles.txt}>{item}</p> */}
//               {item}
//             </Dropdown.Item>
//           );
//         })}
//       </Dropdown.Menu>
//     </Dropdown>
//   );
// }

export function CustomDropdown(props) {
  const {
    title,
    children,
    position="left", 
    width,
    size,
    type
  } = props;
  const { gStyle } = useStyles()
  const [visibleDD, setVisibleDD] = useState(false); // dropDown show
  const [listening, setListening] = useState(false)

  const ddRef = useRef(null)

  useEffect(() => {
    if (listening) return
    if (!ddRef.current) return
    setListening(true)
    document.addEventListener(`click`, handleClick);
    document.addEventListener(`touchstart`, handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('touchstart', handleClick);
    }
  }, [])

  const handleClick = (evt) => {
    const cur = ddRef?.current
    if (!cur) return
    const node = evt.target
    if (cur?.contains(node)) return
    setVisibleDD(false)
  }

  const toggle = () => {
    const dropdowns = document.getElementsByClassName("onl-dropdown");
    for (let i = 0; i < dropdowns.length; i++) {
      dropdowns[i].style.display = "none";
    }
    setVisibleDD(!visibleDD);
  }

  const styles = {
    onlDropdown: {
      padding: 0,
      position: "relative",
      // backgroundColor: 'yellow',
      // marginBottom: 15
    },
    btn: {
      ...gStyle.subtitle,
      cursor: "pointer",
      // fontWeight: "600",
      fontSize: size === "lg" ? gStyle.subtitle.fontSize : gStyle.text.fontSize,
      display: "flex", alignItems: "center",
    },
    dropDownMenu: {
      display: visibleDD ? "block" : "none",
      width: width || "auto",
      padding: "8px 0", // item은 7px 로 합쳐서 15px
      border: "0.5px solid #dfdfdf",
      borderRadius: 7,
      position: "absolute",
      top: 35,
      // right: 0,
      backgroundColor: "#fff",
      // backgroundColor: colors.light,
      zIndex: 999
    },
    dropDownItem: {
      ...gStyle.text,
      padding: "12px 12px 0",
      cursor: "pointer"
    }
  }

  
  styles.dropDownMenu[position] = 0

  return (
    <div style={styles.onlDropdown} ref={ddRef}>
      {type === "nav"
        ?
        <p onClick={toggle} style={styles.btn}>
          {title}&nbsp; <ThreeBar style={{ fontSize: "1.6rem" }} onClick={toggle} />
        </p>
        :
        <p onClick={toggle} style={styles.btn}>{title}&nbsp;<TriAngleD /></p>
      }

      <div style={styles.dropDownMenu} className="onl-dropdown">
        {
          React.Children.map(children, (child, i) => {
            if (React.isValidElement(child)) return React.cloneElement(child, { setVisibleDD })
            return child
          })
        }
      </div>
    </div>
  )
}

export function DropdownItem(props) {
  const { children, onClick, setVisibleDD } = props;
  const { gStyle } = useStyles()

  const styles = {
    dropDownItem: {
      display: "flex", alignItems: "center",
      ...gStyle.text,
      padding: "7px 15px",
      cursor: "pointer"
    }
  }

  const onClickItem = () => {
    setVisibleDD(false)
    onClick()
  }

  return <p style={styles.dropDownItem} onClick={onClickItem}>{children}</p>
}

export function Divider(props) {
  const styles = {
    divider: {
      margin: "7px 0",
      borderTop: "0.5px solid #dfdfdf",
      opacity: "1",
    }
  }
  return <hr style={styles.divider} />
}