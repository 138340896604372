import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Button } from "../../../components2";
import { useStyles } from "../../../components2";
import { ArrowL } from "../../../components2/Icons";
import { SectionAnimate } from "./Sections";

export function Jumbotron(props) {
  const { data } = props
  const { gStyle } = useStyles()
  const { isMobilePhone } = useSelector(state => state.platform)
  // const navigate = useNavigate()
  // const navigate = props.history.push

  // console.log(data.name, data.type)

  const [contentAlign, setContentAlign] = useState("left")
  // const [btnAlign, setBtnAlign] = useState("flex-start")

  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    const tAlign = (data.layout === "row-text-left")
      ? "left"
      : (data.layout === "row-text-center")
        ? "center"
        : "right"
    setContentAlign(tAlign)

  }, [data])

  const onClickLink = (url) => {
    if (url.includes("http")) window.location.replace(url)
    else props.history.push(url)
  }

  const bgImg = `url(${data.images[0].url})`

  const styles = {
    jumboWrap: {
      backgroundImage: bgImg,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    contentWrap: {
      width: "100%",
      minHeight: "40vh",
      padding: isMobilePhone ? "50px 10px" : "50px 30px",
      textAlign: contentAlign,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.3)" // overlay mask
    },
    title: {
      ...gStyle.title,
      fontSize: isMobilePhone ? "26px" : "40px",
      color: "#fff",
      marginBottom: 30
    },
    subtitle: { ...gStyle.subtitle, color: "#fff", lineHeight: "2.0rem", fontWeight: "500" },
    btnWrap: {
      marginTop: 30,
      display: "flex",
      flexWrap: "wrap",
      justifyContent: (contentAlign === "left") ? "flex-start" : "flex-end",
    },
    btn: {
      width: "200px",
      borderRadius: "10px",
      marginRight: "20px",
      marginBottom: "10px"
    }
  }

  if (data.type === "jumbotron") {
    return (
      <div style={styles.jumboWrap}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div style={styles.contentWrap}>
          <Container>
            <HiddenBtn {...props} isHovered={isHovered} color="#fff" onClick={() => props.history.goBack()} />
            <Content {...props} styles={styles} data={data} onClickLink={onClickLink} />
          </Container>
        </div>

      </div>
    )
  }

  if (data.type === "full-image") {
    return (
      <Container>
        <SectionAnimate>
          <div style={styles.jumboWrap}>
            <div style={styles.contentWrap}>
              <Content {...props} styles={styles} data={data} onClickLink={onClickLink} />
            </div>
          </div>
        </SectionAnimate>
      </Container>

    )
  }

}


function Content(props) {
  const { colors } = useStyles()

  // console.log(props.data)

  return (
    <div>
      <h1 style={props.styles.title}>{props.data.title}</h1>
      {props.data.texts &&
        props.data.texts.map((t, i) => {
          return (
            <p
              key={"text" + i}
              style={{
                ...props.styles.subtitle,
                margin: "0.5rem 0px",
                color: colors[t.variant] || "#fff",
                display: t.display || "block"
              }}
            >
              {t.value}
            </p>
          )
        })
      }

      {props.data.links &&
        <div style={props.styles.btnWrap}>
          {
            props.data.links.map((link, i) => {
              return (
                <Button
                  key={"link" + i}
                  variant={link.variant}
                  style={props.styles.btn}
                  title={link.text}
                  onClick={() => props.onClickLink(link.linkUrl)}
                />
              )
            })
          }
        </div>
      }
    </div>
  )
}


function HiddenBtn(props) {
  const { gStyle } = useStyles()

  // const [isHovered, setIsHovered] = useState(false)

  return (
    // <div
    // // onMouseEnter={() => setIsHovered(true)}
    // // onMouseLeave={() => setIsHovered(false)}
    // >
    <ArrowL
      style={{
        ...gStyle.subtitle,
        color: props.isHovered ? props.color : "transparent",
        fontSize: "2rem",
      }}
      onClick={props.onClick}
    />

  )
}
