import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { debug } from "../../settings";

export default function AuthenticatedRoute({ component: C, ...rest }) {

  const { isSigned } = useSelector((state) => state.user);

  // if(debug) console.log("isSigned:", isSigned)

  return (
    <Route {...rest} render={(props) =>
      isSigned
        ? <C {...props} />
        : <Redirect to={`/login?redirect=${props.location.pathname}${props.location.search}`} />}
    />
  );
}
