import React from "react";
import "../App.css";
import { ui } from "../settings";

export default function Footer() {
  const styles = {
    wrap: {
      marginTop: "10px",
      width: "100%",
      padding: "60px 0",
      backgroundColor: ui.color.primary,
      color: "#ddd",
    },
    copy: { color: "#fff", fontWeight: "600" },
  };
  return (
    <div style={styles.wrap}>
      <div className="container">
        <p>
          Copy right
          <span style={styles.copy}>&copy;</span> 2017 PSDL Inc. All Rights
          Reserved. 경기도 성남시 대왕판교로 815, 정보보호클러스터 #466.
        </p>
        <p>Email: sales@psdl.co.kr</p>
        <p>Tel : 031-984-7027</p>
      </div>
    </div>
  );
}
