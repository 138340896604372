import React, { useState } from "react";
// import { useDispatch } from "react-redux";
import { useInputFields } from "../../lib/hooksLib";
import { FormInput, LoaderButton } from "../../components";
import { debug } from "../../settings";
import { userCreate, signUp, logIn } from "../../lib/apiLib";

export default function SignupConfirmSection(props) {
  const { newuser, confirmCode } = props;  
  // const dispatch = useDispatch();

  const [fields, setFields] = useInputFields({ confirmationCode: "" });
  const [isLoading, setIsLoading] = useState(false);

  const validate = () => {
    return fields.confirmationCode.length > 0;
  };

  const onsubmit = async () => {
    if(debug) console.log('Received code:', confirmCode);
    try {
      setIsLoading(true);
      if (fields.confirmationCode !== confirmCode)
        return alert("인증번호가 올바르지 않습니다! 다시 입력해 주세요!");

      const signedUser = await signUp(newuser);
      if (debug) console.log("signedUser:", signedUser);

      let res = await logIn(newuser.username, newuser.password);
      if (debug) console.log("Login res:", res);

      res = await userCreate({
        body: {
          username: newuser.username,
          userType: "admin",
          phone_number: newuser.attributes.phone_number,
          email: newuser.attributes.email,
          nickName: newuser.username,
          approved: false
        }
      });
      if (debug) console.log("createdUser res:", res);
      setIsLoading(false);

      props.history.push('/login');

    } catch (e) {
      setIsLoading(false);
      alert("Signup ERROR:\n" + e.message);
    }
  }

  const resendCode = async () => {
    try {
      setIsLoading(true);
      alert("인증코드가 재발송 기능이 없습니다. 처음부터 다시 시작하세요.");      
      setIsLoading(false);
    } catch (e) {
      alert(e.message);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <FormInput
        name="confirmationCode"
        label="인증 코드"
        validated={validate()}
        value={fields.confirmationCode}
        onChange={setFields}
        comment="휴대전화 문자를 확인한 후 인증번호를 입력하세요!"
        colLeft="4"
        type="text"
      />

      <hr />
      <LoaderButton
        block
        onClick={onsubmit}
        isLoading={isLoading}
        disabled={!validate()}
      >
        인증코드 제출
      </LoaderButton>

      <LoaderButton
        size="sm"
        style={{ marginTop: "10px" }}
        variant="outline-secondary"
        onClick={resendCode}
        isLoading={isLoading}
      >
        인증코드 다시 보내기
      </LoaderButton>
    </div>
  );
}
