import React, {useState} from "react";
import { useInputFields } from "../../lib/hooksLib";
import { gStyle } from "..";
import { FormInput } from "../FormInputs2";
import {
  createFields, validateInputValue
} from "../PostFormSection";
import { Button } from "react-bootstrap";
import { fileUpload } from "../../lib/apiLib";
// import { conf } from "../../settings";


const inputFields = [
  {
    name: "userID",
    displayName: "사용자 아이디",
    comment: "영문자 숫자 포함 8자리 이상",
  },
  {
    name: "password",
    displayName: "비밀번호",
    type: "password",
    inputType: "password"
  },
  {
    name: "isSoldout",
    displayName: "승인여부",
    inputType: "checkbox",
    comment: "approved by Admin ?",
  },
  {
    name: "description",
    displayName: "Text Input",
    inputType: "textarea",
  },
  {
    name: "image",
    displayName: "Image File",
    inputType: "file",
    required: false,
  },
]

export default function FormExamples() {
  // const inputFields = db.shopItemUpdate.inputFields;
  const [fields, setFields] = useInputFields(
    createFields({ inputFields, editItems: null })
  );

  const [imageUrl, setImageUrl] = useState("");

  const file = {}

  const onChangeInputFile = async (e) => {
    file.current = e.target.files[0];
    console.log("file", file.current.name);

    /**
     * @file_upload시_파일명을_encode하면_s3에서_permission_deny_에러가_남
     * @s3는_파일명을_또한번_인코딩하는데_이때 % 를 인코딩하여 %25로 대체되므로 전혀 다른 url이 만들어 진다.
     */
    let res = await fileUpload(file.current, "kebin")
    console.log('res fileupload', res);
    setImageUrl(res.key)
  }
  return (
    <div style={{ marginRight: 'auto', marginLeft: 'auto' }}>
      <h2 style={gStyle.title}>Form Example</h2>
      {
        inputFields.map((item, index) => {

          const myProps = {
            key: index,
            name: item.name,
            label: item.displayName,
            type: item.type || "text",
            value: fields[item.name],
            onChange: setFields,
            placeholder: item.placeholder || item.name,
            validated: validateInputValue(item, fields[item.name]),
            readOnly: item.readOnly || false,
            comment: item.comment,
            inputType: item.inputType || "text",
            selectList: item.selectList || [],
            checked: item.inputType === "checkbox" && fields[item.name],
            onChangeInputFile
          };

          return <FormInput {...myProps} />;
        })
      }
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Button
          variant="primary" style={{ width: 200 }}
          onClick={() => console.log('fields', fields)}
        >확인</Button>
      </div>
      {imageUrl &&
        <div style={{width: "20%", marginTop: 20}}>
          <img src={imageUrl} alt="test" style={{width: "100%"}} />
        </div>
      }
    </div>
  )
}