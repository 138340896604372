import React, { useState } from "react";
import { colors } from "./ui";
import {
  Pencil as PencilIcon, PencilSquare,
  ArrowLeftShort as ArrowLeft, ArrowRightShort as ArrowRight, ArrowDownShort as ArrowDown, ArrowUpShort as ArrowUp,
  ChevronLeft, ChevronRight, ChevronUp, ChevronDown,
  ThreeDots, ThreeDotsVertical,
  XLg,
  Save as BsSave,
  Gear,
  Collection,
  Trash as BsTrash, Recycle as BsRecycle,
  EyeFill as Eye, EyeSlashFill as EyeSlash,
  PlusLg, DashLg
} from "react-bootstrap-icons";

function MyIcon({ style, children, onClick }) {
  const [mouseOver, setMouseOver] = useState(false);
  const opacity = "80";
  const myStyle = {
    wrap: {
      display: "inline",
      padding: "5px",
    },
    icon: {
      cursor: "pointer",
      color: mouseOver ? colors.dark + opacity : colors.dark
    }
  }

  if (style) {
    myStyle.icon = { ...myStyle.icon, ...style };
    if (style.color) {
      myStyle.icon.color = mouseOver
        ? style.color + opacity
        : style.color;
    }
  }

  const udpateChildrenWithProps = React.Children.map(
    children,
    (child, i) => {
      return React.cloneElement(child, {
        style: myStyle.icon,
        index: i,
        onMouseEnter: () => setMouseOver(true),
        onMouseLeave: () => setMouseOver(false),
        onClick: onClick
      })
    }
  )

  // return <div style={myStyle.wrap}>{udpateChildrenWithProps}</div>
  return <>{udpateChildrenWithProps}</>
}

/** @Pencils */
export function Pencil(props) {
  return <MyIcon {...props} ><PencilIcon /></MyIcon>
}
export function PencilBox(props) {
  return <MyIcon {...props} ><PencilSquare /></MyIcon>
}

/** @Arrows */
export function ArrowL(props) {
  return <MyIcon {...props} ><ArrowLeft /></MyIcon>
}

export function ArrowR(props) {
  return <MyIcon {...props} ><ArrowRight /></MyIcon>
}

export function ArrowU(props) {
  return <MyIcon {...props} ><ArrowUp /></MyIcon>
}

export function ArrowD(props) {
  return <MyIcon {...props}><ArrowDown /></MyIcon>
}

/** @Chevrons */
export function ChevronL(props) {
  return <MyIcon {...props} ><ChevronLeft /></MyIcon>
}

export function ChevronR(props) {
  return <MyIcon {...props} ><ChevronRight /></MyIcon>
}

export function ChevronU(props) {
  return <MyIcon {...props} ><ChevronUp /></MyIcon>
}

export function ChevronD(props) {
  return <MyIcon {...props} ><ChevronDown /></MyIcon>
}

/** @Dots */
export function ThreeDotsH(props) {
  return <MyIcon {...props} ><ThreeDots /></MyIcon>
}

export function ThreeDotsV(props) {
  return <MyIcon {...props} ><ThreeDotsVertical /></MyIcon>
}

export function Close(props) {
  return <MyIcon {...props} ><XLg /></MyIcon>
}

export function Save(props) {
  return <MyIcon {...props} ><BsSave /></MyIcon>
}

export function Setting(props) {
  return <MyIcon {...props} ><Gear /></MyIcon>
}

export function Grouping(props) {
  return <MyIcon {...props} ><Collection /></MyIcon>
}

export function Trash(props) {
  return <MyIcon {...props} ><BsTrash /></MyIcon>
}

export function Recycle(props) {
  return <MyIcon {...props} ><BsRecycle /></MyIcon>
}

export function EyeOpen(props) {
  return <MyIcon {...props} ><Eye /></MyIcon>
}

export function EyeClose(props) {
  return <MyIcon {...props} ><EyeSlash /></MyIcon>
}

export function Plus(props) {
  return <MyIcon {...props} ><PlusLg /></MyIcon>
}

export function Minus(props) {
  return <MyIcon {...props} ><DashLg /></MyIcon>
}