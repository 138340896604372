import conf from "../conf";
const debug = conf.debug;

export const shop = {
  inputFields: [
    {
      name: "shopId",
      displayName: "Shop 아이디",
      // defaultValue: "-", // page에서 key 로 사용하지만 디폴트값이 필요 없는 듯.
      readOnly: true
    },
    {
      name: "shopCategory", 
      displayName: "상품 아이템 구분",
      selectList: ["foods", "cafe"],
      inputType: "select",
      defaultValue: "foods"
    },
    {
      name: "shopName",
      displayName: "Shop 이름",
      defaultValue: debug ? "등록테스트 - PSDL 레스토랑" : "",
    },
    {
      name: "licenseNo",
      displayName: "사업자 번호",
      defaultValue: debug ? "12345678-123" : "",
    },
    {
      name: "image",
      type: 'file',
      inputType: "file",
      displayName: "가맹점 전경 사진",
      required: false
    },
    {
      name: 'mobileNo',
      displayName: "대표 휴대전화",
      defaultValue: debug ? "010-0000-0000" : ""
    },
    {
      name: 'zipcode',
      displayName: "우편번호",
      comment: "주소찾기에서 자동으로 인식됨.",
      readOnly: true,
      defaultValue: debug ? "12345" : ""
    },
    {
      name: 'addr1',
      readOnly: true,
      displayName: "도로명/지번",
      comment: "주소찾기에서 자동으로 인식됨. 도로명 주소 혹은 지번 주소",
      defaultValue: debug ? "경기 성남시 수정구 대왕판교로 815" : ""
    },
    {
      name: 'addr2',
      displayName: "상세 주소",
      comment: "상세 주소. (예)아파트의 동/호수",
      defaultValue: debug ? "정보보호클러스터 466호" : ""
    },
    {
      name: 'country',
      displayName: "국가",
      defaultValue: "대한민국",
      readOnly: true
    },
    {
      name: 'phoneNo',
      displayName: "Shop 유선전화",
      defaultValue: debug ? "031-5555-6666" : "",
      required: false
    }
  ],
};

export const shopItem = {
  inputFields: [
    {
      name: "shopId",      
      displayName: "Shop ID",      
      readOnly: true
    },
    // {name: "id",}, sort key
    {
      name: "itemName",
      displayName: "아이템 이름", // 중복 체크 필요
      
    },
    {
      name: "itemGroup",
      displayName: "아이템 그룹",
      required: false
    },
    {
      name: "image",
      inputType: "file",
      required: false,
      displayName: "아이템 사진",      
    },
    {
      name: "currency",      
      displayName: "통화",
      inputType: "select",
      selectList: ["KRW", "USD"],
      defaultValue: "KRW",
    },
    {
      name: "price",
      type: "number",
      displayName: "가격",
      defaultValue: 1000
    },
    {
      name: "description",      
      displayName: "상품 설명",
      required: false,
      comment: "간단한 상품정보 설명..."
    },
    
    // 서버에서 자동 설정하고, 추후 업데이트 페이지에서 변경 가능
    // {
    //   name: "dispOrder",    
    // }
  ],
};

export const shopItemOption = {
  inputFields: [
    {
      name: "shopId",
      displayName: "Shop ID",      
      readOnly: true
    },
    {
      name: "optionName",
      displayName: "옵션 아이템 이름", // 중복 체크 필요
    },
    {
      name: "itemName", // option이 포함되는 아이템, 없으면 common
      inputType: 'select',
      selectList: ['common'], // itemName을 불러옴
      displayName: "아이템 이름", // 중복 체크 필요
    },
    {
      name: "optionImage",
      inputType: "file",
      displayName: "아이템 사진",      
    },
    {
      name: "currency",
      displayName: "통화",
      defaultValue: "KRW",
    },
    {
      name: "price",
      displayName: "가격",
    },
  ],
};
