import React, { useState } from "react";
import {
  createFields,
  validateInputValue,
} from "../../lib/utils";
import { useInputFields } from "../../lib/hooksLib";
import { codeGet } from "../../lib/apiLib";
import { FormInput, LoaderButton } from "../../components";
import { debug, db, conf } from "../../settings";

const adminPhoneNo = conf.superAdminPhoneNo;

export default function SignupSection(props) {
  const inputFields = db.signup.inputFields;

  const { setNewuser, setServerConfirmNum } = props;
  const [fields, setFields] = useInputFields(createFields({ inputFields }));
  const [isLoading, setIsLoading] = useState(false);  

  const validate = () => {
    let val = true;
    inputFields.map(
      (item) => (val = val && validateInputValue(item, fields[item.name]))
    );
    return val && checkUsernameLength(fields.username);
  };

  // 
  // 추가 validation
  // 
  const checkUsernameLength = (uname)=>{
    const minLength=8;
    return uname.length >= minLength;
  }

  const onNext = async () => {
    try {
      setIsLoading(true);

      const newuser = {
        username: fields.username,
        password: fields.password,
        attributes: { phone_number: "+82" + fields.phone_number.slice(1) },
      }
      if (debug) console.log("newUser:", newuser);
      setNewuser(newuser);
      
      // 
      // 유저가 아닌 관리자의 전화번호에서 확인함
      // 
      const res_code = await codeGet({ phone_number: adminPhoneNo });
      // const res_code = await codeGet({ username: fields.phone_number });

      if (debug) console.log('res: ', res_code.result);

      if (!res_code.status) throw new Error("No Code Arrived");      
      setIsLoading(false);
      setServerConfirmNum(res_code.result?.code);

    } catch (e) {
      if (debug) console.log('signup Error: ', e);
      setIsLoading(false);
      alert('SignUp Error:\n' + e.message);
    }
  }

  return (
    <div>
      {inputFields.map((item, index) => {        

        let validated = validateInputValue(item, fields[item.name]);
        if(item.name==='username') validated = validated && checkUsernameLength(fields[item.name]);
        if (item.name === "confirmPassword")
          validated = validated && fields.password === fields[item.name];

        const myProps = {
          key: index,
          name: item.name,
          label: item.displayName || item.name,
          type: item.name === "password" || item.name === "confirmPassword"
              ? "password"
              : "text",
          value: fields[item.name] || "",
          onChange: setFields,
          placeholer: item.placeholder || item.name,
          validated: validated,
          readOnly: item.readOnly || false,
          comment: item.comment || "",
          inputType: item.inputType || "text",
          selectList: item.selectList || [],
          required: item.required,
          colLeft: 4,
        };
        return <FormInput {...myProps} />;
      })}
      <hr />

      <LoaderButton
        block
        onClick={onNext}
        isLoading={isLoading}
        disabled={!validate()}
      >
        Next
      </LoaderButton>
    </div>
  );
}
