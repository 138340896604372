import { createSlice } from "@reduxjs/toolkit";
import { checkBrowser, getMobileOsType } from "../lib/utils";

const initialState = {
  isMobileDevice: false,
  platformType: null, // android, ios
};

export const platform = createSlice({
  name: "platform",
  initialState,
  reducers: {
    setPlatform: (state, action) => {
      const { isMobileDevice, details } = checkBrowser();
      state.isMobileDevice = isMobileDevice;
      state.platformType = getMobileOsType(details);
    }
  },
});

// Action creators are generated for each case reducer function
export const { setPlatform } = platform.actions;

export default platform.reducer;
