import React, { useState, useEffect } from "react";
import { useStyles, ModalForm, FileInputMd, FormInput } from "../../components2";
import { useCookies } from "react-cookie";
import { json2obj } from "../../lib/utils";

// import { postCreate, postUpdate, postDelete } from "../../lib/ec2-api-cms-lib";
// import { createPost, updatePost, deletePost } from "../../lib/ec2-api-cms2-lib";
// import { cmsPost, cmsNotification } from "../../settings/cms2-db";
// import { fileUpload } from "../../lib/apiLib";
// import { imageCompress, tsId, getTimestampFromNow } from "../../lib/utils";
// import { useSelector } from "react-redux";
// import { md2html } from "../../lib/md-utils";

export default function PopupNotiModal(props) {
  const { setIsVisible, visible, post } = props;
  const title = "공지 사항"
  const { gStyle } = useStyles()
  const [cookies, setCookie, removeCookie] = useCookies(["NotiIdBlackList"]);

  const [textList, setTextList] = useState([])
  const [neverSeeAgain, setNeverSeeAgain] = useState(false)

  useEffect(() => {
    if (post) {
      const txts = post.textContent.split("\n")
      setTextList(txts)
    }
  }, [post])

  const onChangeCheckbox = () => {

    // console.log('cookies', cookies);
    const blackList = cookies.NotiIdBlackList || [] // cookies 는 json으로 저장하는 듯 하므로 stringify 를 하지 않아도 됨
    

    // 다시 보기로 변경 -> post.postId 제거
    if (neverSeeAgain) {
      const newBl = []
      blackList.map(id => {
        if (id !== post.postId) newBl.push(id)
        return null
      })
      // console.log('newBl', newBl)
      setCookie("NotiIdBlackList", JSON.stringify(newBl))
    }

    // 보지 않기로 변경 -> post.postId 추가
    else {
      if(!blackList.includes(post.postId)) {
        blackList.push(post.postId)
        // console.log('newBl', blackList)
        setCookie("NotiIdBlackList", JSON.stringify(blackList))
      }
    }
    setNeverSeeAgain(!neverSeeAgain)
  }

  const styles = {
    text: { ...gStyle.text, marginBottom: "3px" },
    formGroup: {
      margin: 0,
      padding: "5px 10px",
      backgroundColor: '#eaeaea'
    }
  };

  return (
    <ModalForm visible={visible} setIsVisible={setIsVisible} title={title}>

      <p>NotiBlackList: {cookies.NotiIdBlackList}</p>

      {
        textList.length > 0 &&
        textList.map((t, i) => {
          return <p key={i}>{t}</p>
        })
      }

      <h5>팝업 부모 페이지에서 cookies.NotiIdBlackList 를 확인하여 공지를 띄울 지 결정해야함</h5>

      <div style={styles.formGroup}>
        <FormInput
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            width: "150px",
            margin: 0
          }}
          label="다시 보지 않기"
          name="naverSeeAgain"
          inputType="checkbox"
          checked={neverSeeAgain}
          onChange={onChangeCheckbox}
        />
      </div>

    </ModalForm>
  )
}
