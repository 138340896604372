import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { useStyles } from "../../../components2";
import { Button } from "../../../components2";
// import { SectionAnimate } from "../../../components2/Layout";
import { SectionAnimate } from "./Sections";

export function SectionTextImage2(props) {
  const { data } = props
  const { gStyle, colors } = useStyles()
  const { isMobilePhone } = useSelector(state => state.platform)
  // const navigate = useNavigate()

  // console.log(data)

  const [textWrapWidth, setTextWrapWidth] = useState("50%")
  const [imgWrapWidth, setImgWrapWidth] = useState("50%")

  useEffect(() => {
    const imgSize = data.images[0].size

    if (!(data.layout === "column")) {
      if (imgSize === "sm") {
        setImgWrapWidth("25%")
        setTextWrapWidth("75%")
      }
      if (imgSize === "lg") {
        setImgWrapWidth("75%")
        setTextWrapWidth("25%")
      }
      if (imgSize === "full") {
        setImgWrapWidth("90%")
        setTextWrapWidth("10%")
      }
    } else {
      setTextWrapWidth("100%")
      setImgWrapWidth("100%")
    }

  }, [data])

  const onClickLink = (url) => {
    if (url.includes("http")) window.location.replace(url)
    // else navigate(url)
    else props.history.push(url)
  }

  const _getFlexDir = () => {
    if (data.layout === "column") return "column-reverse"
    if (data.layout === "row-text-left") return "row-reverse"
    if (data.layout === "row-text-right") return "row"
    return "row"
  }

  const styles = {
    wrap: {
      width: "100%",
      padding: "30px 15px",
    },
    title: {
      ...gStyle.title,
      fontSize: isMobilePhone ? "26px" : "40px",
      color: colors.dark,
      margin: "15px 30% 30px 15px",
    },
    contentWrap: {
      padding: "15px 10px",
      display: "flex",
      flexDirection: _getFlexDir(),
      flexWrap: isMobilePhone ? "wrap" : "no-wrap",
      width: "100%"
    },
    textWrap: {
      padding: "0 15px",
      display: "flex", flexDirection: "column",
      justifyContent: "center",
      width: textWrapWidth
    },
    text: { ...gStyle.subtitle, color: colors.dark, lineHeight: "2.0rem" },
    imgWrap: {
      padding: 15,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
      width: imgWrapWidth
    },
    btnWrap: {
      marginTop: 30,
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap"
    },
  }

  return (
    <Container>
      <SectionAnimate bgColor="#fff">
        <div style={styles.wrap}>
          <h2 style={styles.title}>{data.title}</h2>

          <div style={styles.contentWrap}>

            {data.images?.length > 0 &&
              data.images.map((imgObj, i) => {
                return (
                  <div
                    key={"section-img" + i}
                    style={{
                      ...styles.imgWrap,
                      display: "flex", justifyContent: "center", alignItems: "center",
                      padding: 15
                    }}
                  >
                    <img
                      style={{ width: "100%" }}
                      src={imgObj.url}
                      alt={"section pic " + i}
                    />
                  </div>
                )
              })
            }

            <div style={styles.textWrap}>
              {data.texts &&
                data.texts.map((t, i) => {
                  return <p key={"text" + i} style={styles.text}>{t.value}</p>
                })
              }

              {data.links?.length > 0 &&
                <div style={styles.btnWrap}>
                  {
                    data.links.map((link, i) => {
                      return (
                        <Button
                          key={"link" + i}
                          size="sm"
                          variant={link.variant}
                          title={link.text}
                          onClick={() => onClickLink(link.linkUrl)}
                        />
                      )
                    })
                  }
                </div>
              }

            </div>
          </div>
        </div>
      </SectionAnimate>
    </Container>
  )
}
