import React, { useEffect, useState } from "react";
import { Page, Section, LoaderFullPage, Table, colors } from "../../components";
import { Plus, } from "../../components2/Icons";
import { CustomTable } from "../../components2/Tables2";
import { listPost, updatePost } from "../../lib/ec2-api-cms2-lib";
import { json2obj } from "../../lib/utils"
import { CategoryModal } from "./CategoryModal";
import { createDefaultGroupCat } from "./createDefaultGroupCategory";
import { Alert } from "../../components2";

const catHeader = [
  // { name: "selected", displayName: "선택", type: "checkbox", keyName: "categoryId" },
  { name: "categoryId", displayName: "Category ID", selectKey: true},
  { name: "categoryName", displayName: "Category Name", sorting: true },
  { name: "categoryType", displayName: "Category Type" },
  { name: "groupName", displayName: "Group Name", sorting: true },  
]

// let cnt = 0;

export default function Categories(props) {
  const title = "카테고리 추가 | 편집";
  const [isLoading, setIsLoading] = useState(true)

  const [visibleCatModal, setVisibleCatModal] = useState(false)

  const [groupList, setGroupList] = useState([])
  const [items, setItems] = useState([])
  const [isEditCat, setIsEditCat] = useState(false)
  const [editItemCat, setEditItemCat] = useState({})

  const [alertMsg, setAlertMsg] = useState("")

  useEffect(() => {
    onload();
  }, [])

  const onload = async () => {
    try {
      setIsLoading(true);

      let res = await createDefaultGroupCat()
      if (res.err) throw Error(res.err.message)

      const cList = json2obj(res.result.Item.categoryList)
      setItems(cList)
      // console.log(cList)

      const gList = json2obj(res.result.Item.groupList)
      setGroupList(gList)

    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false)
    }
  }

  const pageProps = {
    ...props,
    onload,
    items, groupList,
    categoryList: items,
    editItemCat, setEditItemCat,
    isEditCat, setIsEditCat,
    setAlertMsg,
    // setVisibleCatModal,
    // alertMsg, setAlertMsg, deleteItem, setDeleteItem
  }

  const onSelect = (obj) => {
    setIsEditCat(true)
    setEditItemCat(obj)
    setVisibleCatModal(true)
  }

  const onClickPlus = () => {
    setIsEditCat(false)
    setEditItemCat({})
    setVisibleCatModal(true)
  }

  const onDeleteOk = async () => {
    try {
      const deleteItem = {...editItemCat}

      if (items.length === 1) throw Error("카테고리 하나는 최소한 필요합니다.")

      let res = await listPost({ categoryId: deleteItem.categoryId })

      if (res.err) throw Error(res.err.message)

      if (res.result.Items.length > 0) {
        throw Error("카테고리에 포스트 항목이 남아 있으면 삭제가 불가능합니다.")
      }

      const newCatList = []
      items.map(cat => {
        if (cat.categoryId !== deleteItem.categoryId) {
          newCatList.push(cat)
        }
        return null
      })

      console.log(newCatList)

      res = await updatePost({
        categoryId: "common",
        postId: "common",
        body: { categoryList: JSON.stringify(newCatList) }
      })
      if (res.err) throw Error(res.err.message)

      await onload()

    } catch (e) {
      alert(e.message)
    } finally {
      setAlertMsg("")
    }
  }

  return (
    <Page stretch title={title}>

      <Section bgColor="#fff">
        <h3 style={{ margin: 5, padding: 0 }}>
          <Plus onClick={onClickPlus} />
          &nbsp;
          Add Category
        </h3>
        <hr />
        
        <CustomTable
          tableHeader={catHeader}
          rowItems={items}
          setRowItems={setItems}
          onClickItem={(item) => onSelect(item)}
        />


        <CategoryModal
          {...pageProps}
          visible={visibleCatModal}
          setVisible={setVisibleCatModal}
          category={editItemCat}
          editItem={editItemCat}
          isEdit={isEditCat}
        />

      </Section>

      {/* <AlertItemDelete
        alertMsg={alertMsg}
        setAlertMsg={setAlertMsg}
        items={items}
        deleteItem={editItemCat}
      /> */}

      <Alert
        title={alertMsg}
        visible={alertMsg !== ""}
        onClickCancel={() => setAlertMsg("")}
        onClickOk={onDeleteOk}
      />

      {isLoading && <LoaderFullPage />}
    </Page>

  )
}
