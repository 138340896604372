import { createSlice } from "@reduxjs/toolkit";

const initialState = {

  // 더치페이할 메뉴아이템들, price: 원래 가격, dpPrice: 더치페이할 가격
  dpItems: [], // {itemName, price, dpPrice, count, dpCount, dp=true}  

  // dp 참여자 userId
  dpUsers: [],

  // 더치페이할 인원수
  persons: 1
};

export const dpCart = createSlice({
  name: "dpCart",
  initialState,
  reducers: {
    addDpItem: (state, action) => {
      const newItem = action.payload.item;
      newItem.dp = true;
      const index = state.dpItems.findIndex(item => item.itemName === newItem.itemName);
      if (index < 0) state.dpItems.push(newItem)
      else state.dpItems[index].count += newItem.count;
    },

    removeDpItem: (state, action) => {
      let arr = [...state.dpItems];
      state.dpItems = arr.filter(
        (item) => item.itemName !== action.payload.itemName
      );
    },

    upDpItemCount: (state, action) => {
      const newItems = state.dpItems;
      const index = newItems.findIndex(item => item.itemName === action.payload.itemName);
      newItems[index].count += 1;
    },

    downDpItemCount: (state, action) => {
      const newItems = state.dpItems;
      const index = newItems.findIndex(item => item.itemName === action.payload.itemName);
      newItems[index].count -= 1;
    },

    increasePerson: (state, action) => {
      state.persons += action.payload.count;
    },

    decreasePerson: (state, action) => {
      if (state.persons <= 1) return null;
      state.persons -= action.payload.count;
    },

    /**
     * @deprecated
     */
    // updateDpPrice: (state, action) => {
    //   const newItems = state.dpItems.map(item => {
    //     item.dpPrice = Math.round(item.price / state.persons)
    //     return item;
    //   })
    //   state.dpItems = newItems;
    // },

    updateDpItems: (state, action) => {
      const newItems = state.dpItems.map(item => {
        item.dpPrice = Number((item.price / state.persons).toFixed(2));
        item.dpCount = Number((item.count / state.persons).toFixed(2));
        item.payAmount = item.dpPrice * item.count;
        // item.payAmount = item.price * item.dpCount;
        return item;
      })
      state.dpItems = newItems;
    },

    // 사용의 위함성이 있슴???. 
    resetDpCart: (state) => {
      state.dpItems = initialState.dpItems;
      state.persons = initialState.persons;
      state.dpUsers = initialState.dpUsers;
    },

    addDpUser: (state, action) => {
      if (!state.dpUsers.includes(action.payload.userId)) {
        state.dpUsers.push(action.payload.userId);
        state.persons = state.dpUsers.length + 1;
      }
    },

    removeDpUser: (state, action) => {
      // const users = state.dpUsers.filter(user => user !== action.payload.userId);
      // state.dpUsers = users;
      const index = state.dpUsers.findIndex(user => user === action.payload.userId)
      if (index >= 0) {
        state.dpUsers.splice(index, 1);
        state.persons = state.dpUsers.length + 1;
      }
    },

  },
});

export const {
  addDpItem,
  removeDpItem,
  upDpItemCount,
  downDpItemCount,
  increasePerson,
  decreasePerson,
  // updateDpPrice,
  updateDpItems,
  resetDpCart,
  addDpUser,
  removeDpUser
} = dpCart.actions;

export default dpCart.reducer;

