import React from "react";
import { capitalize } from "../lib/utils";
import { Form, Row, Col, Button } from "react-bootstrap";
import { debug } from "../settings";

const defaultSize = "normal"; // sm | normal | lg
const validatedStyle = { borderLeft: "5px solid #77d" };
const unValidatedStyle = { borderLeft: "5px solid #d77" };

export function InputSelect(props) {
  // console.log("InputSelect props:", props);
  const myStyle = {
    validated: props.validated ? validatedStyle : unValidatedStyle,
  };
  return (
    <Form.Group as={Row} controlId={props.name}>
      <Form.Label column sm={props.SmLeft}>
        {capitalize(props.label)} :
      </Form.Label>
      <Col sm={props.SmRight}>
        <Form.Control
          style={myStyle.validated}
          type={props.type}
          size={props.size}
          name={props.name}
          onChange={props.onChange}
          as="select"
          value={props.value || ""}
          readOnly={props.readOnly}
        >
          {props.selectList.map((item, index) => {
            return <option key={item + index}>{item}</option>;
          })}
        </Form.Control>
      </Col>
    </Form.Group>
  );
}

export function InputCheckBox(props) {
  // console.log('InputCheckBox props:', props);
  const styles = { comment: { padding: "8px 15px" } };
  return (
    // <>
    <Form.Group as={Row} controlId={props.name} style={{ ...styles.style, marginBottom: 15 }}>
      <Form.Label as="legend" column sm={props.SmLeft}>
        {capitalize(props.label)} :
      </Form.Label>
      <Col sm={props.SmRight} style={styles.comment}>
        <Form.Check
          type="checkbox"
          size={props.size}
          name={props.name}
          onChange={props.onChange}
          label={props.comment}
          checked={props.checked}
        />
      </Col>
    </Form.Group>
    // </>
  );
}

export function InputFile(props) {
  const { label, SmLeft, SmRight, onChangeInputFile, value } = props;

  const myStyle = {
    inputWrap: { padding: "5px 15px" },
    imgWrap: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end"
    },
  };
  return (
    <Form.Group as={Row} controlId="imagefileInput">
      <Form.Label column sm={SmLeft}>
        {capitalize(label)} :
      </Form.Label>
      <Col sm={SmRight / 2} style={myStyle.inputWrap}>
        <Form.Control onChange={onChangeInputFile} type="file" />
      </Col>
      <Col sm={SmRight / 2}>
        <div style={myStyle.imgWrap}>{value && <img src={props.value} alt={props.name} height="100px" />}</div>
      </Col>
    </Form.Group>
  );
}

export function InputText(props) {
  const { validated, styles } = props;
  const myStyle = {
    validated: validated ? validatedStyle : unValidatedStyle,
  };
  // console.log(props.name, validated);
  return (
    <Form.Group as={Row} controlId={props.name} style={{ marginBottom: 15, ...styles.style }}>
      <Form.Label column sm={props.SmLeft}>
        {props.label ? capitalize(props.label) : ""}
      </Form.Label>
      <Col sm={props.SmRight}>
        <Form.Control
          style={{ ...myStyle.validated }}
          type={props.type}
          size={props.size}
          name={props.name}
          onChange={props.onChange}
          value={props.value}
          readOnly={props.readOnly}
          placeholder={props.placeholder}
        />
        {props.comment && <p style={styles.comment}>{props.comment}</p>}
      </Col>
    </Form.Group>
  );
}

/**
 *
 * @param {S} props.name
 * @param {S} props.label
 * @param {S} props.type
 * @param {*} props.value
 * @param {F} props.onChange
 * @param {S} props.placeholder
 * @param {B} props.validated
 * @param {B} props.readOnly
 * @param {S} props.size sm as default
 * @param {S} props.comment
 * @param {O} props.styles
 *
 * @param {S} props.inputType
 * @param {A} props.selectList
 * @param {S} props.colLeft
 *
 * @returns_bootstrap_FormControl
 */
export function FormInput(props) {
  const { size, colLeft, style } = props;
  const styles = {
    style,
    comment: { color: "#aaa", fontSize: "0.8em", margin: "3px" },
  };
  const otherProps = {
    styles: styles,
    SmLeft: colLeft || "2",
    SmRight: props.colRight || colLeft ? 12 - parseInt(colLeft) : "10",
    size: size || defaultSize,
  };

  if (props.inputType === "checkbox")
    return <InputCheckBox {...props} {...otherProps} />;
  else if (props.inputType === "file")
    return <InputFile {...props} {...otherProps} />;
  else if (props.inputType === "select")
    return <InputSelect {...props} {...otherProps} />;
  else return <InputText {...props} {...otherProps} />;
}

/**
 *
 * @param {S} props.onClick
 * @param {*} props.btnText
 *
 * @returns_bootstrap_FormControl
 */
export function FormInputWithBtn(props) {
  const { onClick, size, btnText } = props;
  const myStyle = { btnWrap: { marginBottom: "5px" } };
  return (
    <div>
      <div className="row" style={myStyle.btnWrap}>
        <div className="col-sm-2"></div>
        <div className="col-sm-10">
          <Button
            variant="secondary"
            size={size || defaultSize}
            onClick={onClick}
          >
            {btnText}
          </Button>
        </div>
      </div>
      <FormInput {...props} />
    </div>
  );
}

export const Search = (props) => {
  const {
    onChange,
    onClick,
    value,
    placeholder,
    btnDisabled,
    btnText,
    onKeyUp,
  } = props;
  return (
    <Form.Group as={Row} controlId="search-text">
      <Col sm="3">
        <Form.Control
          size="sm"
          type="text"
          onChange={onChange}
          placeholder={placeholder || "search text here..."}
          onKeyPress={onKeyUp}
          value={value || ""}
        />
      </Col>
      <Col sm="9">
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={onClick}
          disabled={btnDisabled === "true"}
        >
          {btnText || "Search"}
        </Button>
      </Col>
    </Form.Group>
  );
};

export const Search2 = (props) => {
  const { onClick, btnDisabled, btnText, ...rest } = props;
  return (
    <div style={{ display: "flex", justifyContent: "flex-start" }}>
      <FormInput {...rest} />
      <Button
        size="sm"
        variant="outline-secondary"
        onClick={onClick}
        disabled={btnDisabled === "true"}
      >
        {btnText || "Search"}
      </Button>

    </div>
  );
};

export const SelectFilters = (props) => {
  const { defaultValue, list, onChange, onClick } = props;
  return (
    <Form.Group as={Row} controlId="categoryInput">
      <Col sm="3">
        <Form.Control
          onChange={onChange}
          size="sm"
          type="text"
          as="select"
          value={defaultValue}
        >
          {list.map((item, index) => {
            return <option key={index}>{item.name}</option>;
          })}
        </Form.Control>
      </Col>
      {/* <Form.Label column sm="1">Filter </Form.Label> */}
      <Button size="sm" variant="outline-secondary" onClick={onClick}>
        Filter
      </Button>
    </Form.Group>
  );
};
