import React, { useState } from "react";
import { Page, Section, LoaderFullPage } from "../../components";
import { getErrLogs } from "../../lib/ec2-api-lib";
import { ModalForm, CustomTable } from "../../components2";
import { getTimestampFromNow, isJson } from "../../lib/utils";
import { Log, obj2Html } from "../../components2/LogMessage";
import SearchStoreWithCalendar from "./components/SearchStoreWithCalendar";
import { debug } from "../../settings";

export default function OrderErrors(props) {
  const title = "가맹점 주문 에러 확인"

  const [isLoading, setIsLoading] = useState(false);
  const [shopInfo, setShopInfo] = useState({})
  const [targetList, setTargetList] = useState([])

  const _viewErrors = async ({ shopInfo, startDate, endDate }) => {
    try {
      const shopId = shopInfo.shopId
      const start_time = getTimestampFromNow({ now: startDate, days: 0 })
      const end_time = getTimestampFromNow({ now: endDate })

      if (start_time > end_time) throw Error("Ary you kidding?")

      // let res1 = await getOrderRecords({ shopId, start_time, end_time })
      // console.log('res orderRecords', res1)

      let res = await getErrLogs({ shopId, start_time, end_time })
      if (res.err) throw Error(res.err.message)
      if (res.result.Items.length === 0) throw Error("NO DATA FOUND")

      const errList = res.result.Items.map(e => {
        // console.log(JSON.parse(e.requestData))

        const obj = JSON.parse(e.errData)
        const d = {
          ...e,
          createdTime: new Date(e.created).toLocaleString(),
          errMsg: obj.err?.message,
        }

        if (shopInfo.posName === "OKPOS") {
          const rData = JSON.parse(e.requestData)
          d.tableCode = rData?.body?.orderData?.TABLE_CD
        }

        return d

      })
      setTargetList(errList)
    } catch (e) {
      setTargetList([])
      alert(e.message)
    }
  }

  const onSelectShop = async ({ item, startDate, endDate }) => {
    try {
      setIsLoading(true)
      if (debug) console.log('selectedShop', item)
      // setShopName(item.shopName)
      setShopInfo(item)

      _viewErrors({ shopInfo: item, startDate, endDate })
    } catch (e) {
      setTargetList([])
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Page stretch title={title}>

      <SearchStoreWithCalendar
        defaultValue={debug ? "오아시스" : ""}
        onSelectShop={onSelectShop}
      />
      {
        targetList.length > 0 &&
        <Section bgColor="#fff">
          <h3>{`${shopInfo.shopName} - ${shopInfo.posName}`}</h3>
          <ErrorList {...props} errorList={targetList} shopInfo={shopInfo} />
        </Section>
      }

      {isLoading && <LoaderFullPage />}
    </Page>
  );
}

function ErrorList(props) {
  const { errorList, shopInfo } = props

  if (debug) logErrorList(errorList)

  const header = [
    { name: "createdTime", displayName: "생성일" },
    // { name: "tableCode", displayName: "테이블 코드" },
    { name: "errType", displayName: "에러 타입" },
    // { name: "errData", displayName: "에러 데이터" },
    { name: "errMsg", displayName: "에러 메시지" },
  ]

  if (shopInfo.posName === "OKPOS") header.push({ name: "tableCode", displayName: "테이블 코드" })

  const [logDetail, setLogDetail] = useState("")

  const onSelectedItem = (_line) => {
    const line = {
      ..._line,
      created: new Date(_line.created).toLocaleString(),
      errData: isJson(_line.errData) ? JSON.parse(_line.errData) : null,
      requestData: isJson(_line.requestData) ? JSON.parse(_line.requestData) : null
    }
    delete line.lastModified
    delete line.id

    setLogDetail(obj2Html(line))
  }

  return (
    <>
      <CustomTable
        {...props}
        tableHeader={header}
        rowItems={errorList}
        rowItemKey="shopId"
        onClickItem={onSelectedItem}
      />

      <ModalForm visible={logDetail !== ""} setIsVisible={() => setLogDetail("")} title="Error Detail">
        <div style={{ maxHeight: "80vh", overflow: "auto" }}>
          <Log title="Error Detail" msg={logDetail} />
        </div>
      </ModalForm>
    </>
  )
}

const logErrorList = (errorList) => {
  console.log('\nerrorList', errorList)
  // const reqDataList = []
  // errorList.map(err => {
  //   if (err.errData.includes("8610")) {
  //     if(!err.requestData) return null
  //     const reqData = JSON.parse(err.requestData)
  //     reqDataList.push({
  //       created: new Date(err.created),
  //       SEND_DATE: reqData.body.orderData.EXTERNAL_SEND_DATE,
  //       ORDER_SEQ: reqData.body.orderData.EXTERNAL_ORDER_SEQ,
  //       TABLE_CD: reqData.body.orderData.TABLE_CD,
  //       ORDER_NO: reqData.body.orderData.ORDER_NO,
  //       ORDER_INFO: JSON.stringify(reqData.body.orderData.ORDER_INFO.map(o => {
  //         return {
  //           PROD_AMT: o.PROD_AMT,
  //           PROD_CD: o.PROD_CD,
  //           PROD_CNT: o.PROD_CNT,
  //         }
  //       })),
  //     })
  //   }
  // })
  // console.log('\nreqDataList')
  // console.table(reqDataList)
}