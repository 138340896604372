import React, { useEffect, useState } from "react";
import { Section } from "../../../components";
import { getOnlMenu, getPosMenu, validateMenus } from "../../../lib-pos";
import { CustomTable, useStyles } from "../../../components2";
import { debug } from "../../../settings";

const headers = {
  checkList: [
    { name: "checkItem", displayName: "체크항목" },
    { name: "value", displayName: "결과값" }
  ],

  posItemList: [
    { name: "PROD_CD", displayName: "ItemCode" },
    { name: "PROD_NM", displayName: "ItemName" },
    { name: "SIDE_MENU_YN", displayName: "SideMenu" }
  ],

  tuClassList: [
    { name: "TU_CLS_CD", displayName: "TuClass Code" },
    { name: "TU_CLS_NM", displayName: "TuClass Name" },
    { name: "OnlTuClassItems", displayName: "Onl Tu ItemList" },
    { name: "PosTuClassItems", displayName: "Pos Tu ItemList" }
  ],

  onlItemList: [
    { name: "itemCode", displayName: "ItemCode" },
    { name: "itemName", displayName: "ItemName" },
    { name: "itemName2", displayName: "ItemName2" }
  ],

  tableList: [
    { name: "TABLE_CD", displayName: "TABLE_CD" },
    { name: "TableNo", displayName: "TableNo" },
    { name: "TableCode", displayName: "TableCode" },
    { name: "TABLE_NM", displayName: "TABLE_NM" },
    { name: "TableName", displayName: "TableName" },
    { name: "msg", displayName: "비고" },
  ]
}

export default function MenuCheckOkpos(props) {
  const { shopInfo, setIsLoading } = props
  const { colors } = useStyles()

  const [cListPos, setCheckListPos] = useState([])
  const [cListOnl, setCheckListOnl] = useState([])
  const [tables, setTables] = useState([])
  const [val, setValidated] = useState(false)

  const [notFoundPosItems, setNotFoundPosItems] = useState([])
  const [undeletedList, setUndeletedList] = useState([])
  const [tuClassList, setTuClassList] = useState([])

  const [done, setDone] = useState(false)

  // 
  // pos MasterInfo loading progress msg
  // 
  const [msg, setMsg] = useState("")

  useEffect(() => {
    async function start() {
      try {
        setIsLoading(true)

        let resPos = await getPosMenu({ shopInfo, setComment: setMsg })
        if (resPos.err) throw Error(resPos.err.message)
        setMsg("")

        let resOnl = await getOnlMenu({ shopInfo })
        if (resOnl.err) throw Error(resOnl.err.message)

        const {
          checkListPos, checkListOnl, validated,
          notFoundPosItemList, undeletedOnlItemList,
          invalidOnlTuClassList
        } = validateMenus({
          shopInfo,
          posMenu: resPos.result,
          onlMenu: resOnl.result
        })

        // if (debug) {
        //   console.log('checkListPos', checkListPos)
        //   console.log('checkListOnl', checkListOnl)
        //   console.log('validated', validated)
        //   console.log('undeletedOnlItemList', undeletedOnlItemList)
        //   console.log('notFoundPosItemList', notFoundPosItemList)
        //   console.log('invalidOnlTuClassList', invalidOnlTuClassList)
        // }

        setCheckListPos(checkListPos)
        setCheckListOnl(checkListOnl)
        setValidated(validated)
        setNotFoundPosItems(notFoundPosItemList)
        setUndeletedList(undeletedOnlItemList)
        setTuClassList(invalidOnlTuClassList)

        _validateTableList(resOnl.result.itemOptions.TableList)
        setDone(true)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        alert(e.message)
      }
    }
    start()

  }, [shopInfo])

  const _validateTableList = (tableList) => {
    let _val = true

    const _tableList = tableList.map((table) => {
      let msg = ""
      if (table.TABLE_CD !== table.TableCode) msg += 'WRONG TableCode '
      if (table.TABLE_CD !== table.TableNo) msg += 'WRONG TableNo '
      if (table.TABLE_NM !== table.TableName) msg += 'WRONG TableName '
      return {
        ...table,
        msg: msg === "" ? "OK" : msg,
      }
    })
    setTables(_tableList)

    _tableList.map(tbl => {
      _val = _val && (tbl.msg === "OK")
      return null
    })
    setValidated(pre => pre && _val)
  }

  const _validateItemList = (itemList) => {

    console.log('itemList len', itemList)

  }

  return (
    <>
      {
        <Section bgColor="#fff">
          <h2>
            <span style={{ color: colors.primary }}>{shopInfo.shopName}</span>
            &nbsp;&nbsp;메뉴검증 결과 :&nbsp;&nbsp;
            {done && <span style={{ color: colors.danger }}>{val ? "OK" : "FAILED"}</span>}
          </h2>

          {msg !== "" && <h4>{msg}</h4>}
        </Section>
      }

      {
        cListPos.length > 0 &&
        <Section bgColor="#fff">
          <h5>{shopInfo.posName} 메뉴</h5>
          <CustomTable
            tableHeader={headers.checkList}
            rowItems={cListPos}
          />
        </Section>
      }

      {
        cListOnl.length > 0 &&
        <Section bgColor="#fff">
          <h5>{shopInfo.posName} 메뉴</h5>
          <CustomTable
            tableHeader={headers.checkList}
            rowItems={cListOnl}
          />
        </Section>
      }

      {
        notFoundPosItems.length > 0 &&
        <Section bgColor="#fff">
          <h5>미등록 메뉴 리스트: ONL서버에는 등록이 안된 POS 메뉴 {notFoundPosItems.length} items</h5>
          <CustomTable
            tableHeader={headers.posItemList}
            rowItems={notFoundPosItems}
          />
        </Section>
      }

      {
        undeletedList.length > 0 &&
        <Section bgColor="#fff">
          <h5>미삭제 메뉴 리스트: ONL서버에 불필요한 메뉴 {undeletedList.length} items</h5>
          <CustomTable
            tableHeader={headers.onlItemList}
            rowItems={undeletedList}
          />
        </Section>
      }

      {
        tuClassList.length > 0 &&
        <Section bgColor="#fff">
          <h5>일치하지 않은 TuClass 리스트: {tuClassList.length} items</h5>
          <CustomTable
            tableHeader={headers.tuClassList}
            rowItems={tuClassList}
          />
        </Section>
      }

      {
        tables.length > 0 &&
        <Section bgColor="#fff">
          <h5>{shopInfo.posName} TableList</h5>
          <CustomTable
            tableHeader={headers.tableList}
            rowItems={tables}
          />
        </Section>
      }

    </>
  );
}
