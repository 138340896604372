import React, { useState } from "react";
import { Section, Table, LoaderFullPage, Page } from "../../components";
// import { nfcTagList } from "../../lib/apiLib";
import { getOrderRecords } from "../../lib/ec2-api-lib";
import { shopGet } from "../../lib/ec2-api-lib";
import { getItemOption, parseItemOptions, transformOrders } from "../../lib-pos";
import { getTimestamp, sortObjArray } from "../../lib/utils";
import { useLocation } from "react-router";
import { useEffect } from "react";
import SearchStoreWithCalendar from "./components/SearchStoreWithCalendar";
import { debug } from "../../settings";

const orderTableHeader = [
  { name: "time", displayName: "주문일시" },
  { name: "TableName", displayName: "테이블 이름" },
  { name: "OrderData", displayName: "주문내역" },
];

const orderSumHeader = [
  { name: "orderDate", displayName: "주문일자" },
  { name: "orderCnt", displayName: "주문건수" },
];

export default function OrdersByShop(props) {
  const location = useLocation()

  const [isLoading, setIsLoading] = useState(false);
  const [shopName, setShopName] = useState("");
  const [orderItems, setOrderItems] = useState([]);
  const [tagHits, setTagHits] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [orderSum, setOrderSum] = useState([])
  // const [shopList, setShopList] = useState([])

  useEffect(() => {
    function start() {
      if (location.state) {
        if (debug) {
          console.log('shopInfo', location.state.shopInfo)
          console.log('startDate', location.state.startDate)
          console.log('endDate', location.state.endDate)
        }

        return onSelectShop({
          item: location.state.shopInfo,
          startDate: location.state.startDate,
          endDate: location.state.endDate,
        })
      }
    }
    start()
  }, [])

  // const _getOrderData = async ({ item, startDate, endDate }) => {
  //   try {
  //     // const start_time = getTimestampFromNow({ now: startDate, days: 0 })
  //     // const end_time = getTimestampFromNow({ now: endDate })
  //     const start_time = getTimestamp({ now: startDate, type: "begin" })
  //     const end_time = getTimestamp({ now: endDate, type: "end" })

  //     //
  //     // shopInfo 가져오기 - 누적 orderCount 추출
  //     //
  //     let res = await shopGet({ shopId: item.shopId });
  //     if (res.err) throw new Error(res.err.message);
  //     const orderCount = res.result.Item.orderCount || 0
  //     const shopInfo = res.result.Item

  //     res = await getOrderRecords({ shopId: item.shopId, start_time, end_time });
  //     if (res.err) throw new Error(res.err.message);
  //     const _orders = res.result.Items;

  //     res = await getItemOption({ shopInfo })
  //     if (res.err) throw new Error(res.err.message);
  //     const itemOptions2 = parseItemOptions({ shopInfo, itemOptions: res.result.Item });
  //     const tableList = itemOptions2.TableList

  //     let newOrders = transformOrders({ shopInfo, orders: _orders, tableList })
  //     return { data: newOrders, orderCount };
  //   } catch (e) {
  //     return { err: { message: e.message } }
  //   }
  // }

  const _getOrderData2 = async ({ item, startDate, endDate }) => {
    try {
      const start_time = getTimestamp({ now: startDate, type: "begin" })
      const end_time = getTimestamp({ now: endDate, type: "end" })
      const timeList = getTimeList(start_time, end_time)

      // let res = await shopGet({ shopId: item.shopId });
      // if (res.err) throw new Error(res.err.message);
      // const orderCount = res.result.Item.orderCount || 0
      // const shopInfo = res.result.Item

      const orderCount = item.orderCount || 0
      const shopInfo = item

      let res = await getItemOption({ shopInfo })
      if (res.err) throw new Error(res.err.message);

      const itemOptions2 = parseItemOptions({ shopInfo, itemOptions: res.result.Item });
      if (itemOptions2.err) throw new Error(itemOptions2.err.message);
      const tableList = itemOptions2.TableList || itemOptions2.tableList

      if (debug) {
        // console.log('itemOptions2', itemOptions2)
        console.table(tableList)
      }

      let errMsg = ""
      let newOrders = []
      await Promise.all(timeList.map(async (timeObj, i) => {
        const res = await getOrderRecords({
          shopId: item.shopId,
          start_time: timeObj.start_time,
          end_time: timeObj.end_time,
        });
        if (res.err) errMsg += i + " " + res.err + " "
        const _orders = res.result.Items;

        let _newOrders = transformOrders({ shopInfo, orders: _orders, tableList })
        newOrders = [...newOrders, ..._newOrders]
      }))

      const newOrders2 = sortObjArray({ arr: newOrders, key: "time" })
      return { data: newOrders2, orderCount };
    } catch (e) {
      return { err: { message: e.message } }
    }
  }

  const onSelectShop = async ({ item, startDate, endDate }) => {
    try {
      setIsLoading(true)

      setShopName(item.shopName);

      let res = await _getOrderData2({ item, startDate, endDate });
      if (res.err) throw Error(res.err.message);
      if (res.data.length === 0) throw Error("DATA NOT FOUND")

      setOrderItems(res.data);
      setOrderCount(res.orderCount);

      const _orderSum = ordersByDate(res.data)
      setOrderSum(_orderSum)
    } catch (e) {
      alert(e.message)
      if (debug) console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Page stretch title="가맹점 주문 통계">

      <SearchStoreWithCalendar
        defaultValue={debug ? "PSDL 레스토랑 - EASYPOS" : ""}
        onSelectShop={onSelectShop}
      />

      {
        orderItems.length > 0 &&
        <Section bgColor="#fff">
          <h3>{shopName}</h3>
          <Section.FlexBox variant="space-between">
            <h6>{"기간내 주문 건수: " + orderItems.length}</h6>
            <h6>{"누적 주문 건수: " + orderCount}</h6>
            {
              tagHits > 0 &&
              <h6>{"누적주문/태깅: " + ((orderCount / tagHits) * 100).toFixed(0) + "%"}</h6>
            }
          </Section.FlexBox>
          <Table
            tableHeader={orderSumHeader}
            rowItems={orderSum}
          />

          <br /><br />

          <Table
            tableHeader={orderTableHeader}
            rowItems={orderItems}
          />

        </Section>
      }

      {isLoading && <LoaderFullPage />}
    </Page>
  );
}

function ordersByDate(orderList) {
  const orders = orderList || [
    {
      "TableName": "14",
      "time": 1697612958623,
      "OrderData": "아이템 수: 1"
    },
    {
      "TableName": "14",
      "time": 1697612975477,
      "OrderData": "아이템 수: 2"
    },
    {
      "TableName": "14",
      "time": 1697612989685,
      "OrderData": "아이템 수: 1"
    },
    {
      "TableName": "14",
      "time": 1697613197009,
      "OrderData": "아이템 수: 2"
    },
    {
      "TableName": "14",
      "time": 1697613219365,
      "OrderData": "아이템 수: 3"
    },
    {
      "TableName": "14",
      "time": 1697613275986,
      "OrderData": "아이템 수: 3"
    },
    {
      "TableName": "14",
      "time": 1697613301320,
      "OrderData": "아이템 수: 1"
    },
    {
      "TableName": "4",
      "time": 1697696775950,
      "OrderData": "아이템 수: 1"
    },
    {
      "TableName": "8",
      "time": 1697696796212,
      "OrderData": "아이템 수: 1"
    },
    {
      "TableName": "11",
      "time": 1697696853916,
      "OrderData": "아이템 수: 1"
    },
    {
      "TableName": "11",
      "time": 1697696863567,
      "OrderData": "아이템 수: 1"
    },
    {
      "TableName": "3",
      "time": 1697697420552,
      "OrderData": "아이템 수: 6"
    },
    {
      "TableName": "3",
      "time": 1697697795175,
      "OrderData": "아이템 수: 2"
    },
    {
      "TableName": "1",
      "time": 1697697820459,
      "OrderData": "아이템 수: 1"
    },
    {
      "TableName": "10",
      "time": 1697698018040,
      "OrderData": "아이템 수: 2"
    }
  ]

  const orders2 = orders.map(o => {
    return {
      orderDate: new Date(o.time).toLocaleDateString(),
      orderCnt: 1
    }
  })

  const orderSum = []
  orders2.map(order => {
    let index = orderSum.findIndex(o => o.orderDate === order.orderDate)
    if (index > -1) {
      orderSum[index].orderCnt += 1
    } else {
      orderSum.push(order)
    }
    return null
  })

  return orderSum
}

//
// 데이터양이 너무 많아 서버가 한번에 처리할 수 없어, 날짜별로 불러오기 위함
// 
function getTimeList(start_time, end_time) {
  const dayMsecs = 1000 * 60 * 60 * 24
  const days = Math.round((end_time - start_time) / dayMsecs, 0)

  const timeList = []
  let preObj = {}
  for (let i = 0; i < days; i++) {
    let timeObj = {}
    if (i === 0) {
      timeObj.start_time = start_time
      timeObj.end_time = start_time + dayMsecs - 1
    } else {
      timeObj.start_time = preObj.start_time + dayMsecs
      timeObj.end_time = preObj.end_time + dayMsecs
    }
    timeList.push(timeObj)
    preObj = { ...timeObj }
  }

  const tList = timeList.map(timeObj => {
    return {
      // ...timeObj,
      start_time: timeObj.start_time,
      end_time: timeObj.end_time,
      start_time2: new Date(timeObj.start_time).toLocaleString(),
      end_time2: new Date(timeObj.end_time).toLocaleString(),
    }
  })
  return tList
}
