import React from "react";
import { Route, Switch } from "react-router-dom";
import { AppliedRoute, AuthenticatedRoute } from "./conditionalRoutes";

import NotFound from "../pages/NotFound";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Signup from "../pages/signup/Signup";

// import Members from "../pages/admin/Members";
// import MemberApproval from "../pages/admin/MemberApproval";
// import TagVersion from "../pages/admin/NfcTagVersion";
// import NfcVersionRegister from "../pages/admin/NfcTagVersionRegister";

import Categories from "../pages/cms/Categories2";
import Posts from "../pages/cms/Posts2";
import PostView from "../pages/cms/PostView2";
import ImageManager from "../pages/cms/ImageManager";

import IndexLanding from "../pages/cms/landing-page/Index-landing";
import ViewLandingPage from "../pages/cms/landing-page/View-landing";
/** CDEditor는 code 부분 해결 필요 */
// import PostView from "../pages/cms/PostViewCKEditor";

// // 
// // 정품인증 데모용 등록/관리 Demo
// // 
// import BaProducts from "../pages/ba/Product";
// import BaProductRegister from "../pages/ba/ProductRegister";
// import BaAds from "../pages/ba/Ads";
// import BaAdRegister from "../pages/ba/AdRegister"
// // import BaTags from "../pages/ba/Tags";

import QRCodes from "../pages/store/QRCodes";
import OrdersByShop from "../pages/store/OrdersByShop2";
import MenuCheck from "../pages/store/MenuCheck";
import OrderErrors from "../pages/store/OrderErrors2";
import NfcTagsByShop from "../pages/store/NfcTagsByShop";
import StoreInfo from "../pages/store/StoreInfo";
import DqrSetting from "../pages/store/DqrSetting";

// 
// test
// 
import UiViewTest from "../components/ui-test/UI";
// import ApiBatchTest from "../pages/test/Apis-batch-test";
// import UserApiTest from "../pages/test/Apis-users";

function Routes(props) {
  return (
    <Switch>
      <AuthenticatedRoute path="/" exact component={Home} />
      <AuthenticatedRoute path="/index.html" exact component={Home} />
      <AppliedRoute path="/login" exact component={Login} />
      <AppliedRoute path="/signup" exact component={Signup} />

      {/* <AuthenticatedRoute path="/tag-version" exact component={TagVersion} />
      <AuthenticatedRoute path="/tag-version/:action" exact component={NfcVersionRegister} />
      <AuthenticatedRoute path="/members/:userType" exact component={Members} />
      <AuthenticatedRoute path="/members/:userType/update" exact component={MemberApproval} /> */}

      <AuthenticatedRoute path="/store/order-check" exact component={OrdersByShop} />
      <AuthenticatedRoute path="/store/menu-check" exact component={MenuCheck} />
      <AuthenticatedRoute path="/store/error-check" exact component={OrderErrors} />
      <AuthenticatedRoute path="/store/qrcodes" exact component={QRCodes} />
      <AuthenticatedRoute path="/store/nfctags" exact component={NfcTagsByShop} />
      <AuthenticatedRoute path="/store/info" exact component={StoreInfo} />
      <AuthenticatedRoute path="/store/dqr-setting" exact component={DqrSetting} />

      {/* CMS category 등록  */}
      <AuthenticatedRoute path="/cms/category" exact component={Categories} />
      <AuthenticatedRoute path="/cms/post" exact component={Posts} />
      <AuthenticatedRoute path="/cms/post/view" exact component={PostView} />
      <AuthenticatedRoute path="/cms/landing-page" exact component={IndexLanding} />
      <AuthenticatedRoute path="/cms/landing-page/view" exact component={ViewLandingPage} />
      <AuthenticatedRoute path="/cms/image" exact component={ImageManager} />

      {/* BA demo용 */}
      {/* <AuthenticatedRoute path="/ba/products" exact component={BaProducts} />
      <AuthenticatedRoute path="/ba/products/:action" exact component={BaProductRegister} />
      <AuthenticatedRoute path="/ba/ads" exact component={BaAds} />
      <AuthenticatedRoute path="/ba/ads/:action" exact component={BaAdRegister} /> */}

      {/* Test용 */}
      <AuthenticatedRoute path="/test/ui" exact component={UiViewTest} />
      {/* <AuthenticatedRoute path="/test/apis/batch-test" exact component={ApiBatchTest} /> */}
      {/* <AuthenticatedRoute path="/test/apis/user" exact component={UserApiTest} /> */}

      <Route component={NotFound} />
    </Switch>
  );
}

export default Routes;
