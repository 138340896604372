import React, { useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import { conf, debug } from "../settings";
import { useSelector } from "react-redux";

import "./NavigationBar.css";

function Linker(props) {
  const { to, title, onClick } = props;
  return (
    <LinkContainer to={to}>
      <Nav.Link onClick={onClick}>{title}</Nav.Link>
    </LinkContainer>
  );
}

function NavigationBar(props) {
  const { handleLogout } = props;

  const { user, isSigned } = useSelector((state) => state.user);
  // const dispatch = useDispatch();

  const [nickName, setNickName] = useState("-");

  useEffect(() => {
    setNickName(user.nickName ? user.nickName : user.username);
  }, [user]);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="dark"
      className="nav-bg-admin"
    >

      <div className="container">
        <Navbar.Brand>
          <Link to="/">
            {debug ? "DEBUG - " : ""}
            {conf.appName}
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-start" // flex justifyContent: flex-start
        >
          <Nav style={{ flex: 1 }}>
            {
              (isSigned && user.userType === "admin") &&
              <>
                <NavDropdown title="가맹점 관리" id="nav-dropdown-store">
                  <Linker to={"/store/order-check"} title="주문 통계" />
                  <Linker to={"/store/error-check"} title="주문 에러" />
                  <Linker to={"/store/menu-check"} title="메뉴 확인" />
                  <Linker to={"/store/qrcodes"} title="QR코드 생성" />
                  <Linker to={"/store/nfctags"} title="NFC TAG 관리" />
                  <Linker to={"/store/info"} title="가맹점 정보 | DQR" />
                </NavDropdown>

                {/* {user.username === "admin-4673" &&
                  <NavDropdown title="회원 관리" id="nav-dropdown-member">
                    <Linker to={"/members/shop"} title="Shop 회원" />
                    <Linker to={"/members/admin"} title="Admin 회원" />
                    <Linker to={"/members/user"} title="일반 회원" />
                  </NavDropdown>
                } */}

                {/* {user.username === "admin-4673" &&
                  <NavDropdown title="기타 관리" id="nav-dropdown-miscp">
                    <Linker to={"/tag-version"} title="Tag Version 관리" />
                  </NavDropdown>
                } */}

                {/* {user.username === "admin-4673" &&
                  <NavDropdown title="DQR Simulation" id="nav-dropdown-store">
                    <Linker to={"/dqr/provision"} title="DQR Provision" />
                    <Linker to={"/dqr/generate"} title="DQR Generate" />
                    <Linker to={"/dqr/resync"} title="DQR Resync" />
                  </NavDropdown>
                } */}
              </>
            }
          </Nav>
          <Nav>
            {
              (isSigned && user.userType === "admin" && debug) &&
              <NavDropdown title="포스팅" id="nav-dropdown-post">
                <Linker to={"/cms/post"} title="Posting" />
                <Linker to={"/cms/category"} title="Categories" />
                <Linker to={"/cms/image"} title="Images" />
                {debug && <Linker to={"/cms/landing-page/view"} title="Test Landing Page" />}
              </NavDropdown>
            }
          </Nav>
          {/* <Nav>
            {
              (isSigned && user.userType === "admin") &&
              <NavDropdown title="정품인증 등록" id="nav-dropdown-ba">
                <Linker to={"/ba/products"} title="제품 관리/등록" />
                <Linker to={"/ba/ads"} title="광고 관리/등록" />
              </NavDropdown>
            }
          </Nav> */}
          <Nav>
            {
              isSigned && nickName
                ?
                (
                  <NavDropdown title={nickName}>
                    <Linker to="#" title="로그아웃" onClick={handleLogout} />
                    {/* <Linker to="/test/ui" title="테스트"/> */}
                    {
                      debug &&
                      <>
                        <Linker to="/test/ui" title="UI Example View" />
                        <Linker to="/test/apis/batch-test" title="APIs Batch Test" />
                      </>
                    }
                  </NavDropdown>
                )
                :
                (
                  <NavDropdown title="로그인 | 회원등록">
                    <Linker to="/login" title="Login" />
                    {/* <Linker to="/signup?type=shop" title="가맹점 등록" /> */}
                    <Linker to="/signup?type=admin" title="Admin 등록" />
                  </NavDropdown>
                )}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default NavigationBar;
