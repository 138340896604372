import { unified } from "unified";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import rehypeRaw from "rehype-raw";
// import rehypeSanitize from "rehype-sanitize";
import rehypeStringify from "rehype-stringify"

export const md2html = (md) => {
  const targetHtml = unified()
    .use(remarkParse)
    .use(remarkRehype, {allowDangerousHtml: true}) // Pass raw HTML strings through.
    .use(rehypeRaw)                               // *Parse* the raw HTML strings embedded in the tree
    // .use(rehypeSanitize)                       // 스타일, 클래스를 삭제함 
    .use(rehypeStringify)
    .processSync(md);

  return targetHtml
}
