import React from "react";
import { useSelector } from "react-redux";
import {
  ArrowL,
  // ChevronR, 
  ArrowR
} from "./Icons";
import { gStyle, gStyleMobile, colors } from "./ui";

export function CardContainer({
  variant, // lg=100% | md=518 | sm=474
  size, // lg=100% | md=518 | sm=474
  width,
  height,
  children,
  title, onClickBack,
  detail, onClickDetail,
  title2,
  className,
  padding,
}) {

  const { isMobileDevice } = useSelector((state) => state.platform);
  const gStyle_ = isMobileDevice ? gStyleMobile : gStyle;

  let _width = "100%"; // variant===lg or undefined
  if (variant === "md") _width = isMobileDevice ? "90%" : "518px";
  if (variant === "sm") _width = isMobileDevice ? "90%" : "418px";
  if (width) _width = width;

  const color = colors.dark;
  const styles = {
    /**
     * wrap은 container  width가 백프로가 아닌경우에 센터로 위치하게함
     */
    wrap: {
      width: _width,
      marginBottom: "20px",
      marginLeft: "auto", marginRight: "auto"
    },
    container: {
      width: "100%",
    },
    topContainer: {
      width: "100%",
      marginBottom: "5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    innerContainer: {
      width: "100%",
      height: height || "",
      padding: padding || "0px",
      backgroundColor: "white",
      borderRadius: "20px",
      // boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.08)",
      boxShadow: "0 0 10px 0px rgba(0, 0, 0, 0.08)",
    },
    title1: {
      ...gStyle_.title, color,
      cursor: onClickBack ? "pointer" : "auto"
    },
    // title2: {
    //   ...gStyle.title,
    //   textAlign: "center",
    //   marginBottom: "30px",
    //   color
    // },
    detailText: {
      ...gStyle_.subtitle,
      // ...gStyle.bold,
      fontWeight: "600",
      color,
      cursor: "pointer",
    },
  };
  return (
    <div style={styles.wrap}>
      <div style={styles.container}>
        {title &&
          <div style={styles.topContainer}>
            <h2 style={styles.title1} onClick={onClickBack}>
              {onClickBack && <ArrowL />}
              &nbsp;
              {title}
            </h2>
            <h3 style={styles.detailText} onClick={onClickDetail} >
              {detail}&nbsp;
              {
                onClickDetail && <ArrowR />
                // onClickDetail && <ChevronR />
              }
            </h3>
          </div>
        }
        <div
          style={styles.innerContainer}
          className={className}
        >
          {/* {title2 && <h4 style={styles.title2}>{title2}</h4>} */}
          {children}
        </div>
      </div>
    </div>
  );
}
